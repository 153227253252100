import * as React from "react";
import { Box, Flex, Heading, VStack, HStack } from "@chakra-ui/react";
import { NavLink, Route, Switch as RouterSwitch } from "react-router-dom";

import styles from "./Settings.module.scss";

import { SettingsIntegrations } from "../SettingsIntegrations/SettingsIntegrations";
import { SettingsSubscription } from "../SettingsSubscription/SettingsSubscription";
import { SettingsSubscriptionPG } from "../SettingsSubscriptionPG/SettingsSubscriptionPG";
import { SettingsAPI } from "../SettingsAPI/SettingsAPI";
import { SettingsUsagePage } from "../SettingsUsagePage/SettingsUsagePage";
import posthog from "posthog-js";

export const Settings = () => {

	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);
	
	return (
		<Box
      w="100%"
      maxW="1200px"
      p="6"
      shadow="sm"
      h="100%"
      position="relative"
      overflow="auto"
    >
      <VStack w="100%" spacing="10">
        <Flex
          shrink={0}
          w="100%"
          direction="column"
          justifyContent="space-between"
        >
          <Flex width="100%" mb="8">
            <Heading fontSize="30">Settings</Heading>
          </Flex>
          <Box width="100%" height="300px">
            <HStack spacing="0" mb="9">
              <NavLink
                className={styles.nav}
                activeClassName={styles.activeNav}
                to="/app/settings/subscription/"
              >
                Subscription
              </NavLink>
              <NavLink
                className={styles.nav}
                activeClassName={styles.activeNav}
                to="/app/settings/integrations/"
              >
                Integrations
              </NavLink>
              <NavLink
                className={styles.nav}
                activeClassName={styles.activeNav}
                to="/app/settings/api-key/"
              >
                API Key
              </NavLink>
              <NavLink
                className={styles.nav}
                activeClassName={styles.activeNav}
                to="/app/settings/api-usage/"
              >
                API Usage
              </NavLink>
            </HStack>
            <RouterSwitch>
              <Route path="/app/settings/integrations/">
                <SettingsIntegrations />
              </Route>
              <Route path="/app/settings/subscription/">
                <SettingsSubscription />
              </Route>
              <Route path="/app/settings/api-key/">
                <SettingsAPI />
              </Route>
              <Route path="/app/settings/subscription-pg/">
                <SettingsSubscriptionPG />
              </Route>
              <Route path="/app/settings/api-usage/">
                <SettingsUsagePage />
              </Route>
              <SettingsSubscription />
            </RouterSwitch>
          </Box>
        </Flex>
      </VStack>
    </Box>
	);
};
