import * as React from 'react';
import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputRightAddon,
	Text,
	Tooltip
} from '@chakra-ui/react';
import styles from './HolidayListItem.module.scss';

import { useEffect } from 'react';
import { HolidayCategoryDiscount } from '../../services/commonServices';
import { FiInfo } from 'react-icons/fi';
export type HolidayListItemInputType =
	| 'startBefore'
	| 'endAfter'
	| 'discountPercentage'
	| 'coupon';
export interface HolidayListItemProps {
	defaultDiscount?: string;
	defaultCouponCode?: string;
	defaultStartBefore?: string;
	defaultEndAfter?: string;
	holiday: HolidayCategoryDiscount;
	disableCoupon: boolean;
	onChange: (type: HolidayListItemInputType, value: string) => void;
}
export const HolidayListItem = ({
	onChange,
	holiday,
	defaultDiscount,
	defaultStartBefore,
	defaultEndAfter,
	disableCoupon,
	defaultCouponCode,
}: HolidayListItemProps) => {
	useEffect(() => {
		async function fetchData() {
			try {
			} catch (error) {
				console.log('Unable to fetch note', error);
			} finally {
			}
		}
		fetchData();
	}, []);
	return (
		<HStack
			shadow='xs'
			p='4'
			borderRadius='lg'
			className={styles.container}
			spacing='4'
			align='start'
			w='100%'
			justify='space-between'
			alignItems='center'
		>
			<Flex direction='column' alignItems='start'>
				{holiday.startDate ? <Text fontWeight='500' mb='2' fontSize='sm' color='gray.500'>
					{new Date(holiday.startDate as string).toLocaleDateString(
						'en-US',
						{
							weekday: 'long',
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						}
					)}
				</Text> : null}
				<Flex wrap='wrap' pr='140px'>
					{holiday.holiday}
				</Flex>
			</Flex>
			<Flex>
				<HStack w='600px' spacing='4' flexShrink={0}>
					<FormControl id='startBefore'>
						<FormLabel fontSize='md'>Start before</FormLabel>
						<InputGroup size='sm'>
							<Input
								onChange={(e) => {
									onChange('startBefore', e.target.value);
								}}
								size='sm'
								defaultValue={defaultStartBefore}
								type='startBefore'
							/>
							<InputRightAddon children='days' />
						</InputGroup>
					</FormControl>
					<FormControl id='endAfter'>
						<FormLabel fontSize='md'>End after</FormLabel>
						<InputGroup size='sm'>
							<Input
								onChange={(e) => {
									onChange('endAfter', e.target.value);
								}}
								size='sm'
								defaultValue={defaultEndAfter}
								type='endAfter'
							/>
							<InputRightAddon children='days' />
						</InputGroup>
					</FormControl>
					<FormControl id='discountPercentage'>
						<FormLabel fontSize='md'>Discount %</FormLabel>
						<Input
							onChange={(e) => {
								onChange('discountPercentage', e.target.value);
							}}
							size='sm'
							defaultValue={defaultDiscount}
							type='discountPercentage'
						/>
					</FormControl>
					<FormControl id='coupon'>
						<FormLabel>
							<Flex alignItems={"center"}>
								Coupon code {disableCoupon && <Tooltip label='Cannot edit auto generated coupons'>
									<Box marginLeft={'2'}>
										<FiInfo />
									</Box>
								</Tooltip>}
							</Flex>
						</FormLabel>
						<Input
							onChange={(e) => {
								onChange('coupon', e.target.value);
							}}
							size='sm'
							type='coupon'
							disabled={disableCoupon}
							defaultValue={defaultCouponCode}
						/>
					</FormControl>
				</HStack>
			</Flex>
		</HStack>
	);
};
