import * as React from "react";
import {
	Box,
	Button,
	Flex,
	Heading,
	Text,
	IconButton,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { Deal, DealCategory } from "../../types/deals.type";
import { getDealCategories } from "../../services/commonServices";
import { ArrowLeftIcon } from "../../components/Icons/ArrowLeft";
import { CreateMarketplaceDealsForm } from "../CreateMarketplaceDealsForm/CreateMarketplaceDealsForm";
import { fetchDeals } from "../../services/dealServices";
import { MarketplaceDealFormData } from "../../types/marketplaceDeal.type";
import { createMarketplaceDeal } from "../../services/marketplaceSealServices";
import { DealIcon } from "../../components/Icons/Deal";
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

export const CreateMarketplaceDeals = () => {
	const toast = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [showSuccessMessgae, setShowSuccessMessgae] =
		React.useState<boolean>(false);
	const [dealsList, setDealsList] = React.useState<Deal[]>([]);
	const [dealsCategories, setDealsCategories] =
		React.useState<DealCategory[]>();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetchDeals();
				const categoryResponse = await getDealCategories();
				setDealsCategories(categoryResponse.data);
				setDealsList(response.data);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const createDeal = React.useCallback(
		(formValues: MarketplaceDealFormData) => {
			async function saveDeal() {
				try {
					setIsSubmitting(true)
					await createMarketplaceDeal({
						name: formValues.name,
						description: formValues.description,
						website: formValues.website,
						category: formValues.category,
						bar: formValues.bar,
					});
					setShowSuccessMessgae(true);
				} catch (error) {
					if((error as any)?.response?.data.bar) {
						toast({
							title: `You have already submited this deal to the marketplace`,
							status: "error",
							isClosable: true,
						});
					} else {
						toast({
							title: `Oops! Unable to add deal`,
							status: "error",
							isClosable: true,
						});
					}
				} finally {
					setIsSubmitting(false)
				}
			}
			saveDeal();
		},
		[toast]
	);

	const getDealCreationMessageComponent = React.useCallback(() => {
		return (
			<Flex
				h="100%"
				direction="column"
				p="120px 0 200px 0"
				justifyContent="center"
				alignItems="center"
			>
				<DealIcon />
				<Heading mt="8">Deal has been successfully created</Heading>
				<Text color="gray.600" fontSize="md" mt="2">
					We'll manually review your submission and add to the marketplace.
				</Text>
				<Link to='/app/marketplace-deals/'>
					<Button bg='blackAlpha.900'
							mt="10"
							_hover={{ bg: 'blackAlpha.900' }}
							_active={{ bg: 'blackAlpha.900' }}
							colorScheme='blackAlpha'
							variant='solid'
							isLoading={isSubmitting}
							isDisabled={isSubmitting}>View Deals</Button>
				</Link>
			</Flex>
		);
	}, [isSubmitting]);

	const getMainComponent = React.useCallback(() => {
		return (
			<>
				{showSuccessMessgae ? (
					getDealCreationMessageComponent()
				) : (
					<Flex h="100%" direction="column">
						<CreateMarketplaceDealsForm
							isSubmitting={isSubmitting}
							dealsCategories={dealsCategories}
							disableSubmitBtnByDefault
							dealsList={dealsList}
							onPrimaryBtnClick={createDeal}
						/>
					</Flex>
				)}
			</>
		);
	}, [
		createDeal,
		isSubmitting,
		dealsCategories,
		dealsList,
		getDealCreationMessageComponent,
		showSuccessMessgae,
	]);

	return (
		<VStack w="100%" p="6" pl="20" pt="10" spacing="10">
			<Flex shrink={0} alignItems="center" w="100%" justifyContent="start">
				<Link to="/app/marketplace-deals/">
					<IconButton
						borderRadius="full"
						shadow="lg"
						ml="-14"
						mr="5"
						borderColor="gray.500"
						colorScheme="whiteAlpha"
						aria-label="Go to marketplace deals"
						icon={<ArrowLeftIcon />}
					/>
				</Link>
				<Heading fontSize="30">Create marketplace deal</Heading>
			</Flex>
			<Box
				w="100%"
				p="6"
				bg="white"
				shadow="sm"
				borderRadius="lg"
				position="relative"
			>
				{getMainComponent()}
			</Box>
		</VStack>
	);
};
