import * as React from 'react';
import {
	Box,
	BoxProps,
	Button,
	Flex,
	Heading,
	HStack,
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	AlertDialog, 
	AlertDialogBody, 
	AlertDialogFooter, 
	AlertDialogHeader, 
	AlertDialogContent, 
	AlertDialogOverlay
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi"
import styles from './MediaListItem.module.scss';

import { useEffect, useState, useRef } from 'react';
import { DefaultMediaImage } from '../DefaultMediaImage/DefaultMediaImage';

export type MetaListMenuItems = 'edit' | 'customize' | 'getCode' | 'pause' | 'delete' |'refreshCoupon';
interface MediaListItemProps extends BoxProps {
	imageUrl: string;
	productId?: string;
	name: string;
	imageAlt: string;
	detailId?: string;
	description: string;
	showCustomizeMenu: boolean;
	showGetCodeMenu: boolean;
	actionButtonLabel?: string;
	onMenuItemClick?: (type: MetaListMenuItems) => void;
	actionButtonLeftIcon?: React.ReactNode;
	onActionButtonClick?: () => void;
}

export const MediaListItem = ({ onMenuItemClick, className, productId, detailId,  ...restProps }: MediaListItemProps) => {
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  	const cancelRef = useRef();
	  const handleDelete = () => {
		setIsDeleteDialogOpen(true);
	  };
	
	  const handleConfirmDelete = () => {
		setIsDeleteDialogOpen(false);
			onSelect("delete");
	  };
	
	  const handleCancelDelete = () => {
		setIsDeleteDialogOpen(false);
	  };
	useEffect(() => {
		async function fetchData() {
			try {
			} catch (error) {
				console.log('Unable to fetch note', error);
			} finally {
			}
		}
		fetchData();
	}, []);
	const onSelect = React.useCallback((type: MetaListMenuItems) => {
		console.log('on select')
		onMenuItemClick && onMenuItemClick(type)
	}, [onMenuItemClick])
	return (
		<>
			<HStack
				shadow='xs'
				p='4'
				bg='white'
				borderRadius='lg'
				className={className || ''+ ' '+styles.container}
				spacing='4'
				align='start'
				w={restProps.width || '100%'}
				flexShrink={0}
				justify='space-between'
				alignItems='start'
			>
				<HStack spacing={4}>
					<Flex
						alignItems='center'
						justifyContent='center'
						shadow='base'
						boxSize='96px'
						shrink={0}
						borderRadius='lg'
					>
						{restProps.imageUrl ? (
							<Image
								borderRadius='lg'
								objectFit="cover"
								src={restProps.imageUrl}
								alt={restProps.imageAlt}
							/>
						) : (
							<DefaultMediaImage />
						)}
					</Flex>
					<Flex alignSelf='start' direction='column'>
						{detailId ? (<Link to={'/app/edit-deals/' + detailId}>
								<Heading mb='8px' fontSize='lg'>
									{restProps.name}
								</Heading>
							</Link>):(<Heading mb='8px' fontSize='lg'>
									{restProps.name}
								</Heading>) }
							
						<Text noOfLines={2} fontSize='sm' color='gray.500' dangerouslySetInnerHTML={{ __html: restProps.description }} >
						</Text>
						{productId?
							(<Flex mt='2'>
								<Text fontSize='sm' color='gray.500' fontWeight='600' >Product Id : </Text>
								<Text fontSize='sm' color='gray.500'ml='1'> {productId}</Text>
						</Flex>) : null
						}
					</Flex>
				</HStack>
				<HStack>
					<Box alignSelf='end'>
						{restProps.actionButtonLabel ? (
							<Button
								{...{
									[restProps.actionButtonLeftIcon ? 'leftIcon' : '']:
										restProps.actionButtonLeftIcon,
								}}
								onClick={restProps.onActionButtonClick}
								size='sm'
								variant='outline'
							>
								{restProps.actionButtonLabel}
							</Button>
						) : <Menu placement="bottom-end">
							<MenuButton
								as={IconButton}
								minW='8'
								minH='8'
								h="8"
								aria-label='Options'
								icon={<FiMoreHorizontal />}
								color="gray.500"
								variant='outline'
							/>
							<MenuList minW="140px">
								<MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={() => {
									console.log('edit')
									onSelect('edit')
								}}>
									Edit
								</MenuItem>
								{restProps.showCustomizeMenu?<MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={() => {
									onSelect('customize')
								}}>
									Customize
								</MenuItem>:null}
								{restProps.showGetCodeMenu?<MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={() => {
									onSelect('getCode')
								}}>
									Get code
								</MenuItem>:null}
								<MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={handleDelete}>
									Delete
								</MenuItem>
							</MenuList>
						</Menu>}
					</Box>
				</HStack>
			</HStack>
			<AlertDialog
			isOpen={isDeleteDialogOpen}
			leastDestructiveRef={cancelRef}
			onClose={handleCancelDelete}
			>
			<AlertDialogOverlay>
			<AlertDialogContent>
				<AlertDialogHeader fontSize="lg" fontWeight="bold">
				Delete deal?
				</AlertDialogHeader>

				<AlertDialogBody>
					You can't undo this action afterwards.
				</AlertDialogBody>

				<AlertDialogFooter>
				<Button ref={cancelRef} size='sm' onClick={handleCancelDelete}>
					Cancel
				</Button>
				<Button 
							size="sm"
						colorScheme="red"
						variant="solid" onClick={handleConfirmDelete} ml={3}>
					Delete
				</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
			</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
