import * as React from "react";
import { AlertIcon, Alert, Box, Flex, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export interface UpgradeNowAlertProps {
    message: string;
}

export const UpgradeNowAlert = ({ message }: UpgradeNowAlertProps) => {
    return (
        <Alert
            maxW="560px"
            mt="4"
            fontSize="14px"
            status="warning"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
        >
            <Flex alignItems="center">
                <AlertIcon />
                <Text color="blackAlpha.700" fontSize="15px" fontWeight="500">
                    {message}
                </Text>
            </Flex>
            <Link to="/app/settings/subscription/">
                <Button colorScheme="green" size="sm">
                    Upgrade Now
                </Button>
            </Link>
        </Alert>
    );
};
