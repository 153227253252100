import * as React from "react";

import {
	useToast,
	Switch,
	Box,
	Tooltip,
	FormLabel,
	FormControl
} from "@chakra-ui/react";

import { useState } from "react";
import { ToggleTorBrowserProtection } from "../../services/dealServices";
interface TorBrowserProtectionProps {
	id: string;
	isEnabled: boolean;
	defaultValue: boolean;
}

export const TorBrowserProtection = ({ id, isEnabled, defaultValue }: TorBrowserProtectionProps) => {
	const toast = useToast();
	const [isChecked, setIsChecked] = useState(defaultValue);

	const onTorBrowserButtonClick = React.useCallback(async () => {

		try {
			await ToggleTorBrowserProtection(
				{
					tor: !isChecked,
				},
				id

			);
			setIsChecked(!isChecked);
			toast({
				title: `Tor Browser protection has been turned ${isChecked ? 'OFF' : 'ON'}`,
				status: 'info',
				isClosable: true,
			});

			console.log(`Tor Browser protection has been turned ${isChecked ? 'OFF' : 'ON'}`);
		} catch (error) {
			console.error(`An error occurred while turning ${isChecked ? 'OFF' : 'ON'}`, error);

			toast({
				title: `An error occurred while turning ${isChecked ? 'OFF' : 'ON'}`,
				description:
					`An error occurred while turning ${isChecked ? 'OFF' : 'ON'}. Please try again`,
				status: "error",
				isClosable: true,
			});
		}
	}, [id, toast, isChecked]);

	return (
		<Box display='flex' alignItems='center'>
			<FormControl display='flex' alignItems='center'>
				<FormLabel width="450px" mt='4' fontSize="14px" color="gray.500" fontWeight="400">
					Tor enables anonymous web browsing by hiding users' locations. It can be misused to exploit location-based pricing by masking the user's real location, allowing access to lower prices by appearing from different regions.
				</FormLabel>
				{
					isEnabled ? <Switch mt='4' ml="8" colorScheme="teal" size="md" onChange={onTorBrowserButtonClick} defaultChecked={isChecked} /> : <Tooltip label='TOR browsing Protection is only available to PRO users' shouldWrapChildren>
						<Switch mt='4' ml="8" colorScheme="teal" size="md" isDisabled defaultChecked={false} />
					</Tooltip>
				}
			</FormControl>
		</Box>
	);
};