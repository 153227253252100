import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const GumroadGradientIcon = ({
  width = 20,
  height = 20,
  ...restProps
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      {...restProps}
    >
      <rect width="20" height="20" rx="4" fill="#F096E3" />
      <path
        d="M9.13564 16.25C5.74468 16.25 3.75 13.5239 3.75 10.1329C3.75 6.60904 5.94415 3.75 10.133 3.75C14.4548 3.75 15.9176 6.67553 15.984 8.33775H12.859C12.7925 7.40691 11.9947 6.01063 10.0665 6.01063C8.00531 6.01063 6.67553 7.80585 6.67553 9.99999C6.67553 12.1941 8.00531 13.9893 10.0665 13.9893C11.9282 13.9893 12.726 12.5266 13.0585 11.0638H10.0665V9.86699H16.3447V15.984H13.5904V12.1276C13.3909 13.5239 12.5266 16.25 9.13564 16.25Z"
        fill="black"
      />
    </svg>
  );
};
