import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const GumroadColorIcon = ({
  width = 60,
  height = 63,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_6692_28515)">
        <rect x="3" y="3" width="96" height="96" rx="24" fill="#F096E3" />
      </g>
      <path
        d="M46.6917 79.63C30.5154 79.63 21 66.6256 21 50.4492C21 33.6388 31.467 20 51.4493 20C72.0661 20 79.0441 33.9559 79.3612 41.8854H64.4537C64.1365 37.4449 60.3304 30.7841 51.1321 30.7841C41.2995 30.7841 34.956 39.348 34.956 49.815C34.956 60.282 41.2995 68.8458 51.1321 68.8458C60.0131 68.8458 63.8193 61.8679 65.4052 54.8898H51.1321V49.1805H81.082V78.3613H67.9427V59.9647C66.9912 66.6256 62.8678 79.63 46.6917 79.63Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_dd_6692_28515"
          x="0"
          y="0"
          width="102"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6692_28515"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_6692_28515"
            result="effect2_dropShadow_6692_28515"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_6692_28515"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
