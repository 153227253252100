import * as React from "react";

import {
	useToast,
	Switch,
	Box,
	Tooltip,
	FormLabel,
	FormControl
} from "@chakra-ui/react";

import { useState } from "react";

import { ToggleVpnProtection } from "../../services/dealServices";


interface VpnProtectionProps {
	id: string;
	isEnabled: boolean;
	defaultValue: boolean;
}

export const VpnProtection = ({ id, isEnabled, defaultValue }: VpnProtectionProps) => {
	const toast = useToast();
	const [isChecked, setIsChecked] = useState(defaultValue);


	const onVpnProtectionButtonClick = React.useCallback(async () => {

		try {
			await ToggleVpnProtection(
				{
					vpn: !isChecked,
				},
				id

			);
			setIsChecked(!isChecked);
			toast({
				title: `VPN protection has been turned ${isChecked ? 'OFF' : 'ON'}`,
				status: 'info',
				isClosable: true,
			});

			console.log(`VPN protection has been turned ${isChecked ? 'OFF' : 'ON'}`);
		} catch (error) {
			console.error(`An error occurred while turning ${isChecked ? 'OFF' : 'ON'}`, error);

			toast({
				title: `An error occurred while turning ${isChecked ? 'OFF' : 'ON'}`,
				description:
					`An error occurred while turning ${isChecked ? 'OFF' : 'ON'}. Please try again`,
				status: "error",
				isClosable: true,
			});
		}
	}, [id, toast, isChecked]);

	return (
		<Box display='flex' alignItems='center'>
			<FormControl display='flex' alignItems='center'>
				<FormLabel width="450px" mt='4' fontSize="14px" color="gray.500" fontWeight="400">
					Toggle the VPN off to verify the functionality of the banner on your site. Ensure that you switch VPN protection on to safeguard against unauthorized users accessing location-based discounts.
				</FormLabel>
				{
					isEnabled ? <Switch mt='4' ml="8" colorScheme="teal" size="md" onChange={onVpnProtectionButtonClick} defaultChecked={isChecked} /> : <Tooltip label='VPN Protection is only available to PRO users' shouldWrapChildren>
						<Switch mt='4' ml="8" colorScheme="teal" size="md" isDisabled defaultChecked={false} />
					</Tooltip>
				}
			</FormControl>
		</Box>
	);
};