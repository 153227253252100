import * as React from "react";
import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Code,
	Flex,
	Heading,
	HStack,
	IconButton,
	List,
	ListIcon,
	ListItem,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { CheckedCircleIcon } from "../../components/Icons/CheckedCircle";
import { DealsSelectProduct } from "../DealsSelectProduct/DealsSelectProduct";
import {
	DealsProductSetup,
	ProductSetupFormValues,
} from "../DealsProductSetup/DealsProductSetup";
import { CreateDealsCustomize } from "../CreateDealsCustomize/CreateDealsCustomize";
import { DealsCouponSetup } from "../DealsCouponSetup/DealsCouponSetup";
import { CheckCircleIcon } from "../../components/Icons/CheckCircle";
import { Product } from "../../services/productServices";
import {
	CountryDiscount,
	Deal,
	DealCustomizeData,
} from "../../types/deals.type";
import queryString from "query-string";
import { createDeal } from "../../services/dealServices";
import {
	CountryCategoryDiscount,
	CustomCountryDiscount,
	HolidayCategoryDiscount,
} from "../../services/commonServices";
import { DealsHolidaysCouponSetup } from "../DealsHolidaysCouponSetup/DealsHolidaysCouponSetup";
import { ArrowLeftIcon } from "../../components/Icons/ArrowLeft";
import { DealPlatform } from "../../types/types";
import { DealIcon } from "../../components/Icons/Deal";
import {
	DealsTimeCouponSetup,
	DealTimeFormValues,
} from "../DealsTimeCouponSetup/DealsTimeCouponSetup";
import posthog from "posthog-js";
import { getUserProfile } from "../../services/userServices";
import { CurrentUser } from "../../services/appConfig";
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

type Steps =
	| "select-product"
	| "product-setup"
	| "holiday-coupon-setup"
	| "location-coupon-setup"
	| "time-coupon-setup"
	| "customize"
	| "message"
	| "settings";

export interface DealSteps {
	type: Steps;
	name: string;
	isVisible: boolean;
	isChecked: boolean;
}

const defaultDealSteps: DealSteps[] = [
	{
		type: "select-product",
		name: "Select Product",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "product-setup",
		name: "Product setup",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "holiday-coupon-setup",
		name: "Holiday Deals",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "location-coupon-setup",
		name: "Location Deals",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "time-coupon-setup",
		name: "Time based Deals",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "customize",
		name: "Customize",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "message",
		name: "",
		isVisible: true,
		isChecked: false,
	},
	{
		type: "settings",
		name: "Settings",
		isVisible: true,
		isChecked: false,
	},
];

export const CreateDeals = () => {
	const toast = useToast();
	const history = useHistory();
	const [selectedProducts, setSelectedProducts] = React.useState<Product[]>(
		[] as Product[]
	);

	const [productType, setProductType] = React.useState<DealPlatform>("manual");
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [dealIdentifier, setDealIdentifier] = React.useState<string>();
	const [productData, setProductData] = React.useState<
		Omit<
			ProductSetupFormValues,
			| "isLocationDealsSelected"
			| "isHolidayDealsSelected"
			| "isTimeDealsSelected"
		>
	>({} as ProductSetupFormValues);
	const [discounts, setDiscounts] = React.useState<CountryDiscount[]>(
		[] as CountryDiscount[]
	);
	const [customDiscounts, setCustomDiscounts] = React.useState<CustomCountryDiscount[]>(
		[] as CustomCountryDiscount[]
	);
	const [holidayDiscounts, setHolidayDiscounts] = React.useState<
		HolidayCategoryDiscount[]
	>([] as HolidayCategoryDiscount[]);
	const [timeDiscounts, setTimeDiscounts] = React.useState<DealTimeFormValues>(
		{} as DealTimeFormValues
	);
	const [customizedData, setCustomizedData] =
		React.useState<DealCustomizeData>();

	const [dealSteps, setDealSteps] =
		React.useState<DealSteps[]>(defaultDealSteps);

	const [currentStep, setCurrentStep] = React.useState<DealSteps>(
		defaultDealSteps[0]
	);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const pdErrorCode = urlParams.get('pd_error_code');
		const pdErrorMessage = urlParams.get('pd_error_message');
		if (pdErrorCode === 'ACCOUNT_ALREADY_EXISTS') {
			toast({
				title: "Account Already Exists",
				description: pdErrorMessage,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			history.push("/app/deals/");
		}
	}, [history, toast]);

	useEffect(() => {
		async function fetchData() {
			let location = window.location.search;
			const parsed = queryString.parse(location);
			if (parsed.type === "gumroad") {
				setProductType("gumroad");
			} else if (parsed.type === "stripe") {
				setProductType("stripe");
			} else if (parsed.type === "paddle") {
				setProductType("paddle");
			} else if (parsed.type === "lemonsqueezy") {
				setProductType("lemonsqueezy");
			} else if (parsed.type === "whop") {
				setProductType("whop");
			} else if (parsed.type === "chargebee") {
				setProductType("chargebee");
			} else {
				setProductType("manual");
				//setCurrentStep("product-setup");
				setCurrentStep(
					defaultDealSteps.find((step) => step.type === "product-setup") as DealSteps
				);
			}
		}
		fetchData();
	}, []);

	React.useEffect(() => {
		async function fetchData() {
			try {
				const response = await getUserProfile();
				CurrentUser.set(response.data);
				posthog.identify(
					response.data.email,
					{ email: response.data.email }
				);
				posthog.capture('$pageview');
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const goToNextStep = React.useCallback(
		(step: Steps) => {
			const holidayStep = dealSteps.find(
				(step) => step.type === "holiday-coupon-setup"
			);
			const locationStep = dealSteps.find(
				(step) => step.type === "location-coupon-setup"
			);
			const timeStep = dealSteps.find(
				(step) => step.type === "time-coupon-setup"
			);
			const customizeStep = dealSteps.find(
				(step) => step.type === "customize"
			) as DealSteps;
			const productSetupStep = dealSteps.find(
				(step) => step.type === "product-setup"
			) as DealSteps;
			if (step === "select-product") {
				setCurrentStep(productSetupStep);
			} else if (step === "product-setup") {
				if (locationStep?.isVisible) {
					setCurrentStep(locationStep);
				} else if (holidayStep?.isVisible) {
					setCurrentStep(holidayStep);
				} else if (timeStep?.isVisible) {
					setCurrentStep(timeStep);
				} else {
					setCurrentStep(customizeStep);
				}
			} else if (step === "location-coupon-setup") {
				if (holidayStep?.isVisible) {
					setCurrentStep(holidayStep);
				} else if (timeStep?.isVisible) {
					setCurrentStep(timeStep);
				} else {
					setCurrentStep(customizeStep);
				}
			} else if (step === "holiday-coupon-setup") {
				if (timeStep?.isVisible) {
					setCurrentStep(timeStep);
				} else {
					setCurrentStep(customizeStep);
				}
			} else if (step === "time-coupon-setup") {
				setCurrentStep(customizeStep);
			}
		},
		[dealSteps]
	);
	const goToPrevStep = React.useCallback(
		(step: Steps) => {
			const holidayStep = dealSteps.find(
				(step) => step.type === "holiday-coupon-setup"
			);
			const locationStep = dealSteps.find(
				(step) => step.type === "location-coupon-setup"
			);
			const timeStep = dealSteps.find(
				(step) => step.type === "time-coupon-setup"
			);
			const customizeStep = dealSteps.find(
				(step) => step.type === "customize"
			) as DealSteps;
			const productSetupStep = dealSteps.find(
				(step) => step.type === "product-setup"
			) as DealSteps;
			const selectProductStep = dealSteps.find(
				(step) => step.type === "select-product"
			) as DealSteps;
			if (step === "product-setup") {
				setCurrentStep(selectProductStep);
			} else if (step === "location-coupon-setup") {
				setCurrentStep(productSetupStep);
			} else if (step === "holiday-coupon-setup") {
				if (locationStep?.isVisible) {
					setCurrentStep(locationStep);
				} else {
					setCurrentStep(productSetupStep);
				}
			} else if (step === "time-coupon-setup") {
				if (holidayStep?.isVisible) {
					setCurrentStep(holidayStep);
				} else if (locationStep?.isVisible) {
					setCurrentStep(locationStep);
				} else {
					setCurrentStep(productSetupStep);
				}
			} else if (step === "customize") {
				if (timeStep?.isVisible) {
					setCurrentStep(timeStep);
				} else if (holidayStep?.isVisible) {
					setCurrentStep(holidayStep);
				} else if (locationStep?.isVisible) {
					setCurrentStep(locationStep);
				} else {
					setCurrentStep(productSetupStep);
				}
			}
		},
		[dealSteps]
	);

	const getProductSetupComponent = React.useCallback(() => {
		if ((productType === "gumroad" || productType === "stripe" || productType === "paddle" || productType === "lemonsqueezy" || productType === "whop" || productType === "chargebee") && !selectedProducts[0]?.name) {
			return null;
		}
		return (
			<DealsProductSetup
				defaultProductName={selectedProducts[0]?.name || ""}
				showDescription={productType === "manual"}
				onSecondaryBtnClick={() => {
					goToPrevStep("product-setup");
				}}
				showSelectDealType
				disableSubmitBtnByDefault
				showTimeBasedDealType={productType === "gumroad" || productType === 'stripe' || productType === 'paddle'  || productType === 'lemonsqueezy' || productType === 'whop' || productType === 'chargebee'}
				onPrimaryBtnClick={({
					isLocationDealsSelected,
					isHolidayDealsSelected,
					isTimeDealsSelected,
					...formValues
				}: ProductSetupFormValues) => {
					const currentDealSteps = [...dealSteps];

					const holidayStep = currentDealSteps.find(
						(step) => step.type === "holiday-coupon-setup"
					) as DealSteps;
					const locationStep = currentDealSteps.find(
						(step) => step.type === "location-coupon-setup"
					) as DealSteps;
					const timeStep = currentDealSteps.find(
						(step) => step.type === "time-coupon-setup"
					) as DealSteps;

					holidayStep.isVisible = isHolidayDealsSelected;
					locationStep.isVisible = isLocationDealsSelected;
					timeStep.isVisible = isTimeDealsSelected;
					setDealSteps(currentDealSteps);
					setProductData(formValues);
					goToNextStep("product-setup");
				}}
			/>
		);
	}, [
		dealSteps,
		goToNextStep,
		goToPrevStep,
		productType,
		selectedProducts,
	]);

	const getMainComponent = React.useCallback(() => {
		return (
			<>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep.type === "select-product" ? "flex" : "none",
					}}
				>
					<DealsSelectProduct
						platform={productType}
						primaryButtonLabel="Next"
						onBackClick={() => {}}
						isVisibleBackButton={false}
						onNextClick={(product: Product[]) => {
							setSelectedProducts(product);
							goToNextStep("select-product");
						}}
					/>
				</Flex>

				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep.type === "product-setup" ? "flex" : "none",
					}}
				>
					{getProductSetupComponent()}
				</Flex>

				<Flex
					h="100%"
					direction="column"
					style={{
						display:
							currentStep.type === "location-coupon-setup" ? "flex" : "none",
					}}
				>
					<DealsCouponSetup
						platform={productType}
						onBackClick={() => {
							goToPrevStep("location-coupon-setup");
						}}
						onNextClick={(
							countryCategoryDiscount: CountryCategoryDiscount[],
							customDiscounts: CustomCountryDiscount[]
						) => {
							const updatedDiscounts = countryCategoryDiscount.map(
								(countryDiscount) => {
									return {
										pppFactor: countryDiscount.range,
										couponCode: countryDiscount.couponCode,
										discountPercentage: countryDiscount.discountPercentage,
									};
								}
							);
							setDiscounts(updatedDiscounts);
							setCustomDiscounts(customDiscounts)
							goToNextStep("location-coupon-setup");
						}}
					/>
				</Flex>

				<Flex
					h="100%"
					direction="column"
					style={{
						display:
							currentStep.type === "holiday-coupon-setup" ? "flex" : "none",
					}}
				>
					<DealsHolidaysCouponSetup
						platform={productType}
						onBackClick={() => {
							goToPrevStep("holiday-coupon-setup");
						}}
						onNextClick={(
							holidayCategoryDiscount: HolidayCategoryDiscount[]
						) => {
							const updatedDiscounts = holidayCategoryDiscount.map(
								(holidayDiscount) => {
									return {
										startBefore: holidayDiscount.startBefore,
										endAfter: holidayDiscount.endAfter,
										couponCode: holidayDiscount.couponCode,
										discountPercentage: holidayDiscount.discountPercentage,
										holiday: holidayDiscount.holiday,
									};
								}
							);
							setHolidayDiscounts(updatedDiscounts);
							goToNextStep("holiday-coupon-setup");
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep.type === "time-coupon-setup" ? "flex" : "none",
					}}
				>
					<DealsTimeCouponSetup
						platform={productType}
						onNextClick={(values: DealTimeFormValues) => {
							console.log("values", values);
							setTimeDiscounts(values);
							goToNextStep("time-coupon-setup");
						}}
						onBackClick={() => {
							goToPrevStep("time-coupon-setup");
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep.type === "customize" ? "flex" : "none",
					}}
				>
					<CreateDealsCustomize
						onBackClick={() => {
							goToPrevStep("customize");
						}}
						showCountryMessage={
							dealSteps.find((step) => step.type === "location-coupon-setup")
								?.isVisible
						}
						showHolidayMessage={
							dealSteps.find((step) => step.type === "holiday-coupon-setup")
								?.isVisible
						}
						showTimeMessage={
							dealSteps.find((step) => step.type === "time-coupon-setup")
								?.isVisible
						}
						isSubmitting={isSubmitting}
						onNextClick={async (formData: DealCustomizeData) => {
							setCustomizedData(formData);

							const holidayStep = dealSteps.find(
								(step) => step.type === "holiday-coupon-setup"
							) as DealSteps;
							const locationStep = dealSteps.find(
								(step) => step.type === "location-coupon-setup"
							) as DealSteps;
							const timeStep = dealSteps.find(
								(step) => step.type === "time-coupon-setup"
							) as DealSteps;

							const createDealsFormData: Deal = {
								...formData,
								discountBar: locationStep.isVisible ? discounts : [],
								countryBar: locationStep.isVisible ? customDiscounts : [],
								holidayBar: holidayStep.isVisible ? holidayDiscounts : [],
								timeBar: timeStep.isVisible ? [timeDiscounts] : [],
								targetPagesBar: productData.targetPagesBar,
								website: productData.website,
								name: productData.name,
								description: productData.description,
								imageUrl: selectedProducts[0]?.previewUrl,
								isDraft: false,
								...((productType === "gumroad" || productType === "stripe" || productType === "paddle" || productType === "lemonsqueezy" || productType === "whop" || productType === "chargebee") && {
									integratedPlatform: selectedProducts?.map(product => {
										return {
											platform: productType,
											identifier: product.id,
											sync: true,
										}}),
								}),
							};
							
							try {
								setIsSubmitting(true);
								const response =  await createDeal(createDealsFormData);
								setDealIdentifier(response.data.identifier)
								setCurrentStep(
									defaultDealSteps.find(
										(step) => step.type === "message"
									) as DealSteps
								);
							} catch (error) {
								toast({
									title: `Oops! Unable to add deal`,
									status: "error",
									isClosable: true,
								});
							} finally {
								setIsSubmitting(false);
							}
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep.type === "message" ? "flex" : "none",
					}}
					overflow="auto"
					pt={20}
					pb={20}
					alignItems="center"
				>
					<Flex direction="column" >
					
						<Flex justifyContent="center" height="164">
							<DealIcon />
						</Flex>
						<Heading mt="8">Deal has been successfully created</Heading>
						<Heading fontSize="x-large" mt="8">Use any of the following option to display discounts on your site</Heading>
							
						<Heading fontSize="medium" mt="8"> 1) Add the below script to your site to display the banner.</Heading>
						<Code p="4" shadow="sm" mt="12" borderRadius="md">
							&lt;script
							src="https://cdn.paritydeals.com/banner.js"&gt;&lt;/script&gt;
						</Code>
						<HStack mt="4">
							<Link to="/app/deals/">
								<Button variant="outline">View Deals</Button>
							</Link>
							<Button
								bg="blackAlpha.900"
								_hover={{ bg: "blackAlpha.900" }}
								_active={{ bg: "blackAlpha.900" }}
								colorScheme="blackAlpha"
								variant="solid"
								isLoading={isSubmitting}
								isDisabled={isSubmitting}
								onClick={() => {
									navigator.clipboard.writeText(
										`<script src="https://cdn.paritydeals.com/banner.js"></script>`
									);
									toast({
										title: `Copied to Clipboard`,
										status: "info",
										isClosable: true,
									});
								}}
							>
								Copy code
							</Button>
						</HStack>
						<Heading fontSize="medium" mt="8"> 2) Use our API to build a custom solution.</Heading>
							<Code p="4" shadow="sm" mt="12" borderRadius="md">
								{dealIdentifier}
							</Code>
							<HStack mt="4">
								<a href="https://www.paritydeals.com/docs/api/deals-discount/">
									<Button variant="outline">View Docs</Button>
								</a>
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={isSubmitting}
									isDisabled={isSubmitting}
									onClick={() => {
										navigator.clipboard.writeText(
											dealIdentifier || ''
										);
										toast({
											title: `Copied to Clipboard`,
											status: "info",
											isClosable: true,
										});
									}}
								>
									Copy Identifier
								</Button>
							</HStack>
						<Flex alignItems="center">
							<Alert maxW="600px" mt="12" status="info">
								<AlertIcon />
								If you face any issues, please contact us at hi@paritydeals.com
							</Alert>
						</Flex>
					</Flex>
				</Flex>
			</>
		);
	}, [currentStep.type, customDiscounts, dealIdentifier, dealSteps, discounts, getProductSetupComponent, goToNextStep, goToPrevStep, holidayDiscounts, isSubmitting, productData.description, productData.name, productData.targetPagesBar, productData.website, productType, selectedProducts, timeDiscounts, toast]);

	return (
		<VStack w="100%" h="100vh" p="6" pl="20" pt="10" spacing="10">
			<Flex shrink={0} alignItems="center" w="100%" justifyContent="start">
				<Link to="/app/deals/">
					<IconButton
						borderRadius="full"
						shadow="lg"
						ml="-14"
						mr="5"
						borderColor="gray.500"
						colorScheme="whiteAlpha"
						aria-label="Go to deals"
						icon={<ArrowLeftIcon />}
					/>
				</Link>
				<Heading fontSize="30">Create deals</Heading>
			</Flex>
			<Flex flex={1} h="calc(100% - 100px)" w="100%">
				<Box
					w="100%"
					maxW="1320px"
					p="6"
					bg="white"
					shadow="sm"
					h="100%"
					borderRadius="lg"
					position="relative"
				>
					{getMainComponent()}
				</Box>
			</Flex>
		</VStack>
	);
};
