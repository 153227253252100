import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../constants/app';

export interface Product {
	id: string;
	name: string;
	previewUrl: string;
}

export async function fetchGumroadProducts(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/gumraod-products/',
	});
}
export async function syncProductsService(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		url: '/integrations/sync-products/',
	});
}

export async function fetchStripeProducts(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/stripe-products/',
	});
}
export async function fetchPaddleProducts(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/paddle-products/',
	});
}
export async function fetchLemonSqueezyProducts(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/lemon-squeezy-products/',
	});
}
export async function fetchWhopProducts(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/whop-products/',
	});
}
export async function fetchChargebeeProducts(): Promise<
	AxiosResponse<Product[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/chargebee-products/',
	});
}
