import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const CheckIcon = ({
	width = 14,
	height = 14,
	...restProps
}: IconProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 14 14'
			{...restProps}
		>
			<path
				d='M5.83333 8.85038L11.1953 3.48779L12.0207 4.31263L5.83333 10.5L2.12099 6.78771L2.94583 5.96288L5.83333 8.85038Z'
				fill='#2D3748'
			/>
		</svg>
	);
};
