import * as React from "react";

import {
	useToast,
	Switch,
	Box,
	Tooltip,
	FormControl,
	FormLabel
} from "@chakra-ui/react";

import { useState } from "react";

import { ToggleProxyProtection } from "../../services/dealServices";
interface ProxyProtectionProps {
	id: string;
	isEnabled: boolean;
	defaultValue: boolean;
}

export const ProxyProtection = ({ id, isEnabled, defaultValue }: ProxyProtectionProps) => {

	const toast = useToast();
	const [isChecked, setIsChecked] = useState(defaultValue);

	const onProxyButtonClick = React.useCallback(async () => {

		try {
			await ToggleProxyProtection(
				{
					proxy: !isChecked,
				},
				id

			);
			setIsChecked(!isChecked);
			toast({
				title: `Proxy protection has been turned ${isChecked ? 'OFF' : 'ON'}`,
				status: 'info',
				isClosable: true,
			});

			console.log(`Proxy protection has been turned ${isChecked ? 'OFF' : 'ON'}`);
		} catch (error) {
			console.error(`An error occurred while turning ${isChecked ? 'OFF' : 'ON'}`, error);

			toast({
				title: `An error occurred while turning ${isChecked ? 'OFF' : 'ON'}`,
				description:
					`An error occurred while turning ${isChecked ? 'OFF' : 'ON'}. Please try again`,
				status: "error",
				isClosable: true,
			});
		}
	}, [id, toast, isChecked]);

	return (
		<Box display='flex' alignItems='center'>
			<FormControl display='flex' alignItems='center' >
				<FormLabel isReadOnly htmlFor='email-alerts' width="450px" mt='4' fontSize="14px" color="gray.500" fontWeight="400">
					Proxy servers act as intermediaries between a user's device and the internet, offering privacy by hiding the user's IP address. This can be exploited to bypass location-based pricing, enabling users to access discounts restricted to certain regions by appearing to be in a different geographical location.
				</FormLabel>
				{isEnabled ? (
					<Switch mt='4' ml="8" colorScheme="teal" size="md" onChange={onProxyButtonClick} defaultChecked={isChecked} />
				) : (
					<Tooltip label='Proxy servers Protection is only available to Pro users.' shouldWrapChildren>
						<Switch mt='4' ml="8" colorScheme="teal" size="md" isDisabled defaultChecked={false} />
					</Tooltip>
				)}
			</FormControl>
		</Box>
	);
};