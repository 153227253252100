import * as React from "react";
import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Flex,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	Text,
	Link as ChakraLink,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Spinner,
	Stack,
	Tooltip,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";

import posthog from "posthog-js";

import { useEffect, useState } from "react";
import { deleteDeal, fetchDeals, refreshCoupon } from "../../services/dealServices";
import { Deal } from "../../types/deals.type";
import {
	MediaListItem,
	MetaListMenuItems,
} from "../../components/MediaListItem/MediaListItem";
import { NoDataDeals } from "../../components/NoData/NoDataDeals";
import { PlusIcon } from "../../components/Icons/Plus";
import {
	getGumroadAuthUrl,
	getPaddleAuthUrl,
	getStripeAuthUrl,
	authenticateWithPaddle,
	authenticateWithLemonSqueezy,
	getLemonSqueezyAuthUrl,
	authenticateWithWhop,
	authenticateWithChargebee,
	getWhopAuthUrl,
	getChargebeeAuthUrl,
} from "../../services/authServices";
import { CurrentUser } from "../../services/appConfig";
import { StripeIcon } from "../../components/Icons/Stripe";
import { GumroadGradientIcon } from "../../components/Icons/GumroadGradient";
import { PlusBlackIcon } from "../../components/Icons/PlusBlack";
import { PaddleColorIcon } from "../../components/Icons/PaddleColor";
import { LemonSqueezyColorIcon } from "../../components/Icons/LemonSqueezy";
import { WhopColorIcon } from "../../components/Icons/Whop";
import { ChargebeeColorIcon } from "../../components/Icons/Chargebee";
import { PaddleAuthFormForm } from "../../widgets/PaddleAuthForm/PaddleAuthForm";
import { LemonSqueezyAuthForm } from "../../widgets/LemonSqueezyAuthForm/LemonSqueezyAuthForm";
import { WhopAuthForm } from "../../widgets/WhopAuthForm/WhopAuthForm";
import { ChargebeeAuthForm } from "../../widgets/ChargebeeAuthForm/ChargebeeAuthForm";
import { PaddleAuthData, LemonsqueezyAuthData, WhopAuthData, ChargebeeAuthData } from "../../types/types";

export const DealsList = () => {
	const toast = useToast();
	const [dealsList, setDealsList] = React.useState<Deal[]>();
	const [dealsLoaded, setDealsLoaded] = React.useState<boolean>(false);
	const user = CurrentUser.get();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isLemonSqueezyOpen, onOpen:onLemonSqueezyOpen, onClose:onLemonSqueezyClose } = useDisclosure();
	const { isOpen: isWhopOpen, onOpen:onWhopOpen, onClose:onWhopClose } = useDisclosure();
	const { isOpen: isChargebeeOpen, onOpen:onChargebeeOpen, onClose:onChargebeeClose } = useDisclosure();
	const initialRef = React.useRef(null);
	const initialLemonSqueezyRef = React.useRef(null);
	const initialWhopRef = React.useRef(null);
	const initialChargebeeRef = React.useRef(null);



	const history = useHistory();

	const [gumroadLink, setGumroadLink] = useState<string>("");
	const [stripeLink, setStripeLink] = useState<string>("");
	const [paddleLinked, setPaddleLinked] = useState<boolean>();
	const [lemonSqueezyLinked, setLemonSqueezyLinked] = useState<boolean>();
	const [whopLinked, setWhopLinked] = useState<boolean>();
	const [chargebeeLinked, setChargebeeLinked] = useState<boolean>();
	const [isPaddleSubmitting, setIsPaddleSubmitting] = useState<boolean>(false);
	const [isLemonSqueezySubmitting, setIsLemonSqueezySubmitting] = useState<boolean>(false);
	const [isWhopSubmitting, setIsWhopSubmitting] = useState<boolean>(false);
	const [isChargebeeSubmitting, setIsChargebeeSubmitting] = useState<boolean>(false);

	

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getGumroadAuthUrl();
				if (!response.data.status) {
					setGumroadLink(
						response.data.authUrl +
						"+https://app.paritydeals.com/app/create-deals/?type=gumroad"
					);
				}
				const stripeResponse = await getStripeAuthUrl();
				if (!stripeResponse.data.status) {
					setStripeLink(
						stripeResponse.data.authUrl +
						"+https://app.paritydeals.com/app/create-deals/?type=stripe"
					);
				}
				const paddleResponse = await getPaddleAuthUrl();

				setPaddleLinked(paddleResponse.data.status);

				const lemonSqueezyResponse = await getLemonSqueezyAuthUrl();
				setLemonSqueezyLinked(lemonSqueezyResponse.data.status);
				
				const whopSqueezyResponse = await getWhopAuthUrl();
				setWhopLinked(whopSqueezyResponse.data.status);
				
				const chargebeeSqueezyResponse = await getChargebeeAuthUrl();
				setChargebeeLinked(chargebeeSqueezyResponse.data.status);
			} catch (error) {
				console.log("Unable to connect with gumroad", error);
			} finally {
			}
		}
		fetchData();
	}, []);
	
	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);

	const onDealMenuItemClick = React.useCallback(
		async (type: MetaListMenuItems, deal: Deal) => {
			console.log("type", type);
			if (type === "edit") {
				history.push(`/app/edit-deals/${deal.id}/`);
			} else if (type === "getCode") {
				history.push(`/app/edit-deals/${deal.id}/?step=add-to-site`);
			} else if (type === "customize") {
				history.push(`/app/edit-deals/${deal.id}/?step=customize`);
			} else if (type === "delete") {
				await deleteDeal(deal.id as string);
				setDealsList(dealsList?.filter((_deal) => _deal.id !== deal.id));
				toast({
					title: `Deal has been deleted successfully `,
					status: "success",
					isClosable: true,
				});
			} else if (type === "refreshCoupon") {
				try {
					await refreshCoupon(deal.id as string);

					toast({
						title: `Coupons has been refreshed successfully`,
						status: "success",
						isClosable: true,
					});
				} catch (error) {
					console.error("An error occurred while refreshing coupons :", error);

					toast({
						title: "Error while refreshing Coupons",
						description:
							"An error occurred while refreshing coupons. Please try again.",
						status: "error",
						isClosable: true,
					});
				}
			}		  
		},
		[history, dealsList, toast]
	);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetchDeals();
				setDealsLoaded(true);
				setDealsList(response.data);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const getNoDataIcon = React.useCallback(() => {
		return (
			<VStack
				spacing="9"
				alignItems="center"
				direction="column"
				justifyContent="center"
				w="100%"
				h="100%"
			>
				<NoDataDeals />
				<Heading
					maxW="780px"
					fontSize="2xl"
					fontWeight="semibold"
					as="h3"
					lineHeight="medium"
					textAlign="center"
				>
					Offer your customers a dynamic pricing and discount based on their
					location, holidays, and more for your products.
				</Heading>
			</VStack>
		);
	}, []);

	const connectWithPaddle = React.useCallback(async (values: PaddleAuthData) => {
		setIsPaddleSubmitting(true);
		
		try {
			await authenticateWithPaddle({
				vendorAuthCode: values.vendorAuthCode,
				vendorId: values.vendorId,
				version: values.version,
			})
			toast({
				title: `Successfully connected with Paddle`,
				status: "success",
				isClosable: true,
			});
			history.push("/app/create-deals/?type=paddle");
		} catch (error) {
			toast({
				title: `Oops! Unable to connect with Paddle.`,
				description: "Make sure you have at least one product on your Paddle account",
				status: "error",
				isClosable: true,
			});
		} finally {
			setIsPaddleSubmitting(false);
		}

	}, [history, toast]);
	
	const connectWithLemonSqueezy = React.useCallback(async (values: LemonsqueezyAuthData) => {
		setIsLemonSqueezySubmitting(true);
		try {
			await authenticateWithLemonSqueezy({
				apiKey: values.apiKey,
			})
			setIsLemonSqueezySubmitting(false);
			toast({
				title: `Successfully connected with lemonsqueezy`,
				status: "success",
				isClosable: true,
			});
			history.push("/app/create-deals/?type=lemonsqueezy");
		} catch (error) {
			setIsLemonSqueezySubmitting(false);
			toast({
				title: `Oops! Unable to connect with lemonsqueezy.`,
				description: "Make sure you have at least one product on your LemonSqueezy account",
				status: "error",
				isClosable: true,
			});
		}
	}, [history, toast]);
	
	const connectWithWhop = React.useCallback(async (values: WhopAuthData) => {
		setIsWhopSubmitting(true);
		try {
			await authenticateWithWhop({
				apiKey: values.apiKey,
			})
			setIsWhopSubmitting(false);
			toast({
				title: `Successfully connected with whop`,
				status: "success",
				isClosable: true,
			});
			history.push("/app/create-deals/?type=whop");
		} catch (error) {
			setIsWhopSubmitting(false);
			toast({
				title: `Oops! Unable to connect with Whop.`,
				description: "Make sure you have at least one product on your Whop account",
				status: "error",
				isClosable: true,
			});
		}
	}, [history, toast]);
	
	const connectWithChargebee = React.useCallback(async (values: ChargebeeAuthData) => {
		setIsChargebeeSubmitting(true);
		try {
			await authenticateWithChargebee({
				apiKey: values.apiKey,
				site: values.site,
			})
			setIsChargebeeSubmitting(false);
			toast({
				title: `Successfully connected with Chargebee`,
				status: "success",
				isClosable: true,
			});
			history.push("/app/create-deals/?type=chargebee");
		} catch (error) {
			setIsChargebeeSubmitting(false);
			toast({
				title: `Oops! Unable to connect with Chargebee.`,
				status: "error",
				isClosable: true,
			});
		}
	}, [history, toast]);

	const getDealsList = React.useCallback(() => {
		if (!dealsLoaded) {
			return (
				<Flex w="100%" minH="300px" justifyContent="center" alignItems="center">
					<Spinner
						thickness="2px"
						speed="0.65s"
						emptyColor="gray.200"
						color="gray.700"
						size="xl"
					/>
				</Flex>
			);
		}
		if (!dealsList?.length) {
			return getNoDataIcon();
		}
		const dealListItems = dealsList?.map((deal) => {
			return (
				<MediaListItem
					showCustomizeMenu
					name={deal.name}
					imageAlt={deal.name}
					showGetCodeMenu
					imageUrl={deal.imageUrl}
					description={deal.description}
					key={deal.id}
					detailId={deal.id}
					actionButtonLeftIcon={null}
					onMenuItemClick={(type: MetaListMenuItems) => {
						onDealMenuItemClick(type, deal);
					}}
				></MediaListItem>
			);
		});
		return (
			<SimpleGrid columns={[1, 1, 1, 2]} spacing="6">
				{dealListItems}
			</SimpleGrid>
		);
	}, [dealsList, dealsLoaded, getNoDataIcon, onDealMenuItemClick]);

	const canAddDeal = React.useCallback(() => {
	return (user.isActiveSubscription || !dealsList || dealsList.length === 0)
	}, [dealsList, user.isActiveSubscription]);

	return (
		<Box
			w="100%"
			maxW="1200px"
			p="6"
			shadow="sm"
			h="100%"
			position="relative"
			overflow="auto"
		>
			<VStack w="100%" spacing="10" >
				<Flex shrink={0} w="100%" justifyContent="space-between">
					<Heading fontSize="30">Deals</Heading>
					<Stack direction="row" spacing={4}>
					
						<Menu>
							<Tooltip
								isDisabled={
									canAddDeal()
								}
								label="Please subscribe to add more deals"
							>
								<MenuButton as={Button} bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									//disabled={!canAddDeal()}
									leftIcon={<PlusIcon />}>
									Create Deals
								</MenuButton>
							</Tooltip>
							{canAddDeal() ? <MenuList>
								<MenuItem disabled={!canAddDeal()}>
									{gumroadLink ? (
										<a href={gumroadLink}>
											<Flex alignItems="center" fontSize="sm">
												<GumroadGradientIcon style={{ marginRight: '6px' }} />
												Create Deals via Gumroad
											</Flex>
										</a>
									) : (
										<Link to="/app/create-deals/?type=gumroad">
											<Flex alignItems="center" fontSize="sm">
												<GumroadGradientIcon style={{ marginRight: '6px' }} />
												Create Deals via Gumroad
											</Flex>

										</Link>
									)}
								</MenuItem>
								<MenuItem disabled={!canAddDeal()}>
									{stripeLink ? (
										<a href={stripeLink}>
											<Flex alignItems="center" fontSize="sm">
												<StripeIcon style={{ marginRight: '6px' }} />
												Create Deals via Stripe
											</Flex>
										</a>
									) : (
										<Link to="/app/create-deals/?type=stripe">
											<Flex alignItems="center" fontSize="sm">
												<StripeIcon style={{ marginRight: '6px' }} />
												Create Deals via Stripe
											</Flex>
										</Link>
									)}
								</MenuItem>
								{
									!paddleLinked ? <MenuItem onClick={onOpen} disabled={!canAddDeal()}>
										<Flex alignItems="center" fontSize="sm">
											<PaddleColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
											Create Deals via Paddle
										</Flex>
									</MenuItem> : <MenuItem>
										<Link to="/app/create-deals/?type=paddle">
											<Flex alignItems="center" fontSize="sm">
												<PaddleColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
												Create Deals via Paddle
											</Flex>
										</Link>

									</MenuItem>
								}
								{
									!lemonSqueezyLinked ? <MenuItem onClick={onLemonSqueezyOpen} disabled={!canAddDeal()}>
										<Flex alignItems="center" fontSize="sm">
											<LemonSqueezyColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
											Create Deals via Lemon Squeezy
										</Flex>
									</MenuItem> : <MenuItem>
										<Link to="/app/create-deals/?type=lemonsqueezy">
											<Flex alignItems="center" fontSize="sm">
												<LemonSqueezyColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
												Create Deals via Lemon Squeezy
											</Flex>
										</Link>

									</MenuItem>
								}
								{
									!whopLinked ? <MenuItem onClick={onWhopOpen} disabled={!canAddDeal()}>
										<Flex alignItems="center" fontSize="sm">
											<WhopColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
											Create Deals via Whop
										</Flex>
									</MenuItem> : <MenuItem>
										<Link to="/app/create-deals/?type=whop">
											<Flex alignItems="center" fontSize="sm">
												<WhopColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
												Create Deals via Whop
											</Flex>
										</Link>

									</MenuItem>
								}
								{
									!chargebeeLinked ? <MenuItem onClick={onChargebeeOpen} disabled={!canAddDeal()}>
										<Flex alignItems="center" fontSize="sm">
											<ChargebeeColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
											Create Deals via Chargebee
										</Flex>
									</MenuItem> : <MenuItem>
										<Link to="/app/create-deals/?type=chargebee">
											<Flex alignItems="center" fontSize="sm">
												<ChargebeeColorIcon width={20} height={20} style={{ marginRight: '6px' }} />
												Create Deals via Chargebee
											</Flex>
										</Link>

									</MenuItem>
								}

								<MenuItem disabled={!canAddDeal()}>
									<Link to="/app/create-deals/">
										<Flex alignItems="center" fontSize="sm">
											<PlusBlackIcon style={{ marginRight: '6px' }} />
											Create Deals manually
										</Flex>
									</Link>
								</MenuItem>
							</MenuList> : null}

						</Menu>
						
					</Stack>
				</Flex>
				<Box width="100%">{getDealsList()}</Box>
				<Modal
					initialFocusRef={initialRef}
					isOpen={isOpen}
					onClose={onClose}
					closeOnOverlayClick={false}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Authenticate with Paddle</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Alert status='info' mb="6">
								<AlertIcon />
								<Text>You can find the below details from your Paddle  <ChakraLink target="_blank" textDecoration="underline" fontWeight="medium" href='https://vendors.paddle.com/authentication'>
									authentication
								</ChakraLink> page</Text>
							</Alert>
							<PaddleAuthFormForm
								onBackClick={onClose}
								initialRef={initialRef}
								onNextClick={(values)=>{
									connectWithPaddle(values)
								}}
								isSubmitting={isPaddleSubmitting}
							></PaddleAuthFormForm>
						</ModalBody>
					</ModalContent>
				</Modal>
				<Modal
					initialFocusRef={initialLemonSqueezyRef}
					isOpen={isLemonSqueezyOpen}
					onClose={onLemonSqueezyClose}
					closeOnOverlayClick={false}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Authenticate with Lemon Squeezy</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Alert status='info' mb="6">
								<AlertIcon />
								<Text>Lemon Squeezy uses API keys for authentication. You can view and manage your API keys in your <ChakraLink target="_blank" textDecoration="underline" fontWeight="medium" href='https://app.lemonsqueezy.com/settings/api'>
								account settings.
								</ChakraLink></Text>
							</Alert>
							<LemonSqueezyAuthForm
								onBackClick={onLemonSqueezyClose}
								initialRef={initialLemonSqueezyRef}
								onNextClick={(values)=>{
									connectWithLemonSqueezy(values)
								}}
								isSubmitting={isLemonSqueezySubmitting}
							></LemonSqueezyAuthForm>
						</ModalBody>
					</ModalContent>
				</Modal>
				<Modal
					initialFocusRef={initialWhopRef}
					isOpen={isWhopOpen}
					onClose={onWhopClose}
					closeOnOverlayClick={false}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Authenticate with Whop</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Alert status='info' mb="6">
								<AlertIcon />
								<Text>Whop uses API keys for authentication. You can view and manage your API keys in your <ChakraLink target="_blank" textDecoration="underline" fontWeight="medium" href='https://dash.whop.com/settings/developer'>
								account settings.
								</ChakraLink></Text>
							</Alert>
							<WhopAuthForm
								onBackClick={onWhopClose}
								initialRef={initialWhopRef}
								onNextClick={(values)=>{
									connectWithWhop(values)
								}}
								isSubmitting={isWhopSubmitting}
							></WhopAuthForm>
						</ModalBody>
					</ModalContent>
				</Modal>
				<Modal
					initialFocusRef={initialChargebeeRef}
					isOpen={isChargebeeOpen}
					onClose={onChargebeeClose}
					closeOnOverlayClick={false}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Authenticate with Chargebee</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Alert status='info' mb="6">
								<AlertIcon />
								<Text>To create and configure the API keys, select Settings &gt; Configure Chargebee &gt; API Keys and Webhooks and then click on the API Keys tab. Any existing API keys are listed.</Text>
							</Alert>
							<ChargebeeAuthForm
								onBackClick={onChargebeeClose}
								initialRef={initialChargebeeRef}
								onNextClick={(values)=>{
									connectWithChargebee(values)
								}}
								isSubmitting={isChargebeeSubmitting}
							></ChargebeeAuthForm>
						</ModalBody>
					</ModalContent>
				</Modal>
			</VStack>
		</Box>
	);
};
