import React, { useState, useEffect } from "react";
import { Box, Text, Progress, HStack, Flex } from "@chakra-ui/react";
import { formatNumber } from "../../utils/commonUtils"
export interface APIUsageInfoProps {
  allowdPage: number;
  usedPage: number;
}

export const APIUsageInfo = ({ usedPage, allowdPage }: APIUsageInfoProps) => {
  const [progressValue, setProgressValue] = useState(
    (usedPage * 100) / allowdPage
  );
  const [islimitExccede, setIsLimitExcceded] = useState(usedPage >= allowdPage);

  useEffect(() => {
    setProgressValue((usedPage * 100) / allowdPage);
    setIsLimitExcceded(usedPage >= allowdPage);
  }, [usedPage, allowdPage]);

  return (
    <Flex
      bg="white"
      p="4"
      borderRadius="md"
      shadow="sm"
      direction={"column"}
      mt="48px"
    >
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Text pr="1" fontSize="16px" color="gray.700" fontWeight="600" lineHeight={"24px"}>Current month pricing page visits</Text>
           
          </Box>
          <Box>
            <HStack spacing={"1"}>
              <Text
                fontSize="16px"
                fontWeight={600}
                textColor={islimitExccede ? "red" : "black"}
              >
                 {formatNumber(usedPage)}
              </Text>
              <Text as="span">/ </Text>
              <Text fontSize="16px">
              {formatNumber(allowdPage)}
              {" "}price page visits
              </Text>
            </HStack>
          </Box>
        </Box>
        <Progress
          colorScheme={islimitExccede ? "red" : "purple"}
          size="sm"
          value={progressValue}
          borderRadius={"5"}
          mt="16px"
        
        />
      </Box>
    </Flex>
  );
};
