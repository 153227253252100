import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
}

export const StripeIcon = ({
  width = 20,
  height = 20,
  ...restProps
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      {...restProps}
    >
    <rect width="20" height="20" rx="4" fill="#625BF6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53973 7.2864C9.53973 6.77747 9.95582 6.58173 10.645 6.58173C11.6332 6.58173 12.8815 6.88187 13.8697 7.41689V4.35027C12.7905 3.91964 11.7242 3.75 10.645 3.75C8.00539 3.75 6.25 5.13324 6.25 7.44299C6.25 11.0446 11.1911 10.4705 11.1911 12.0234C11.1911 12.6236 10.671 12.8194 9.94282 12.8194C8.86358 12.8194 7.48527 12.3757 6.39303 11.7754V14.8812C7.6023 15.4032 8.82457 15.625 9.94282 15.625C12.6474 15.625 14.5068 14.2809 14.5068 11.9451C14.4938 8.05632 9.53973 8.74794 9.53973 7.2864Z" fill="white"/>
    </svg>

  );
};
