import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../constants/app';
import { MarketplaceDeal, MarketplaceDealFormData } from '../types/marketplaceDeal.type';

export interface Product {
	id: string;
	name: string;
	previewUrl: string;
}

export async function createMarketplaceDeal(data: MarketplaceDealFormData): Promise<AxiosResponse<MarketplaceDeal>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		data: data,
		url: '/marketplace/products/',
	});
}
export async function editMarketplaceDeal(data: Partial<MarketplaceDeal>): Promise<AxiosResponse<MarketplaceDeal>> {
	return await axios({
		baseURL: baseURL,
		method: 'patch',
		data: data,
		url: `/marketplace/products/${data.id}/`,
	});
}
export async function deleteMarketplaceDeal(id: string): Promise<AxiosResponse<MarketplaceDeal>> {
	return await axios({
		baseURL: baseURL,
		method: 'delete',
		url: `/marketplace/products/${id}/`,
	});
}

export async function fetchMarketplaceDeals(): Promise<AxiosResponse<MarketplaceDeal[]>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/marketplace/products/',
	});
}
export async function fetchMarketplaceDeal(id: string): Promise<AxiosResponse<MarketplaceDeal>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: `/marketplace/products/${id}/`,
	});
}
