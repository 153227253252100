import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const Logo = ({ width = 175, height = 28, ...restProps }: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			{...restProps}
			viewBox='0 0 175 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M36.1569 3.728C37.6106 3.728 38.8726 3.968 39.9429 4.448C41.0292 4.928 41.8599 5.616 42.435 6.512C43.01 7.408 43.2976 8.472 43.2976 9.704C43.2976 10.92 43.01 11.984 42.435 12.896C41.8599 13.792 41.0292 14.48 39.9429 14.96C38.8726 15.44 37.6106 15.68 36.1569 15.68H32.3709L29.2559 24.872V3.728H36.1569ZM36.0131 13.04C37.371 13.04 38.4014 12.752 39.1042 12.176C39.8071 11.6 40.1586 10.776 40.1586 9.704C40.1586 8.632 39.8071 7.808 39.1042 7.232C38.4014 6.656 37.371 6.368 36.0131 6.368H32.3709V13.04H36.0131Z'
				fill='black'
			/>
			<path
				d='M58.5822 7.712V20.528H55.7307V18.872C55.2355 19.48 54.6204 19.936 53.8856 20.24C53.1667 20.544 52.368 20.696 51.4894 20.696C50.2434 20.696 49.1252 20.424 48.1347 19.88C47.1603 19.336 46.3935 18.568 45.8344 17.576C45.2912 16.584 45.0197 15.432 45.0197 14.12C45.0197 12.808 45.2912 11.664 45.8344 10.688C46.3935 9.696 47.1603 8.928 48.1347 8.384C49.1252 7.84 50.2434 7.568 51.4894 7.568C52.3201 7.568 53.0789 7.712 53.7658 8C54.4687 8.288 55.0757 8.712 55.5869 9.272V7.712H58.5822ZM51.8249 18.128C52.9271 18.128 53.8377 17.76 54.5565 17.024C55.2754 16.288 55.6348 15.32 55.6348 14.12C55.6348 12.92 55.2754 11.952 54.5565 11.216C53.8377 10.48 52.9271 10.112 51.8249 10.112C50.7226 10.112 49.8121 10.48 49.0932 11.216C48.3903 11.952 48.0389 12.92 48.0389 14.12C48.0389 15.32 48.3903 16.288 49.0932 17.024C49.8121 17.76 50.7226 18.128 51.8249 18.128Z'
				fill='black'
			/>
			<path
				d='M65.3525 9.584C66.2152 8.24 67.7328 7.568 69.9053 7.568V10.424C69.6497 10.376 69.4181 10.352 69.2104 10.352C68.0443 10.352 67.1337 10.696 66.4788 11.384C65.8238 12.056 65.4963 13.032 65.4963 14.312L65.4426 20.528H63.9987H62.5011V7.712H65.3525V9.584Z'
				fill='black'
			/>
			<path
				d='M72.4462 7.712H75.4415L75.3217 20.48L72.4462 20.528V7.712ZM73.9558 5.6C73.4127 5.6 72.9574 5.432 72.59 5.096C72.2226 4.744 72.0389 4.312 72.0389 3.8C72.0389 3.288 72.2226 2.864 72.59 2.528C72.9574 2.176 73.4127 2 73.9558 2C74.499 2 74.9542 2.168 75.3217 2.504C75.6891 2.824 75.8728 3.232 75.8728 3.728C75.8728 4.256 75.6891 4.704 75.3217 5.072C74.9702 5.424 74.5149 5.6 73.9558 5.6Z'
				fill='black'
			/>
			<path
				d='M86.5613 19.832C86.2099 20.12 85.7786 20.336 85.2674 20.48C84.7722 20.624 84.245 20.696 83.6859 20.696C82.2801 20.696 81.1938 20.328 80.4271 19.592C79.6603 18.856 79.2769 17.784 79.2769 16.376V4.88H82.2721V7.808H85.6987V10.208H82.2721V16.304C82.2721 16.928 82.4239 17.408 82.7274 17.744C83.0469 18.064 83.4862 18.224 84.0453 18.224C84.7163 18.224 85.2754 18.048 85.7227 17.696L86.5613 19.832Z'
				fill='black'
			/>
			<path
				d='M101.425 7.712V18.584C101.425 23.096 99.213 25.352 94.788 25.352C93.6218 25.352 92.5036 25.192 91.4333 24.872C90.379 24.568 89.5083 24.12 88.8214 23.528L88.8446 23.5478C89.3877 24.0118 91.3694 22.104 92.1521 22.376C92.9349 22.648 93.7576 22.784 94.6202 22.784C95.9302 22.784 96.8886 22.464 97.4957 21.824C98.1187 21.2 98.4302 20.232 98.4302 18.92V18.32C97.951 18.848 97.3679 19.256 96.681 19.544C95.9941 19.816 95.2512 19.952 94.4525 19.952C92.7432 19.952 91.3934 19.48 90.4029 18.536C89.4285 17.592 88.9412 16.176 88.9412 14.288V7.712H91.9365V13.904C91.9365 15.04 92.1921 15.896 92.7033 16.472C93.2304 17.032 93.9733 17.312 94.9317 17.312C96.0021 17.312 96.8487 16.984 97.4717 16.328C98.1107 15.672 98.4302 14.72 98.4302 13.472V7.712H101.425Z'
				fill='black'
			/>
			<path
				d='M105.635 3.728H112.967C114.756 3.728 116.346 4.08 117.736 4.784C119.125 5.472 120.204 6.456 120.97 7.736C121.737 9 122.121 10.464 122.121 12.128C122.121 13.792 121.737 15.264 120.97 16.544C120.204 17.808 119.125 18.792 117.736 19.496C116.346 20.184 114.756 20.528 112.967 20.528H105.635V3.728ZM112.823 17.888C114.053 17.888 115.132 17.656 116.058 17.192C117.001 16.712 117.72 16.04 118.215 15.176C118.726 14.296 118.982 13.28 118.982 12.128C118.982 10.976 118.726 9.968 118.215 9.104C117.72 8.224 117.001 7.552 116.058 7.088C115.132 6.608 114.053 6.368 112.823 6.368H108.75V17.888H112.823Z'
				fill='black'
			/>
			<path
				d='M131.23 18.152C132.62 18.152 133.778 17.688 134.705 16.76L136.286 18.608C135.727 19.296 135.008 19.816 134.13 20.168C133.251 20.52 132.261 20.696 131.158 20.696C129.753 20.696 128.515 20.416 127.444 19.856C126.374 19.296 125.543 18.52 124.952 17.528C124.377 16.52 124.09 15.384 124.09 14.12C124.09 12.872 124.369 11.752 124.928 10.76C125.503 9.752 126.286 8.968 127.277 8.408C128.283 7.848 129.417 7.568 130.679 7.568C131.877 7.568 132.963 7.832 133.938 8.36C134.928 8.872 135.711 9.616 136.286 10.592C136.861 11.552 137.149 12.68 137.149 13.976L127.348 15.896C127.652 16.632 128.139 17.192 128.81 17.576C129.481 17.96 130.288 18.152 131.23 18.152ZM130.679 9.968C129.577 9.968 128.682 10.328 127.995 11.048C127.324 11.768 126.989 12.736 126.989 13.952V13.976L134.225 12.584C134.018 11.8 133.594 11.168 132.955 10.688C132.332 10.208 131.574 9.968 130.679 9.968Z'
				fill='black'
			/>
			<path
				d='M152.535 7.712V20.528H149.683V18.872C149.188 19.48 148.573 19.936 147.838 20.24C147.119 20.544 146.321 20.696 145.442 20.696C144.196 20.696 143.078 20.424 142.087 19.88C141.113 19.336 140.346 18.568 139.787 17.576C139.244 16.584 138.972 15.432 138.972 14.12C138.972 12.808 139.244 11.664 139.787 10.688C140.346 9.696 141.113 8.928 142.087 8.384C143.078 7.84 144.196 7.568 145.442 7.568C146.273 7.568 147.031 7.712 147.718 8C148.421 8.288 149.028 8.712 149.539 9.272V7.712H152.535ZM145.777 18.128C146.88 18.128 147.79 17.76 148.509 17.024C149.228 16.288 149.587 15.32 149.587 14.12C149.587 12.92 149.228 11.952 148.509 11.216C147.79 10.48 146.88 10.112 145.777 10.112C144.675 10.112 143.765 10.48 143.046 11.216C142.343 11.952 141.991 12.92 141.991 14.12C141.991 15.32 142.343 16.288 143.046 17.024C143.765 17.76 144.675 18.128 145.777 18.128Z'
				fill='black'
			/>
			<path
				d='M160.671 20.696C159.345 20.696 158.307 20.336 157.556 19.616C156.821 18.88 156.454 17.848 156.454 16.52V2.72H159.449V16.304C159.449 17.584 160.048 18.224 161.246 18.224C161.645 18.224 162.005 18.144 162.324 17.984L162.468 20.384C161.909 20.592 161.31 20.696 160.671 20.696Z'
				fill='black'
			/>
			<path
				d='M168.457 20.696C167.419 20.696 166.404 20.56 165.414 20.288C164.423 20.016 163.633 19.672 163.042 19.256H163.151C163.726 19.64 165.454 17.672 166.253 17.912C167.067 18.136 167.866 18.248 168.649 18.248C170.438 18.248 171.332 17.776 171.332 16.832C171.332 16.384 171.101 16.072 170.638 15.896C170.19 15.72 169.463 15.552 168.457 15.392C167.403 15.232 166.54 15.048 165.869 14.84C165.214 14.632 164.639 14.272 164.144 13.76C163.665 13.232 163.425 12.504 163.425 11.576C163.425 10.36 163.928 9.392 164.935 8.672C165.957 7.936 167.331 7.568 169.056 7.568C169.935 7.568 170.813 7.672 171.692 7.88C172.571 8.072 173.289 8.336 173.848 8.672L172.698 10.952C171.612 10.312 170.39 9.992 169.032 9.992C168.154 9.992 167.483 10.128 167.019 10.4C166.572 10.656 166.348 11 166.348 11.432C166.348 11.912 166.588 12.256 167.067 12.464C167.562 12.656 168.321 12.84 169.344 13.016C170.366 13.176 171.205 13.36 171.86 13.568C172.515 13.776 173.074 14.128 173.537 14.624C174.016 15.12 174.256 15.824 174.256 16.736C174.256 17.936 173.737 18.896 172.698 19.616C171.66 20.336 170.246 20.696 168.457 20.696Z'
				fill='black'
			/>
			<rect width='24' height='28' rx='2' fill='black' />
			<path
				d='M12 22C11.3847 22 10.7703 21.7664 10.3006 21.3L9.7166 20.7159C9.49018 20.4903 9.18696 20.3655 8.86534 20.3647H8.04368C6.71559 20.3647 5.63472 19.2838 5.63472 17.9557V17.1333C5.63392 16.8124 5.50911 16.5092 5.2827 16.2812L4.70826 15.7076C3.76739 14.7723 3.76339 13.2434 4.69946 12.3017L5.2835 11.7169C5.50911 11.4905 5.63392 11.1873 5.63472 10.8656V10.0448C5.63472 8.71589 6.71559 7.63502 8.04368 7.63502H8.86614C9.18696 7.63502 9.48938 7.51021 9.7174 7.2822L10.2926 6.70856C11.2279 5.76769 12.756 5.76289 13.6985 6.69976L14.2825 7.2838C14.5097 7.51021 14.8121 7.63502 15.133 7.63502H15.9554C17.2835 7.63502 18.3644 8.71589 18.3644 10.0448V10.8664C18.3652 11.1873 18.49 11.4905 18.7164 11.7185L19.2909 12.2929C19.7461 12.7458 19.9981 13.349 20.0005 13.9931C20.0021 14.6331 19.7557 15.2355 19.3069 15.6908C19.2989 15.6988 19.2917 15.7076 19.2837 15.7148L18.7156 16.2828C18.49 16.5092 18.3652 16.8124 18.3644 17.1341V17.9557C18.3644 19.2838 17.2835 20.3647 15.9554 20.3647H15.133C14.8121 20.3655 14.5089 20.4903 14.2817 20.7167L13.7065 21.2912C13.2376 21.7632 12.6184 22 12 22Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.4351 12.4415C10.3031 12.5735 10.1318 12.6447 9.94223 12.6447C9.76462 12.6447 9.59021 12.5719 9.4502 12.4407C9.31739 12.3087 9.24219 12.1279 9.24219 11.9446C9.24219 11.7702 9.31579 11.591 9.4454 11.4526C9.51661 11.3806 9.60141 11.3262 9.68622 11.2974C9.92783 11.187 10.2487 11.2518 10.4391 11.4518C10.5071 11.5198 10.5591 11.5942 10.5935 11.6718C10.6311 11.755 10.6503 11.8494 10.6503 11.9446C10.6503 12.1351 10.5743 12.3119 10.4351 12.4415ZM14.5543 11.4486C14.2815 11.1766 13.8374 11.1766 13.5646 11.4486L9.45235 15.5609C9.17953 15.8337 9.17953 16.2777 9.45235 16.5513C9.58515 16.6834 9.76037 16.7562 9.94758 16.7562C10.1348 16.7562 10.31 16.6834 10.442 16.5513L14.5543 12.4391C14.8271 12.1654 14.8271 11.7222 14.5543 11.4486ZM14.326 15.4121C14.0667 15.3033 13.7595 15.3625 13.5547 15.5673C13.5123 15.6169 13.4523 15.6937 13.4115 15.7841C13.3683 15.8817 13.3627 15.9865 13.3627 16.057C13.3627 16.1274 13.3683 16.2314 13.4115 16.329C13.4515 16.4186 13.4995 16.4914 13.5627 16.5546C13.7075 16.689 13.8755 16.757 14.0627 16.757C14.2404 16.757 14.4148 16.685 14.558 16.5514C14.6852 16.4242 14.7548 16.2482 14.7548 16.057C14.7548 15.8649 14.6852 15.6897 14.5572 15.5617C14.4868 15.4921 14.402 15.4377 14.326 15.4121Z'
				fill='black'
			/>
		</svg>
	);
};
