import * as React from "react";
import {
	Box,
	Button,
	Flex,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	Text,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Spinner,
	Stack,
	useToast,
	useDisclosure,
	VStack,
	Select,
	Skeleton,
} from "@chakra-ui/react";

const lowerPPPvalues = [
	"0.0-0.1",
	"0.1-0.2",
	"0.2-0.3",
	"0.3-0.4",
	"0.4-0.5",
	"0.5-0.6",
];
const higherPPPvalues = [
	"0.6-0.7",
	"0.7-0.8",
	"0.8-0.9",
	"0.9-1.0",
	"1.0-1.1",
	"1.1-1.2",
	"1.3-1.4",
	"1.7-1.8",
];

const getTotal = (payLoad) => {
	let total = 0;
	payLoad.forEach((element) => {
		total += element.value;
	});
	return total;
};

const getLowerPPPTotal = (payLoad) => {
	let total = 0;
	payLoad.forEach((element) => {
		if (lowerPPPvalues.includes(element.name)) {
			total += element.value;
		}
	});
	return total;
};
const getHigherPPPTotal = (payLoad) => {
	let total = 0;
	payLoad.forEach((element) => {
		if (higherPPPvalues.includes(element.name)) {
			total += element.value;
		} 
	});
	return total;
};

export const getPPPToolTip = (payLoad: any) => {
	const validPayload = payLoad.filter(
		(item: { value: number }) => item.value > 0
	);
	const total = getTotal(payLoad);
	const lowerPPPtotal = getLowerPPPTotal(payLoad);
	const higherPPPtotal = getHigherPPPTotal(payLoad);
	const pppMarkup = validPayload.map((item) => {
		return (
			<Box key={item.name} mb="0.5">
				<Text fontSize="sm" as="span" color="gray.400">
					{item.name} :{" "}
				</Text>
				<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
					${item.value.toFixed(0)} -{" "}
				</Text>
				<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
					{((item.value * 100) / total).toFixed(0)}%
				</Text>
			</Box>
		);
	});
	return (
		<Box>
			<Text fontSize="sm" as="span" color="gray.400">
				Total Revenue : ${total.toFixed(0)}
			</Text>
			<Box>
				<Text fontSize="sm" as="span" color="gray.400">
					PPP Ratio : {((lowerPPPtotal * 100) / total).toFixed(0)}% /{" "}
					{((higherPPPtotal * 100) / total).toFixed(0)}%{" "}
				</Text>
			</Box>
			<Box mb="2">
				<Text fontSize="sm" as="span" color="gray.400">
					PPP Revenue : ${lowerPPPtotal.toFixed(0)} /{" "}${higherPPPtotal.toFixed(0)}
				</Text>
			</Box>
			{pppMarkup}
		</Box>
	);
};
export const getSalesToolTip = (payLoad: any) => {
	const validPayload = payLoad.filter(
		(item: { value: number }) => item.value > 0
	);
	const total = getTotal(payLoad);
	const lowerPPPtotal = getLowerPPPTotal(payLoad);
	const higherPPPtotal = getHigherPPPTotal(payLoad);
	const pppMarkup = validPayload.map((item) => {
		return (
			<Box key={item.name} mb="0.5">
				<Text fontSize="sm" as="span" color="gray.400">
					{item.name} :{" "}
				</Text>
				<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
					{item.value.toFixed(0)} -{" "}
				</Text>
				<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
					{((item.value * 100) / total).toFixed(0)}%
				</Text>
			</Box>
		);
	});
	return (
		<Box>
			<Text fontSize="sm" as="span" color="gray.400">
				Total sales : {total.toFixed(0)}
			</Text>
			<Box>
				<Text fontSize="sm" as="span" color="gray.400">
					PPP Ratio : {((lowerPPPtotal * 100) / total).toFixed(0)}% /{" "}
					{((higherPPPtotal * 100) / total).toFixed(0)}%{" "}
				</Text>
			</Box>
			<Box mb="2">
				<Text fontSize="sm" as="span" color="gray.400">
					PPP Sales : {lowerPPPtotal.toFixed(0)} /{" "}{higherPPPtotal.toFixed(0)}
				</Text>
			</Box>
			{pppMarkup}
		</Box>
	);
};
