import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}
export const WhopColorIcon = ({
  width = 32,
  height = 32,
  fill,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      {...restProps}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="24" fill="#FF6243" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.8017 36.1351C83.9469 29.2796 72.8607 29.2883 66.0166 36.1613L38.2986 63.873C45.8172 70.4821 57.2082 70.2144 64.2929 63.1001L90.962 36.3188L90.8017 36.1351ZM22.5852 48.0344L10 35.5081C16.8921 29.4163 27.4009 29.676 33.9845 36.2874L34.189 36.4927L22.5852 48.0344ZM37.4687 36.2874C44.3215 29.4057 55.4273 29.4057 62.2801 36.2874L62.4883 36.4965L36.7954 62.2976L24.1813 49.6305L37.4687 36.2874Z"
        fill="white"
      />
    </svg>
  );
};
