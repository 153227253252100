import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const PlusBlackIcon = ({
	width = 20,
	height = 20,
	...restProps
}: IconProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			{...restProps}
		>
			<path d="M9.16699 9.16675V4.16675H10.8337V9.16675H15.8337V10.8334H10.8337V15.8334H9.16699V10.8334H4.16699V9.16675H9.16699Z" fill="#2D3748" />

		</svg>
	);
};
