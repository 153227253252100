import * as React from "react";
import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useToast,
} from "@chakra-ui/react";

import styles from "./ForgotPassword.module.scss";

import { Field, Form, Formik } from "formik";

import { forgotPassword, logIn } from "../../services/authServices";
import {
	Link,
	Redirect,
	RouteComponentProps,
	withRouter,
} from "react-router-dom";
import { useState } from "react";
import { Logo } from "../../components/Logo/Logo";
import posthog from "posthog-js";
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}
interface FormValues {
	email: string;
}
function validateEmail(value: string) {
	let error;
	if (!value) {
		error = "Please enter your email.";
	} else if (validateEmailAddress(value)) {
		error = "Please enter a valid email address.";
	}
	return error;
}
const ForgotPassword = () => {
	const toast = useToast();
	const [message, setMessage] = React.useState<string>("");
	const [showAlert, setShowAlert] = React.useState<boolean>(false);

	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);

	return (
		<Flex bg="white" h="100vh" direction="column">
			<Flex
				shrink={0}
				shadow="base"
				w="100%"
				h="60px"
				bg="white"
				pr="10"
				pb="4"
				pt="4"
				pl="10"
				zIndex="2"
				alignItems="center"
			>
				<Logo></Logo>
			</Flex>
			<Flex alignItems="center" justifyContent="center" h="100%">
				<Box
					bg="gray.50"
					p="100px 60px "
					shadow="md"
					width={480}
					maxW="90vw"
					borderRadius="md"
					m="0 auto"
				>
					{showAlert? <Alert fontSize="sm" mt="-32px" mb="8" status="success">
						<AlertIcon />
						{message}
					</Alert>: null}
					
					<Heading
						textAlign="center"
						fontSize="24px"
						as="h3"
						mb="4"
						isTruncated
					>
						Forgot password?
					</Heading>
					<Text mb="12" color="gray.600" textAlign="center" fontSize="14px">
						Enter your email address and we’ll send you a link to reset your
						password.
					</Text>
					<Formik
						initialValues={{ email: "" }}
						onSubmit={async (values: FormValues, actions: any) => {
							try {
								const response = await forgotPassword({
									email: values.email,
								});
								setMessage(response.data?.message)
								setShowAlert(true);
								actions.resetForm();
								actions.setSubmitting(false);
							} catch (error) {
								toast({
									title: `Oops! Something went wrong`,
									status: "error",
									isClosable: true,
								});
								actions.setSubmitting(false);
							}
						}}
					>
						{(props) => (
							<Form>
								<Field type="email" name="email" validate={validateEmail}>
									{({ field, form }: any) => (
										<FormControl
											isInvalid={form.errors.email && form.touched.email}
											mb="6"
										>
											<Input {...field} id="email" placeholder="Email" />
											<FormErrorMessage>{form.errors.email}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									mt={2}
									isFullWidth
									size="lg"
									isLoading={props.isSubmitting}
									isDisabled={
										props.isSubmitting || !props.isValid || !props.dirty
									}
									type="submit"
								>
									Send me instructions
								</Button>
							</Form>
						)}
					</Formik>
					<Text fontSize="sm" mt="4" textAlign="center" color="gray.600">
						<Link to="/login">
							<Text display="inline" color="gray.700">
								Back to Sign in
							</Text>
						</Link>
					</Text>
				</Box>
			</Flex>
		</Flex>
	);
};

export default withRouter(ForgotPassword);
