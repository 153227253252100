import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  List,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  VStack,
  Text,
} from "@chakra-ui/react";

import styles from "./App.module.scss";

import { Link, NavLink, Route, Switch } from "react-router-dom";
import { DashboardIcon } from "../../components/Icons/Dashboard";
import { DealsIcon } from "../../components/Icons/Deals";
import { SettingsIcon } from "../../components/Icons/Settings";
import { Logo } from "../../components/Logo/Logo";
import { DealsList } from "../DealsList/DealsList";
import { Dashboard } from "../Dashboard/Dashboard";
import { MarketPlaceDealsList } from "../MarketPlaceDealsList/MarketPlaceDealsList";
import { Settings } from "../Settings/Settings";
import { getUserProfile } from "../../services/userServices";
import { CurrentUser, User } from "../../services/appConfig";
import { HomeIcon } from "../../components/Icons/Home";
import { LocationIcon } from "../../components/Icons/Location";
import { CelebratoryIcon } from "../../components/Icons/celebratory";
import { FlashDealsIcon } from "../../components/Icons/FlashDeals";
import { IntegrationsIcon } from "../../components/Icons/integrations";
import { APIIcon } from "../../components/Icons/API";
import { DocsIcon } from "../../components/Icons/Docs";
import { UsageBox } from "../../components/UsageBox/UsageBox";
import posthog from "posthog-js";
import { allowdQuota } from "../SettingsUsagePage/SettingsUsagePage";
import { fetchVisitors } from "../../services/dashboardServices";
import { useEffect, useCallback, useState } from "react";
interface AppProps {
  onLoginOut: () => void;
  allowdQuota: number;
  usedQuota: number;
  PlanName: string;
}
  export const App = (props: AppProps) => {
    const [userData, setUserData] = React.useState<User | null>(null);
    const user = CurrentUser.get();
    const [usedQuota, setUsedQuota] = useState<number>(0);
    const [visitorsData, setVisitorsData] = useState<string>("");
    const getVisitorsData = useCallback(async () => {
      const thisMonthResponse = await fetchVisitors("thismonth", "");
      const visotorResponse = await fetchVisitors("thisyear", "");
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");

      const formattedDate = `${year}-${month}-01`;
      let monthlyDatedVisitorsData =
        thisMonthResponse.data.datedVisitorsData.reduce(
          (acc, curr) => {
            acc.visitors += curr.visitors;
            acc.uniqueVisitors += curr.uniqueVisitors;
            acc.overages += curr.overages;
            return acc;
          },
          { date: formattedDate, visitors: 0, uniqueVisitors: 0, overages: 0 }
        );
      let yearlyData = visotorResponse.data.datedVisitorsData;
      let currentMonthObj = yearlyData.find(
        (obj) => obj.date === formattedDate
      );
      if (currentMonthObj) {
        currentMonthObj.visitors = monthlyDatedVisitorsData.visitors;
        currentMonthObj.uniqueVisitors =
          monthlyDatedVisitorsData.uniqueVisitors;
        currentMonthObj.overages = monthlyDatedVisitorsData.overages;
      }

      let usedQuota = monthlyDatedVisitorsData.visitors;
      setUsedQuota(usedQuota || 0);
      setVisitorsData(monthlyDatedVisitorsData);
    }, []);
    useEffect(() => {
      getVisitorsData();
    }, [getVisitorsData]);

    React.useEffect(() => {
      async function fetchData() {
        try {
          const response = await getUserProfile();
          CurrentUser.set(response.data);
          setUserData(response.data);
        } catch (error) {
          console.log("Unable to fetch deals", error);
        } finally {
        }
      }
      fetchData();
    }, []);
    return (
      <VStack spacing="0" w="100%" h="100vh">
        <Flex
          shrink={0}
          shadow="base"
          w="100%"
          h="65px"
          bg="white"
          justifyContent="space-between"
          pr="10"
          pb="4"
          pt="4"
          pl="10"
          zIndex="2"
          alignItems="center"
        >
          <Logo></Logo>
          <Box>
            <Menu>
              <MenuButton>
                <Avatar size="sm" bg="teal.500" />
              </MenuButton>
              <MenuList minW="180px">
                <MenuItem fontSize="small">
                  <Avatar mr={2} size="sm" bg="teal.500" /> {userData?.email}
                </MenuItem>
                <MenuDivider />
                <Link to="/app/settings/subscription/">
                  <MenuItem fontSize="small">Subscription</MenuItem>
                </Link>
                <Link to="/app/settings/subscription/">
                  <MenuItem fontSize="small">Settings</MenuItem>
                </Link>
                <MenuDivider />
                <a href="https://www.paritydeals.com/docs" target="_blank">
                  <MenuItem fontSize="small">Docs</MenuItem>
                </a>
                <MenuDivider />
                <MenuItem onClick={props.onLoginOut} fontSize="small">
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        <Flex flex={1} h="calc(100% - 100px)" w="100%">
          <HStack spacing="0" w="100%" justify="start" paddingLeft="270px">
            <Box
              h="100%"
              w="250px"
              shadow="base"
              bg="white"
              flexShrink={0}
              p="16px"
              pb={12}
              paddingRight="0"
              display="flex"
              height="100%"
              flexDirection="column"
              position="fixed"
              left="0"
              top="0"
              paddingTop="70px"
              marginTop="30px"
              justifyContent="space-between"
            >
              <List spacing={7} overflow="auto" pl="24px">
                <ListItem display="flex" alignItems="center" fontSize="sm">
                  <NavLink activeClassName={styles.activeNav} to="/app/deals">
                    <Flex alignItems="center">
                      <HomeIcon />
                      <Text ml="2" fontSize="14" className={styles.navLink}>
                        Home
                      </Text>
                    </Flex>
                  </NavLink>
                </ListItem>
                <ListItem display="flex" alignItems="center" fontSize="sm">
                  <NavLink
                    activeClassName={styles.activeNav}
                    to="/app/dashboard"
                  >
                    <Flex alignItems="center">
                      <DashboardIcon />
                      <Text ml="2"  fontSize="14" className={styles.navLink}>
                        Dashboard
                      </Text>
                    </Flex>
                  </NavLink>
                </ListItem>
                {/* <ListItem display="flex" alignItems="center" fontSize="sm">
                <NavLink activeClassName={styles.activeNav} to="/app/deals"> */}
                {/* <ListIcon
                    fontSize="xl"
                    mr="3"
                    as={DealsIcon}
                    color="green.500"
                  /> */}
                {/* </NavLink>
              </ListItem> */}
                {/* <Text color="grey.500">Pricing and features</Text>
              <ListItem display="Flex" alignItems="center" fontSize="sm">
                <NavLink activeClassName={styles.activeNav} to="/app/deals">
                  <ListIcon
                    fontSize="xl"
                    mr="3"
                    as={LocationIcon}
                    color="grey.700"
                  />
                  Location-wise pricing
                </NavLink>
              </ListItem>
              <ListItem display="Flex" alignItems="center" fontSize="sm">
                <NavLink activeClassName={styles.activeNav} to="/app/deals">
                  <ListIcon
                    fontSize="xl"
                    mr="3"
                    as={CelebratoryIcon}
                    color="grey.700"
                  />
                  Celebratory promotions
                </NavLink>
              </ListItem>
              <ListItem display="Flex" alignItems="center" fontSize="sm">
                <NavLink activeClassName={styles.activeNav} to="/app/deals">
                  <ListIcon
                    fontSize="xl"
                    mr="3"
                    as={FlashDealsIcon}
                    color="grey.700"
                  />
                  Flash deals promotions
                </NavLink>
              </ListItem> */}
                {/* <Text color="gray.500" fontWeight="400" fontSize="14"
              >Configuration</Text> */}
                <ListItem display="Flex" alignItems="center" fontSize="sm">
                  <NavLink
                    activeClassName={styles.activeNav}
                    to="/app/settings/integrations"
                  >
                    <Flex alignItems="center">
                      <IntegrationsIcon />
                      <Text ml="2"  fontSize="14" className={styles.navLink}>
                        Integrations
                      </Text>
                    </Flex>
                  </NavLink>
                </ListItem>
                {/* <ListItem display="Flex" alignItems="center" fontSize="sm">
                <NavLink activeClassName={styles.activeNav} to="/app/settings/api-key">
                  <ListIcon
                    fontSize="14"
                    mr="3"
                    as={APIIcon}
                    color="gray.700"
                    fontWeight="500"
                  />
                  API
                </NavLink>
              </ListItem> */}
                {/* <Text color="gray.500">Resources</Text> */}
                <ListItem display="Flex" alignItems="center" fontSize="sm">
                  <a href="https://www.paritydeals.com/docs" target="_blank">
                    <Flex alignItems="center">
                      <DocsIcon />
                      <Text
                        ml="2"
                        fontSize="14"
                        fontWeight="400"
                        className={styles.navLink}
                      >
                        Docs
                      </Text>
                    </Flex>
                  </a>
                </ListItem>
                {/* <ListItem display="flex" alignItems="center" fontSize="sm">
	const [userData, setUserData] = React.useState<User | null>(null);
	React.useEffect(() => {
		async function fetchData() {
			try {
				const response = await getUserProfile();
				CurrentUser.set(response.data);
				setUserData(response.data);
				posthog.identify(
					response.data.email,
					{ email: response.data.email }
				);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, []);
	return (
		<VStack spacing="0" w="100%" h="100vh">
			<Flex
				shrink={0}
				shadow="base"
				w="100%"
				h="60px"
				bg="white"
				justifyContent="space-between"
				pr="10"
				pb="4"
				pt="4"
				pl="10"
				zIndex="2"
				alignItems="center"
			>
				<Logo></Logo>
				<Box>
					<Menu>
						<MenuButton>
							<Avatar size="sm" bg="teal.500" />
						</MenuButton>
						<MenuList minW="180px">
							<MenuItem fontSize="small">
								<Avatar mr={2} size="sm" bg="teal.500" /> {userData?.email}
							</MenuItem>
							<MenuDivider />
							<Link to="/app/settings/subscription/">
								<MenuItem fontSize="small">Subscription</MenuItem>
							</Link>
							<Link to="/app/settings/subscription/">
								<MenuItem fontSize="small">Settings</MenuItem>
							</Link>
							<MenuDivider />
							<a href="https://www.paritydeals.com/docs" target="_blank">
								<MenuItem fontSize="small">Docs</MenuItem>
							</a>
							<MenuDivider />
							<MenuItem onClick={props.onLoginOut} fontSize="small">
								Log Out
							</MenuItem>
						</MenuList>
					</Menu>
				</Box>
			</Flex>
			<Flex flex={1} h="calc(100% - 100px)" w="100%">
				<HStack spacing="0" w="100%" justify="start">
					<Box
						h="100%"
						w="260px"
						shadow="base"
						bg="white"
						flexShrink={0}
						p="10"
						pt="10"
					>
						<List spacing={7}>
							<ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/dashboard">
									<ListIcon
										fontSize="xl"
										mr="3"
										as={DashboardIcon}
										color="green.500"
									/>
									Dashboard
								</NavLink>
							</ListItem>
							<ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/deals">
									<ListIcon
										fontSize="xl"
										mr="3"
										as={DealsIcon}
										color="green.500"
									/>
									Deals
								</NavLink>
							</ListItem>
							{/* <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink
									activeClassName={styles.activeNav}
									to="/app/marketplace-deals"
								>
									<ListIcon
										fontSize="xl"
										mr="3"
										as={SettingsIcon}
										color="green.500"
									/>
									Marketplace Deals
								</NavLink>
							</ListItem> */}
              </List>
              {visitorsData && (
                <UsageBox
                  islimitExceed
                  planName={user.plan}
                  allowdQuota={allowdQuota[user.plan]}
                  usedQuota={usedQuota}
                />
              )}
            </Box>

            {userData ? (
              <Switch>
                <Route path="/app/deals">
                  <DealsList />
                </Route>
                <Route path="/app/marketplace-deals">
                  <MarketPlaceDealsList />
                </Route>
                <Route path="/app/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/app/settings">
                  <Settings />
                </Route>
                <DealsList />
              </Switch>
            ) : (
              <Flex className={styles.appLoading}>
                <Spinner mr={2} /> Loading App...
              </Flex>
            )}
          </HStack>
        </Flex>
      </VStack>
    );
  };
