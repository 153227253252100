import * as React from "react";
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Textarea,
} from "@chakra-ui/react";

import { Form, Field, Formik } from "formik";
import { LemonsqueezyAuthData } from "../../types/types";
const initialValues: LemonsqueezyAuthData = {
	apiKey: '',
};

interface LemonSqueezyAuthFormProps {
	onNextClick: (values: LemonsqueezyAuthData) => void;
	onBackClick: () => void;
	isSubmitting?: boolean;
	initialRef: React.RefObject<HTMLInputElement>;
}


export const LemonSqueezyAuthForm = ({
	onNextClick,
	onBackClick,
	isSubmitting = false,
	initialRef
}: LemonSqueezyAuthFormProps) => {
	return (

		<Formik
			initialValues={initialValues}
			onSubmit={async (values: any, actions) => { }}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isValid,
			}) => (
				<>
					<Form>
						<Field type="text" name="apiKey">
							{({ field, form }: any) => (
								<FormControl
									isRequired
									isInvalid={
										form.errors.apiKey && form.touched.apiKey
									}
								>
									<FormLabel color="gray.800" htmlFor="apiKey">
										API Key
									</FormLabel>
									<Textarea
										{...field}
										ref={initialRef}
										id="apiKey"
									/>
									<FormErrorMessage>
										{form.errors.apiKey}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					</Form>
					<Box
						w="100%"
						display="flex"
						pt="6"
						mt="6"
						bg="white"
						borderTop="1px solid"
						borderColor="gray.100"
						justifyContent="space-between"
					>
						<HStack></HStack>
						<HStack>
							<Button onClick={onBackClick} variant="outline">
								Cancel
							</Button>
							<Button
								bg="blackAlpha.900"
								_hover={{ bg: "blackAlpha.900" }}
								_active={{ bg: "blackAlpha.900" }}
								colorScheme="blackAlpha"
								variant="solid"
								isLoading={isSubmitting}
								isDisabled={isSubmitting || !values.apiKey}
								onClick={() => {
									onNextClick(values);
								}}
							>
								Authenticate
							</Button>
						</HStack>
					</Box>
				</>
			)}
		</Formik>




	);
};
