import * as React from "react";
import {
	Box,
	Button,
	Heading,
	Text,
	ListItem,
	List,
	ListIcon,
	HStack,
	Flex,
} from "@chakra-ui/react";

import { CurrentUser, User } from "../../services/appConfig";
import { CheckCircleBlueIcon } from "../../components/Icons/CheckCircleBlue";
import styles from "./SettingsSubscriptionPG.module.scss";

export const SettingsSubscriptionPG = () => {

	const [user, setUser] = React.useState<User>(CurrentUser.get());
	React.useEffect(() => {
		async function fetchData() {
			try {
				const userData = CurrentUser.get();
				setUser(userData);
			} catch (error) {
				console.log("Unable to fetch user ID", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const getFreeComponent = React.useCallback(() => {
		return (
			<Box>
				<Flex direction="column" alignItems="center" justifyContent="center" mb="12">
					<Heading mb="1" fontSize="large">
						You are currently using the Free plan
					</Heading>
					<Text mb="6" color="gray.500" fontSize="medium">
						Upgrade to paid plans to supercharge your sales
					</Text>
				</Flex>
				<Box>
					<HStack spacing="4" alignItems="flex-end">
					<Box className={styles.pricingBlock}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								 $49
								</Text>
							</HStack>
							
							<Text className={styles.pricingPlan}>Plan A</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>10k</b> Pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>5</b> products
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<a href={"https://neravat.gumroad.com/l/pxgge?wanted=true&user_id=" + user.id}>
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
									>
										Subscribe Now
									</Button>
								</a>
							</Box>
						</Box>
						<Box className={styles.pricingBlock}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								 $97
								</Text>
							</HStack>
							
							<Text className={styles.pricingPlan}>Plan B</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>30k</b> Pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>30</b> products
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<a href={"https://neravat.gumroad.com/l/bnilh?wanted=true&user_id=" + user.id}>
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
									>
										Subscribe Now
									</Button>
								</a>
							</Box>
						</Box>
						<Box className={styles.pricingBlock}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								 $197
								</Text>
							</HStack>
							
							<Text className={styles.pricingPlan}>Plan C</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>100k</b> Pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>50</b> products
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<a href={"https://neravat.gumroad.com/l/rghhq?wanted=true&user_id=" + user.id}>
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
									>
										Subscribe Now
									</Button>
								</a>
							</Box>
						</Box>
						<Box className={styles.pricingBlock}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								 $397
								</Text>
							</HStack>
							
							<Text className={styles.pricingPlan}>Plan D</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>Unlimited</b> Pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>Unlimited</b> products
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<a href={"https://neravat.gumroad.com/l/dcvxi?wanted=true&user_id=" + user.id}>
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
									>
										Subscribe Now
									</Button>
								</a>
							</Box>
						</Box>
						
					</HStack>
				</Box>
			</Box>
		);
	}, []);

	return (
		<Box minW="1088px" p="8" backgroundColor="white" boxShadow="sm" borderRadius="md">
			{getFreeComponent()}
		</Box>
	);
};
