import * as React from "react";
import {
	Box,
	Heading,
	HStack,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	FormHelperText,
	Textarea,
	Switch,
	Radio,
	RadioGroup,
	Flex,
} from "@chakra-ui/react";

import { Form, Field } from "formik";

interface CreateDealsCustomizeFormProps {
	showCountryMessage?: boolean;
	showHolidayMessage?: boolean;
	showTimeMessage?: boolean;
}

export const DealsCustomizeForm = ({
	showCountryMessage = true,
	showHolidayMessage = true,
	showTimeMessage = true,
}: CreateDealsCustomizeFormProps) => {
	return (
		<Form>
			<HStack spacing="8" alignItems="start">
				<Box w="50%">
					<Flex>
						<Heading mr={3} flexShrink={0} mt="0" mb="6" size="md" as="h3">
							Messages
						</Heading>
					</Flex>
					{showCountryMessage ? (
						<Field type="text" name="countryMessage">
							{({ field, form }: any) => (
								<FormControl
									mb="6"
									isInvalid={
										form.errors.countryMessage && form.touched.countryMessage
									}
								>
									<FormLabel htmlFor="countryMessage">
										Message for location based banner
									</FormLabel>
									<Textarea
										{...field}
										rows="5"
										placeholder="Hello {country_flag} Hey! It looks like you are from {country}. We support Parity Purchasing Power, so if you need it, use code “{coupon_code}” to get {discount_percentage}% off your subscription at checkout."
										size="sm"
									/>
									<FormHelperText>
										Data Parameters: &#123;country_flag&#125;,
										&#123;country&#125;, &#123;coupon_code&#125;,
										&#123;discount_percentage&#125;
									</FormHelperText>
									<FormHelperText>
										You can also add custom styles using the &lt;style&gt; tag
									</FormHelperText>
									<FormErrorMessage>
										{form.errors.countryMessage}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					) : null}
					{showHolidayMessage ? (
						<Field type="text" name="holidayMessage">
							{({ field, form }: any) => (
								<FormControl
									mb="6"
									isInvalid={
										form.errors.holidayMessage && form.touched.holidayMessage
									}
								>
									<FormLabel htmlFor="holidayMessage">
										Message for holiday based banner
									</FormLabel>
									<Textarea
										{...field}
										placeholder="Get {discount_percentage}% off during {holiday} sale! Use code “{coupon_code}” at checkout."
										size="sm"
									/>
									<FormHelperText>
										Data Parameters: &#123;discount_percentage&#125;,
										&#123;holiday&#125;, &#123;coupon_code Code&#125;
									</FormHelperText>
									<FormErrorMessage>
										{form.errors.holidayMessage}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					) : null}

					{showTimeMessage ? (
						<Field type="text" name="timeMessage">
							{({ field, form }: any) => (
								<FormControl
									mb="6"
									isInvalid={
										form.errors.timeMessage && form.touched.timeMessage
									}
								>
									<FormLabel htmlFor="timeMessage">
										Message for time based discount banner
									</FormLabel>
									<Textarea
										{...field}
										placeholder="Limited time offer! Use coupon code <b>“{coupon_code}”</b> to get <b>{discount_percentage}%</b> off at checkout"
										size="sm"
									/>
									<FormHelperText>
										Data Parameters: &#123;discount_percentage&#125;,
										&#123;coupon_code Code&#125;
									</FormHelperText>
									<FormErrorMessage>{form.errors.timeMessage}</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					) : null}
				</Box>
				<Box w="50%">
					<Flex>
						<Heading mr={3} flexShrink={0} mt="0" mb="6" size="md" as="h3">
							Banner styling{" "}
						</Heading>
						<Field type="text" name="unStyled">
							{({ field, form }: any) => (
								<FormControl mb="6">
									<Switch
										{...field}
										size="sm"
										defaultChecked={!!form.values.unStyled}
										id="enable styling"
									/>

									<FormErrorMessage>{form.errors.unStyled}</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					</Flex>
					<HStack spacing="5" alignItems="start">
						<Box w="50%">
							<Field type="text" name="backgroundColor">
								{({ field, form }: any) => (
									<FormControl
										mb="6"
										isInvalid={
											form.errors.backgroundColor &&
											form.touched.backgroundColor
										}
									>
										<FormLabel color="gray.800" htmlFor="backgroundColor">
											Background color
										</FormLabel>
										<Input
											{...field}
											id="backgroundColor"
											isDisabled={!form.values.unStyled}
											placeholder="#11141C"
										/>
										<FormErrorMessage>
											{form.errors.backgroundColor}
										</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							{/* <Field type='text' name='backgroundTransparency'>
						{({ field, form }: any) => (
							<FormControl
								mb='6'
								isInvalid={
									form.errors.backgroundTransparency &&
									form.touched.backgroundTransparency
								}
							>
								<FormLabel
									color='gray.800'
									htmlFor='backgroundTransparency'
								>
									Background transparency
								</FormLabel>
								<Input
									isDisabled={!form.values.unStyled}
									{...field}
									id='backgroundTransparency'
									placeholder='0'
								/>

								<FormErrorMessage>
									{form.errors.backgroundTransparency}
								</FormErrorMessage>
							</FormControl>
						)}
					</Field> */}
							<Field type="text" name="borderRadius">
								{({ field, form }: any) => (
									<FormControl
										mb="6"
										isInvalid={
											form.errors.borderRadius && form.touched.borderRadius
										}
									>
										<FormLabel color="gray.800" htmlFor="borderRadius">
											Border radius
										</FormLabel>
										<Input
											{...field}
											isDisabled={!form.values.unStyled}
											id="borderRadius"
											placeholder="0px"
										/>
										<FormErrorMessage>
											{form.errors.borderRadius}
										</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							<Field type="text" name="addCloseIcon">
								{({ field, form }: any) => (
									<FormControl mb="6">
										<FormLabel color="gray.800" htmlFor="addCloseIcon">
											Add close icon
										</FormLabel>
										<Switch
											{...field}
											size="sm"
											isDisabled={!form.values.unStyled}
											defaultChecked={form.values.addCloseIcon}
											id="add-close-icon"
										/>

										<FormErrorMessage>
											{form.errors.addCloseIcon}
										</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							<Field type="text" name="container">
								{({ field, form }: any) => (
									<FormControl
										mb="6"
										isInvalid={
											form.errors.container && form.touched.container
										}
									>
										<FormLabel color="gray.800" htmlFor="container">
											Banner container
										</FormLabel>
										<Input
											{...field}
											isDisabled={!form.values.unStyled}
											id="container"
											placeholder="body"
										/>
										<FormHelperText>
											Enter the HTML container selector where you want to place the banner. Ex: #container, .container, body
										</FormHelperText>
										<FormErrorMessage>
											{form.errors.container}
										</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						</Box>
						<Box w="50%">
							<Field type="text" name="fontColor">
								{({ field, form }: any) => (
									<FormControl
										mb="6"
										isInvalid={form.errors.fontColor && form.touched.fontColor}
									>
										<FormLabel color="gray.800" htmlFor="fontColor">
											Font color
										</FormLabel>
										<Input
											{...field}
											id="fontColor"
											isDisabled={!form.values.unStyled}
											placeholder="#ffffff"
										/>

										<FormErrorMessage>{form.errors.fontColor}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							{/* <Field type='text' name='highlightFontColor'>
						{({ field, form }: any) => (
							<FormControl
								mb='6'
								isInvalid={
									form.errors.highlightFontColor &&
									form.touched.highlightFontColor
								}
							>
								<FormLabel
									color='gray.800'
									htmlFor='highlightFontColor'
								>
									Highlight font color
								</FormLabel>
								<Input
									{...field}
									isDisabled={!form.values.unStyled}
									id='highlightFontColor'
									placeholder='#DD5C64'
								/>
								<FormErrorMessage>
									{form.errors.highlightFontColor}
								</FormErrorMessage>
							</FormControl>
						)}
					</Field> */}
							<Field type="text" name="fontSize">
								{({ field, form }: any) => (
									<FormControl
										mb="6"
										isInvalid={
											form.errors.fontSize && form.touched.fontSize
										}
									>
										<FormLabel color="gray.800" htmlFor="fontSize">
											Font size
										</FormLabel>
										<Input
											{...field}
											isDisabled={!form.values.unStyled}
											id="fontSize"
											placeholder="14px"
										/>
										<FormErrorMessage>
											{form.errors.fontSize}
										</FormErrorMessage>
									</FormControl>
								)}
							</Field>

							<Field type="text" name="placement">
								{({ field, form }: any) => (
									<FormControl mb="6">
										<FormLabel color="gray.800" htmlFor="placement">
											Banner placement
										</FormLabel>
										<RadioGroup
											isDisabled={!form.values.unStyled}
											{...field}
											defaultValue="top"
										>
											<HStack height="38px" spacing="24px">
												<Radio {...field} value="top">
													Top
												</Radio>
												<Radio {...field} value="bottom">
													Bottom
												</Radio>
											</HStack>
										</RadioGroup>
									</FormControl>
								)}
							</Field>
						</Box>
					</HStack>
				</Box>
			</HStack>
		</Form>
	);
};
