// theme.ts

// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// 2. Add your color mode config
const config: ThemeConfig = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};

const theme = extendTheme({
	config,
	colors: {
		brand: {
			100: '#f7fafc',
			900: '#rgba(0, 0, 0, 0.92)',
		},
	},
	styles: {
		global: (props) => ({
			body: {
				color: mode('gray.800', 'gray.400')(props),
				background: mode('gray.50', 'gray.900')(props),
			},
		}),
	},
});

export default theme;
