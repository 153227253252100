import * as React from "react";
import {
	Box,
	Heading,
	HStack,
	Text,
	Button,
	VStack,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Flex,
	Select,
	SimpleGrid,
	InputGroup,
	InputRightAddon,
} from "@chakra-ui/react";

import { HolidayCategoryDiscount } from "../../services/commonServices";
import { Field, Form, Formik } from "formik";
import { DealPlatform } from "../../types/types";

export interface DealTimeFormValues {
	startDate: string;
	id: string;
	discountPercentage: number;
	startingDiscount: number;
	discountDuration: "hour" | "day";
	days: number;
}

const initialValues: DealTimeFormValues = {
	startDate: new Date().toISOString().substr(0, 10),
	discountPercentage: 5,
	startingDiscount: 90,
	discountDuration: "day",
	days: 1,
};

interface DealsTimeCouponSetupProps {
	onNextClick: (values: DealTimeFormValues) => void;
	onBackClick: () => void;
	isSubmitting?: boolean;
	primaryButtonLabel?: string;
	platform: DealPlatform;
	existingDiscounts?: DealTimeFormValues[];
}
export const DealsTimeCouponSetup = ({
	onNextClick,
	onBackClick,
	platform,
	isSubmitting = false,
	primaryButtonLabel = "Next",
	existingDiscounts
}: DealsTimeCouponSetupProps) => {

	const getPageHeader = React.useCallback(() => {
		return <>
				Run time-based flash deals on any specific day. Just specify the values, we'll create coupons automatically on your <span style={{textTransform: 'capitalize'}}>{platform}</span> account.
				<br />
			</>
	}, [platform])
	return (
		<Flex h="100%" direction="column">
			<Formik
				initialValues={existingDiscounts && existingDiscounts.length > 0 ? existingDiscounts[0] : initialValues}
				onSubmit={async (values: any, actions) => { }}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isValid,
				}) => (
					<>
						<VStack alignItems="start" w="100%" mb="10">
							<Heading as="h2" size="md" mb="2" isTruncated>
								Time based deals
							</Heading>
							<Text color="gray.500">
								{getPageHeader()}
							</Text>
						</VStack>
						<VStack
							alignItems="start"
							overflowY="auto"
							spacing="4"
							mb="86px"
							p="2px"
						>
							<Form style={{ width: "100%" }}>
								<VStack w="100%">
									<Box
										shadow="xs"
										p="4"
										borderRadius="lg"
										spacing="4"
										align="start"
										w="100%"
										justify="space-between"
										alignItems="center"
									>
										<SimpleGrid columns={[1, 1, 1, 5]} spacing="4">
											<Field type="text" name="startingDiscount">
												{({ field, form }: any) => (
													<FormControl
														mb="6"
														isInvalid={
															form.errors.startingDiscount &&
															form.touched.startingDiscount
														}
													>
														<FormLabel
															fontSize="sm"
															color="gray.700"
															htmlFor="startingDiscount"
														>
															Discount starts at
														</FormLabel>

														<InputGroup>
															<Input
																{...field}
																id="startingDiscount"
																placeholder="90"
															/>
															<InputRightAddon children="%" />
														</InputGroup>
														<FormErrorMessage>
															{form.errors.startingDiscount}
														</FormErrorMessage>
													</FormControl>
												)}
											</Field>
											<Field type="text" name="discountPercentage">
												{({ field, form }: any) => (
													<FormControl
														mb="6"
														isInvalid={
															form.errors.discountPercentage &&
															form.touched.discountPercentage
														}
													>
														<FormLabel
															fontSize="sm"
															color="gray.700"
															htmlFor="discountPercentage"
														>
															Drops
														</FormLabel>
														<InputGroup>
															<Input
																{...field}
																id="discountPercentage"
																placeholder="5"
															/>
															<InputRightAddon children="%" />
														</InputGroup>

														<FormErrorMessage>
															{form.errors.discountPercentage}
														</FormErrorMessage>
													</FormControl>
												)}
											</Field>
											<Field type="text" name="discountDuration">
												{({ field, form }: any) => (
													<FormControl
														mb="6"
														isInvalid={
															form.errors.discountDuration &&
															form.touched.discountDuration
														}
													>
														<FormLabel
															fontSize="sm"
															color="gray.700"
															htmlFor="discountDuration"
														>
															Every
														</FormLabel>
														<Select {...field}>
															<option value="hour">Hour</option>
															<option value="day">Day</option>
														</Select>
														<FormErrorMessage>
															{form.errors.discountDuration}
														</FormErrorMessage>
													</FormControl>
												)}
											</Field>
											<Field type="text" name="startDate">
												{({ field, form }: any) => (
													<FormControl
														mb="6"
														isInvalid={
															form.errors.startDate && form.touched.startDate
														}
													>
														<FormLabel
															fontSize="sm"
															color="gray.700"
															htmlFor="startDate"
														>
															From
														</FormLabel>
														<Input
															type="date"
															{...field}
															id="startDate"
															placeholder="#11141C"
														/>
														<FormErrorMessage>
															{form.errors.startDate}
														</FormErrorMessage>
													</FormControl>
												)}
											</Field>
											<Field type="text" name="days">
												{({ field, form }: any) => (
													<FormControl
														mb="6"
														isInvalid={
															form.errors.days && form.touched.days
														}
													>
														<FormLabel
															fontSize="sm"
															color="gray.700"
															htmlFor="days"
														>
															For
														</FormLabel>
														<InputGroup>
															<Input
																type="number"
																{...field}
																id="days"
																placeholder="1"
															/>
															<InputRightAddon children="days" />
														</InputGroup>
														<FormErrorMessage>
															{form.errors.days}
														</FormErrorMessage>
													</FormControl>
												)}
											</Field>
										</SimpleGrid>
									</Box>
								</VStack>
							</Form>
						</VStack>
						<Box
							pos="absolute"
							w="100%"
							zIndex={2}
							display="flex"
							p="6"
							bottom="0"
							left="0"
							right="0"
							bg="white"
							borderTop="1px solid"
							borderColor="gray.100"
							justifyContent="space-between"
						>
							<HStack></HStack>
							<HStack>
								<Button onClick={onBackClick} variant="outline">
									Go back
								</Button>
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={isSubmitting}
									isDisabled={isSubmitting}
									onClick={() => {
										onNextClick(values);
									}}
								>
									{primaryButtonLabel}
								</Button>
							</HStack>
						</Box>
					</>
				)}
			</Formik>
		</Flex>
	);
};
