import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../constants/app';
import {
	CountryDiscount,
	DealCategory,
	HolidayDiscount,
	parityRange,
} from '../types/deals.type';

export interface Country {
	country: string;
	countryName: string;
}

export interface CountryCategory {
	range: parityRange;
	countries: Country[];
}
export interface Holiday {
	endDate?: string;
	holiday: string;
	startDate?: string;
}

export interface PricingDiscounts {
	country: string;
	countryFlag: string;
	couponCode: string;
	message: string;
	discountPercentage: string;
}

export interface CustomCountryDiscount {
	"country": string;
	"couponCode": string;
	"discountPercentage": string;
}


export interface CountryCategoryDiscount
	extends CountryCategory,
		CountryDiscount {}

export interface HolidayCategoryDiscount extends Holiday, HolidayDiscount {}

export async function getCountryCategories(): Promise<
	AxiosResponse<CountryCategory[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: 'deals/category/',
	});
}
export async function getHolidays(): Promise<AxiosResponse<Holiday[]>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: 'deals/holidays/',
	});
}

export async function getDealCategories(): Promise<AxiosResponse<DealCategory[]>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: 'marketplace/category/',
	});
}
// Need to remove the identifier
export async function getPricingDiscounts(url:string): Promise<AxiosResponse<PricingDiscounts>> {
	return await axios({
		method: 'get',
		url: `https://api.paritydeals.com/api/v1/deals/discount/?url=${url}&pd_identifier=0f595eb8-1d7a-46c6-a43c-2692c832893a`,
	});
}
