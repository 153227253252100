import * as React from "react";
import {
	Box,
	Flex,
	Heading,
	VStack,
	Text,
	Divider,
	Tooltip,
	Badge,
	Spinner,
	useToast
} from "@chakra-ui/react";
import { DealSetting, IntegratedPlatform } from '../../types/deals.type';
import { RefreshCoupon } from "../RefreshCoupon/RefreshCoupon";
import { VpnProtection } from "../VpnProtection/VpnProtection";
import { TorBrowserProtection } from "../TorBrowserProtection/TorBrowserProtection";
import { ProxyProtection } from "../ProxyProtection/ProxyProtection";
import { UpgradeNowAlert } from "../UpgradeNowAlert/UpgradeNowAlert";
import { CurrentUser, User } from "../../services/appConfig";
import { useState } from "react";
import { fetchDealSetting } from "../../services/dealServices";
import { getUserProfile } from "../../services/userServices";
interface ProductSettingsProps {
	id: string;
	integratedPlatform: IntegratedPlatform[];
}
export const ProductSettings = ({ id, integratedPlatform }: ProductSettingsProps) => {	
	const [user,setUser] = React.useState<User>(CurrentUser.get());
	const toast = useToast();
	const [isLoading, setIsLoading] = useState(true);
	const [dealSettingData, setDealSettingData] = useState<DealSetting>({} as DealSetting);

	React.useEffect(() => {
    async function fetchUserData() {
      try {
        if (!user.id) {
          const response = await getUserProfile();
          CurrentUser.set(response.data);
          setUser(response.data);
        }
      } catch (error) {
        console.log("Unable to fetch user profile", error);
      }
    }

    fetchUserData();
  }, [user.id]);
	
	React.useEffect(() => {

		async function fetchData() {
			setIsLoading(true);
			try {
				const response = await fetchDealSetting(id);
				setDealSettingData(response.data);
			} catch (error) {
				toast({
					title: `Error fetching security settings data`,
					status: "error",
					isClosable: true,
				});
			} finally {
					setIsLoading(false);
			}
		}

		fetchData();
	}, [id, toast]);
	  
	const getRefreshCouponsMarkup = React.useCallback(() => {
		if (integratedPlatform && integratedPlatform.length) {
			return ( <>
				<Flex width="100%" direction="column">
					<Flex>
						<Heading as='h6' size='20px' fontWeight="500">Refresh Coupon</Heading>
						{!user.isActiveSubscription && (
							<Tooltip label='Automatic coupon refresh is only available to Pro users'>
								<Box marginLeft={'2'} display='flex' alignItems='center'>
									<Badge variant='outline' colorScheme='yellow'>PRO</Badge>
								</Box>
							</Tooltip>
						)}
					</Flex>
					<Box display="flex" alignItems="center" mb="6">
						<Text width="450px" mr="4" mt="4" fontSize="14px" color="gray.500">
							We automatically refresh coupons at regular intervals. However, if you ever need to refresh coupons instantly,
							simply click the 'Refresh Coupon' button manually for an instant refresh.
						</Text>
						<RefreshCoupon isEnabled={user.isActiveSubscription} id={id} />
					</Box>
				</Flex>
				<Divider mt="8" />
			</> );
		}
		return null;
	}, [id, integratedPlatform, user.isActiveSubscription])

	if (isLoading || user === null ) {
		return (
			<Flex h="100%" alignItems="center" justifyContent="center">
				<Spinner
					thickness="2px"
					speed="0.65s"
					emptyColor="gray.200"
					color="gray.700"
					size="xl"
				/>
			</Flex>
		);
	}
	return (
		<VStack w="100%" spacing="10">
			<Flex
				shrink={0}
				w="100%"
				direction="column"
				justifyContent="space-between"
			>
				<Flex width="100%" direction="column">
					<Heading as="h2" size="md" isTruncated>
						Security Settings
					</Heading>
					<Text mt='4' fontSize="16px" color="gray.500" maxW="600px">
						Depending on your product and target audience, you have the flexibility to enable or disable security features, ensuring your deals are protected from unauthorized access.</Text>
						{!user.isActiveSubscription && (<UpgradeNowAlert message={"All the Below security features require a PRO plan."} />)}
				</Flex>
				<Divider mt="10" mb="10" />
				{getRefreshCouponsMarkup()}

			</Flex>
			<Flex width="100%" direction="column">
				<Flex>
					<Flex>
						<Heading as='h6' size='20px' fontWeight="500">VPN Protection</Heading>
						{!user.isActiveSubscription && (
							<Tooltip label='VPN Protection is only available to PRO users'>
								<Box marginLeft={'2'} display='flex' alignItems='center'>
									<Badge variant='outline' colorScheme='yellow'>PRO</Badge>
								</Box>
							</Tooltip>
						)}
					</Flex>
				</Flex>
				<VpnProtection isEnabled={user.isActiveSubscription} id={id} defaultValue={dealSettingData.vpn}/>
			</Flex>
			<Divider mt="8" />
			<Flex width="100%" direction="column">
				<Flex>
					<Flex>
						<Heading as='h6' size='20px' fontWeight="500">Tor Browser Protection</Heading>
						{!user.isActiveSubscription && (
							<Tooltip label='Tor Browser Protection is only available to PRO users'>
								<Box marginLeft={'2'} display='flex' alignItems='center'>
									<Badge variant='outline' colorScheme='yellow'>PRO</Badge>
								</Box>
							</Tooltip>
						)}
					</Flex>
				</Flex>
				<TorBrowserProtection isEnabled={user.isActiveSubscription} id={id} defaultValue={dealSettingData.tor}/>
			</Flex>
			<Divider mt="8" />
			<Flex width="100%" direction="column">
				<Flex>
					<Flex>
						<Heading as='h6' size='20px' fontWeight="500">Proxy servers Protection</Heading>
						{!user.isActiveSubscription && (
							<Tooltip label='Proxy servers Protection is only available to PRO users'>
								<Box marginLeft={'2'} display='flex' alignItems='center'>
									<Badge variant='outline' colorScheme='yellow'>PRO</Badge>
								</Box>
							</Tooltip>
						)}
					</Flex>
				</Flex>
				<ProxyProtection isEnabled={user.isActiveSubscription} id={id} defaultValue={dealSettingData.proxy} />
			</Flex>
		</VStack>
	);
};
