import * as React from 'react';
import {
	ChakraProvider,
	Box,
	Grid,
} from '@chakra-ui/react';
import theme from './theme/theme';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import { PostHogProvider} from 'posthog-js/react'
import SignUp from './containers/SignUp/SignUp';
import axios from 'axios';
import { removeAuthenticationDetails, setAuthDetails } from './utils/authUtils';
import Login from './containers/Login/Login';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { App } from './containers/App/App';
import { CreateDeals } from './containers/CreateDeals/CreateDeals';
import EditDeals from './containers/EditDeals/EditDeals';
import { CreateMarketplaceDeals } from './containers/CreateMarketplaceDeals/CreateMarketplaceDeals';
import EditMarketplaceDeals from './containers/EditMarketplaceDeals/EditMarketplaceDeals';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';

interface AppState {
	isAuthenticated: boolean;
}
interface AppProps {}
export class Base extends React.Component<AppProps, AppState> {
	public constructor(props: any) {
		super(props);
		this.state = {
			isAuthenticated: !!localStorage.getItem('accesstoken'),
		};
		this.interceptor();
	}
	interceptor() {
		axios.interceptors.response.use(
			(response: any) => {
				return response;
			},
			(error: any) => {
				if (error.response && error.response.status === 401) {
					removeAuthenticationDetails();
					this.setState({
						isAuthenticated: false,
					});
				}
				return Promise.reject(error);
			}
		);
		const token = localStorage.getItem('accesstoken');
		if (!token) {
			this.setState({
				isAuthenticated: false,
			});
		} else {
			this.setState({
				isAuthenticated: true,
			});
			setAuthDetails(token as string);
		}
	}
	onLoginIn = (token: string) => {
		setAuthDetails(token as string);
		this.setState({
			isAuthenticated: true,
		});
	};
	onLoginOut = () => {
		removeAuthenticationDetails();
		this.setState({
			isAuthenticated: false,
		});
	};
	render() {
		return (
			<ChakraProvider theme={theme}>
				<PostHogProvider 
					apiKey="phc_EwHOit5CAndJ75LD4wL7Ts8GmUyMl9kPVO4e3HwGBVM" 
					options={{
						host: "https://app.posthog.com",
					}}
					>
					<Box>
						<Grid minH='100vh'>
							<Router>
								<Switch>
									<Route path='/sign-up'>
										<SignUp onSignUp={this.onLoginIn} />
									</Route>
									<Route path='/login'>
										<Login onLoginIn={this.onLoginIn} />
									</Route>
									<Route path='/forgot-password'>
										<ForgotPassword />
									</Route>
									<Route path='/reset-password'>
										<ResetPassword />
									</Route>
									<PrivateRoute
										isAuthenticated={this.state.isAuthenticated}
										path='/app/create-deals'
										component={CreateDeals}
									></PrivateRoute>
									<PrivateRoute
										isAuthenticated={this.state.isAuthenticated}
										path='/app/edit-deals/:dealId'
										component={EditDeals}
									></PrivateRoute>
									<PrivateRoute
										isAuthenticated={this.state.isAuthenticated}
										path='/app/edit-marketplace-deals/:dealId'
										component={EditMarketplaceDeals}
									></PrivateRoute>
									<PrivateRoute
										isAuthenticated={this.state.isAuthenticated}
										path='/app/create-marketplace-deals'
										component={CreateMarketplaceDeals}
									></PrivateRoute>
									<PrivateRoute
										isAuthenticated={this.state.isAuthenticated}
										path='/app'
										onLoginOut={this.onLoginOut}
										component={App}
									></PrivateRoute>
									<PrivateRoute
										path='/'
										isAuthenticated={this.state.isAuthenticated}
										onLoginOut={this.onLoginOut}
										component={App}
									></PrivateRoute>
								</Switch>
							</Router>
						</Grid>
					</Box>
				</PostHogProvider>
			</ChakraProvider>
		);
	}
}
