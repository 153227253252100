import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../constants/app';

export interface Integration {
	authUrl: string
    platform: "gumroad" | 'stripe' | 'paddle' | 'lemonsqueezy' | 'whop' | 'chargebee'
    status: boolean
}

export async function getIntegrationsList(): Promise<
	AxiosResponse<Integration[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/',
	});
}

export async function disableIntegration(platform: string): Promise<
	AxiosResponse<Integration[]>
> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
        data: {
            platform
        },
		url: '/integrations/disable/',
	});
}
