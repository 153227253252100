import * as React from "react";
import {
	Box,
	Button,
	Flex,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	Text,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Spinner,
	Stack,
	useToast,
	useDisclosure,
	VStack,
	Select,
	Skeleton,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";

import posthog from "posthog-js";

import { useEffect, useState } from "react";
import styles from './Dashboard.module.scss';
import { deleteDeal, fetchDeals } from "../../services/dealServices";
import { Deal } from "../../types/deals.type";
import {
	MediaListItem,
	MetaListMenuItems,
} from "../../components/MediaListItem/MediaListItem";
import { NoDataDeals } from "../../components/NoData/NoDataDeals";
import { GumroadIcon } from "../../components/Icons/Gumroad";
import { PlusIcon } from "../../components/Icons/Plus";
import { fetchGumroadProducts } from "../../services/productServices";
import {
	connectWithGumroad,
	getGumroadAuthUrl,
} from "../../services/authServices";

import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	BarChart,
	Bar,
	ComposedChart,
	Line,
	PieChart,
	Pie,
	Cell,
} from "recharts";

import { format } from "date-fns";

import {
	CountrySalesData,
	CountryVisitorsData,
	DashboardDuration,
	DatedSalesData,
	DatedVisitorsData,
	fetchSales,
	fetchVisitors,
	OfferSalesData,
	PppFactorSalesData,
	PppFactorVisitorsData,
} from "../../services/dashboardServices";
import { parityGroup, payHipData } from "../../utils/payHipData";
import _, { keys, has } from "lodash";
import { getPPPToolTip, getSalesToolTip } from "./DashboardUtils";
import { NoDataInfo } from "../../components/NoDataInfo/NoDataInfo";

const chartColors = [
	"#F0A8A8",
	"#A3C297",
	"#A3C297",
	"#A3DFF2",
	"#A8A8F0",
	"#A8F0EC",
	"#A997C2",
	"#B3907D",
	"#B2D5FF",
	"#A3C297",
	"#B8DFD0",
	"#BF97C2",
	"#C297AD",
	"#CCA8F0",
	"#EAA7C7",
	"#EBB2FF",
	"#F0CFA8",
	"#F0D0DF",
	"#FEDC98",
	"#FFB2D9",
	"#FFB2C9",
	"#FFCEB2",
	"#FFD19A",
];

const RADIAN = Math.PI / 180;

export const Dashboard = () => {
	const [dealsList, setDealsList] = React.useState<Deal[]>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [selectedDuration, setSelectedDuration] =
		React.useState<DashboardDuration>("thisweek");

	const [selectedDeal, setSelectedDeal] = React.useState<string>("all");

	const [countryVisitors, setCountryVisitors] =
		React.useState<CountryVisitorsData[]>();
	const [datedVisitors, setDatedVisitors] =
		React.useState<DatedVisitorsData[]>();
	const [datedSales, setDatedSales] = React.useState<DatedSalesData[]>();
	const [datedSalesRevenue, setDatedSalesRevenue] = React.useState<DatedSalesData[]>();
	const [pppFactorVisitors, setPppFactorVisitorsData] =
		React.useState<PppFactorVisitorsData[]>();
	const [countrySales, setCountrySales] = React.useState<CountrySalesData[]>();
	const [offerSales, setOfferSales] = React.useState<OfferSalesData[]>();
	const [pppFactorSales, setPppFactorSalesData] =
		React.useState<PppFactorSalesData[]>();

	const isPPPfactorSalesChartHasData = (data: PppFactorVisitorsData[]) => {
    if (!data) return true;
    const hasData = data.some((item) => item.visitors || item.uniqueVisitors);
    return hasData;
  };

  const [showPppFactorVisitorsData, setShowPppFactorVisitorsData] =
    useState(false);

  useEffect(() => {
    setShowPppFactorVisitorsData(
      isPPPfactorSalesChartHasData(pppFactorVisitors || [])
    );
  }, [pppFactorVisitors]);

  const isDatedSalesChartEmpty = (data: PppFactorSalesData[]) => {
    if (!data) return true;
    const ieEmpty = data.every(
      (item) => item.quantity !== 0 || item.revenue !== 0
    );
    return ieEmpty;
  };
  const [showdatedsalesdata, setShowDatedSalesData] = useState(false);

  useEffect(() => {
    setShowDatedSalesData(isDatedSalesChartEmpty(pppFactorSales || []));
  }, [pppFactorSales]);

  const isCountrySalesChartEmpty = (data: CountrySalesData[]) => {
    if (!data || data.length === 0) return true;
    const isNotEmpty = data.every(
      (item) => item.quantity !== 0 || item.revenue !== 0
    );
    return !isNotEmpty;
  };

  const [showCountrySalesData, setShowCountrySalesData] = useState(false);
  useEffect(() => {
    const isEmpty = isCountrySalesChartEmpty(countrySales || []);
    setShowCountrySalesData(!isEmpty);
  }, [countrySales]);

  const countryVisitorsChartHasData = (data: CountryVisitorsData[]) => {
    if (!data) return true;
    const hasData = data.some((item) => item.visitors || item.uniqueVisitors);
    return hasData;
  };
  const [showCountryVisitorsData, setShowCountryVisitorsData] = useState(false);
  useEffect(() => {
    setShowCountryVisitorsData(
      countryVisitorsChartHasData(countryVisitors || [])
    );
  }, [countryVisitors]);

  const isOfferSalesChartEmpty = (data: OfferSalesData[]) => {
    if (!data) return true;
    const ieEmpty = data.every((item) => item.quantity !== 0);
    return ieEmpty;
  };

  const [showOfferSalesData, setShowOfferSalesData] = useState(false);
  useEffect(() => {
    setShowOfferSalesData(isOfferSalesChartEmpty(offerSales || []));
  }, [offerSales]);

  const datedVisitorsChartHasData = (data: DatedVisitorsData[]) => {
    if (!data) return true;
    const hasData = data.some((item) => item.uniqueVisitors || item.visitors);
    return hasData;
  };
  const [showDatedVisitors, setShowDatedVisitors] = useState(false);
  useEffect(() => {
    setShowDatedVisitors(datedVisitorsChartHasData(datedVisitors || []));
  }, [datedVisitors]);

  const isDatedSalesRevenueChartEmpty = (data: DatedSalesData[]) => {
    if (!data) return true;
    for (const entry of data) {
      for (const key in entry) {
        if (key !== "date" && (entry as any)[key] !== 0) {
          return false;
        }
      }
    }
    return true;
  };

  const [showDatedSalesRevenueData, setShowDatedSalesRevenueData] =
    useState(false);

  useEffect(() => {
    setShowDatedSalesRevenueData(
      !isDatedSalesRevenueChartEmpty(datedSalesRevenue || [])
    );
  }, [datedSalesRevenue]);

  const isDatedSalesPppValueChartEmpty = (data: DatedSalesData[]) => {
    if (!data) return true;
    for (const entry of data) {
      for (const key in entry) {
        if (key !== "date" && (entry as any)[key] !== 0) {
          return false;
        }
      }
      return true;
    }
  };
  const [showDatedSalesPppValueData, setShowDatedSalesPppValueData] =
    useState(false);

  useEffect(() => {
    setShowDatedSalesPppValueData(
      !isDatedSalesPppValueChartEmpty(datedSales || [])
    );
  }, [datedSales]);

	 const getVisitorsData = React.useCallback(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const visotorResponse = await fetchVisitors(
				selectedDuration,
				selectedDeal === "all" ? "" : selectedDeal
			);
			const salesResponse = await fetchSales(
				selectedDuration,
				selectedDeal === "all" ? "" : selectedDeal
			);

			setCountryVisitors(visotorResponse.data.countryVisitorsData.slice(0, 50));
			setDatedVisitors(visotorResponse.data.datedVisitorsData);
			const datedSalesData = salesResponse.data.dateSales
			const datedSalesRevenue = salesResponse.data.datedSalesRevenue;
			setOfferSales(salesResponse.data.offerSales);
			const pppSalesData = salesResponse.data.pppFactorSales;

			const pppData = visotorResponse.data.pppFactorVisitorsData;
			Object.keys(pppData).forEach((key) => {
				const d = (salesResponse.data as any).pppFactorSales.find(
					(ppp: any) => ppp.pppFactor === (pppData as any)[key].pppFactor
				);
				if (d) {
					(pppData as any)[key].sales = d.quantity;
				}
			});

			const countryData = salesResponse.data.countrySales;
			Object.keys(countryData).forEach((key) => {
				const d = (visotorResponse.data as any).countryVisitorsData.find(
					(countryVisitors: any) =>
						countryVisitors.code === (countryData as any)[key].code
				);
				if (d) {
					(countryData as any)[key].visitors = d.visitors;
					(countryData as any)[key].uniqueVisitors = d.uniqueVisitors;
				}
			});
			countryData.sort((a, b) =>
				b.revenue > a.revenue ? 1 : a.revenue > b.revenue ? -1 : 0
			);
			// if (selectedDuration === 'previousyear') {
			// 	let janData = [] as any;
			// 	Object.keys(payHipData as any).forEach((k: any, index) => {
			// 		janData = [] as any;
			// 		const payHipItem = payHipData[k];
			// 		const graphData = {};
			// 		payHipItem.forEach((payHipItem: any) => {
			// 			const d = parityGroup.find(
			// 				(countryVisitors: any) =>
			// 					countryVisitors.countryName.toLowerCase() ===
			// 					payHipItem.country.toLowerCase()
			// 			);
			// 			if (d) {
			// 				payHipItem.pppFactor = d.range;
			// 				payHipItem.code = d.country;
			// 			}
			// 			janData.push(payHipItem);
			// 		});
			// 		janData.sort((a, b) =>
			// 			b.pppFactor > a.pppFactor ? 1 : a.pppFactor > b.pppFactor ? -1 : 0
			// 		);
			// 		const group = _.groupBy(janData, "pppFactor");


			// 		console.log('group', group)
			// 		// Object.keys(group).forEach((key) => {
			// 		// 	const pppgroup = group[key];
			// 		// 	let totalViews = 0;
			// 		// 	let totalRev = 0;
			// 		// 	console.log(pppgroup);
			// 		// 	pppgroup.forEach((item) => {
			// 		// 		totalViews += item.views;
			// 		// 		totalRev += parseFloat(item.revenue);
			// 		// 	});
			// 		// 	graphData.push({
			// 		// 		pppFactor: pppgroup[0].pppFactor,
			// 		// 		revenue: totalRev,
			// 		// 		views: totalViews,
			// 		// 	});
			// 		// });
			// 		let totalRev = 0;
			// 		Object.keys(group).forEach((key) => {
			// 			const pppgroup = group[key];
			// 			let totalViews = 0;
			// 			totalRev = 0;
			// 			pppgroup.forEach((item) => {
			// 				totalViews += item.views;
			// 				if (k === '2021-06-01') {
			// 					console.log(totalRev, item.revenue, parseFloat(item.revenue, 10))
			// 				}
			// 				totalRev += parseFloat(item.revenue, 10);
			// 			});
			// 			if (pppgroup[0].pppFactor) {
			// 				graphData[[pppgroup[0].pppFactor]] = totalRev;
			// 			}
			// 			totalRev = 0;
			// 		});
			// 		graphData.date = k;
			// 		console.log('graphData', graphData)

			// 		datedSalesRevenue[index] = graphData

			// 	});
			// }
			// console.log('datedSalesData', datedSalesData)
			setDatedSales(datedSalesData);
			setDatedSalesRevenue(datedSalesRevenue);

			setPppFactorSalesData(pppSalesData);
			setCountrySales(countryData);
			setPppFactorVisitorsData(pppData);
			setIsLoading(false);
		};
		fetchData();
	}, [selectedDeal, selectedDuration]);
	React.useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const dealsResponse = await fetchDeals();
			setDealsList(dealsResponse.data);
		};
		fetchData();
	}, []);
	React.useEffect(() => {
		getVisitorsData();
	}, [getVisitorsData]);

	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);

	const handleDurationChange = React.useCallback((event) => {
		console.log("event.target.value", event.target.value);
		setSelectedDuration(event.target.value);
	}, []);

	const handleDealChange = React.useCallback((event) => {
		console.log("event.target.value", event.target.value);
		setSelectedDeal(event.target.value);
	}, []);

	const CustomPPPTooltip = (data) => {
		if (data.active && data.payload && data.payload.length) {
			return (
				<Box border="1px solid" borderColor="gray.200" borderRadius="md" bg="whiteAlpha.900" p="4">
					<Text mb="2" color="gray.500" fontSize="sm" fontWeight="600">{`${data.label}`}</Text>
					{getPPPToolTip(data.payload)}
				</Box>
			);
		}
		return null;
	};
	const CustomSalesTooltip = (data) => {
		if (data.active && data.payload && data.payload.length) {
			return (
				<Box border="1px solid" borderColor="gray.200" borderRadius="md" bg="whiteAlpha.900" p="4">
					<Text mb="2" color="gray.500" fontSize="sm" fontWeight="600">{`${data.label}`}</Text>
					{getSalesToolTip(data.payload)}
				</Box>
			);
		}
		return null;
	};

	const CustomTooltip = (data) => {
		if (data.active && data.payload && data.payload.length) {
			console.log(data)
			return (
				<Box border="1px solid" borderColor="gray.200" borderRadius="md" bg="whiteAlpha.900" p="4">
					<Text mb="2" color="gray.500" fontSize="sm" fontWeight="600">{`${data.label}`}</Text>
					{data.payload.map((item) => {
						return (
							<Box key={item.name} mb="0.5">
								<Text fontSize="sm" as="span" color="gray.400">
									{item.name} :{" "}
								</Text>
								<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
									{item.value.toFixed(0)}
								</Text>
							</Box>
						);
					})}
				</Box>
			);
		}

		return null;
	};
	const CustomCountryTooltip = (data) => {
		if (data.active && data.payload && data.payload.length) {
			return (
				<Box border="1px solid" borderColor="gray.200" borderRadius="md" bg="whiteAlpha.900" p="4">
					<Text mb="2" color="gray.500" fontSize="sm" fontWeight="600">{`${data.payload[0]?.payload.name}`}</Text>
					<Box mb="0.5">
						<Text fontSize="sm" as="span" color="gray.400">
							Revenue :{" "}
						</Text>
						<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
							${data.payload[0]?.payload.revenue.toFixed(0)}
						</Text>
					</Box>
					<Box mb="0.5">
						<Text fontSize="sm" as="span" color="gray.400">
							PPP :{" "}
						</Text>
						<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
							{data.payload[0]?.payload.pppFactor}
						</Text>
					</Box>
					<Box mb="0.5">
						<Text fontSize="sm" as="span" color="gray.400">
							visitors :{" "}
						</Text>
						<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
							{data.payload[0]?.payload.visitors}
						</Text>
					</Box>
					<Box mb="0.5">
						<Text fontSize="sm" as="span" color="gray.400">
							uniqueVisitors :{" "}
						</Text>
						<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
							{data.payload[0]?.payload.uniqueVisitors}
						</Text>
					</Box>
					{data.payload.map((item) => {
						return (
							<Box key={item.name} mb="0.5">
								<Text fontSize="sm" as="span" color="gray.400">
									{item.name} :{" "}
								</Text>
								<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
									{item.value.toFixed(0)}
								</Text>
							</Box>
						);
					})}
				</Box>
			);
		}

		return null;
	};
	const CustomCountryVisitorTooltip = (data) => {
		if (data.active && data.payload && data.payload.length) {
			console.log('data', data)
			console.log('data.payload.payload', data.payload[0])
			return (
				<Box border="1px solid" borderColor="gray.200" borderRadius="md" bg="whiteAlpha.900" p="4">
					<Text mb="2" color="gray.500" fontSize="sm" fontWeight="600">{`${data.payload[0]?.payload.name}`}</Text>
					{data.payload.map((item) => {
						return (
							<Box key={item.name} mb="0.5">
								<Text fontSize="sm" as="span" color="gray.400">
									{item.name} :{" "}
								</Text>
								<Text fontSize="sm" as="b" fontWeight="500" ml="1" color="gray.500">
									{item.value.toFixed(0)}
								</Text>
							</Box>
						);
					})}
				</Box>
			);
		}

		return null;
	};

	const customizedDateTick = React.useCallback((props: any) => {
		const { payload, height, width, index, textAnchor, x, y } = props;
		const attrs = {
			height,
			width,
			index,
			textAnchor,
			x,
			y,
			fill: "#A0AEC0",
			fontSize: 12,
		};
		let result = '';
		if (selectedDuration === 'thisweek' || selectedDuration === 'previousweek') {
			result = format(new Date(payload.value), "E, MMM d");
		} else if (selectedDuration === 'thismonth' || selectedDuration === 'previousmonth') {
			result = format(new Date(payload.value), "d/M");
		} else {
			result = format(new Date(payload.value), "MMM/y");
		}

		return (
			<text {...attrs}>
				<tspan x={x} dy="8">
					{result}
				</tspan>
			</text>
		);
	}, [selectedDuration]);
	const customizedPPPTick = (props: any) => {
		const { payload, height, width, index, textAnchor, x, y } = props;
		const attrs = {
			height,
			width,
			index,
			textAnchor,
			x,
			y,
			fill: "#A0AEC0",
			fontSize: 12,
		};

		return (
			<text {...attrs}>
				<tspan x={x} dy="8">
					{payload.value.split("-")[1]}
				</tspan>
			</text>
		);
	};

	const getLoadingComponent = React.useCallback(() => {
		return (
			<VStack spacing={8}>
				<Skeleton width="100%" height="200px" />
				<Skeleton width="100%" height="200px" />
				<HStack spacing={8} width="100%">
					<Skeleton width="50%" height="200px" />
					<Skeleton width="50%" height="200px" />
				</HStack>
				<Skeleton width="100%" height="200px" />
			</VStack>
		);
	}, []);

	const getNoDataIcon = React.useCallback(() => {
		return (
			<VStack
				spacing="9"
				alignItems="center"
				direction="column"
				justifyContent="center"
				w="100%"
				h="100%"
			>
				<NoDataInfo />
				<Heading
					maxW="780px"
					fontSize="2xl"
					fontWeight="semibold"
					as="h3"
					lineHeight="medium"
					textAlign="center"
				>
					No Data Found
				</Heading>
			</VStack>
		);
	}, []);

	const getChartComponent = React.useCallback(() => {
		return (
			<VStack spacing={6}>
				{
					!showPppFactorVisitorsData && !showCountrySalesData && !showDatedSalesPppValueData && !showDatedSalesRevenueData && !showOfferSalesData && !showDatedVisitors && !showCountryVisitorsData  &&(
						getNoDataIcon()
					)
				}
				{showPppFactorVisitorsData &&(
				<Box width="100%" height="340px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Visitors by PPP value
							</Heading>
							<Box height="300px">
								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={pppFactorVisitors}>
										<XAxis
											tick={{ fontSize: "10px" }}
											stroke="#A0AEC0"
											dataKey="pppFactor"
											interval={0}
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<YAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip content={<CustomTooltip />} />
										<Legend wrapperStyle={{ fontSize: 11 }} />
										<Bar barSize={40} dataKey="visitors" fill="#8884d8" />
										<Line
											type="monotone"
											dataKey="uniqueVisitors"
											stroke="#ff7300"
										/>
										<Line type="monotone" dataKey="sales" stroke="#82ca9d" />
										{/* <Bar dataKey="uniqueVisitors" fill="#82ca9d" />	 */}
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
							{/* <HStack mb="8" spacing="8">
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">VISITORS</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">UNIQUE VISITORS</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">TOTAL SALES</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
							</HStack> */}

						</Flex>
					
				</Box>
				
	)}
				{/* <Box width="100%" height="340px">
					{datedSales ? (
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Sales by PPP value
							</Heading>
							<Box height="300px">
								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={datedSales}>
										<XAxis
											stroke="#A0AEC0"
											dataKey="date"
											tick={customizedDateTick}
											interval={0}
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<YAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip content={<CustomPPPTooltip />} />
										<Legend wrapperStyle={{fontSize: 11}} />
										<Bar barSize={40} stroke="none" dataKey="0.0-0.1" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.1-0.2" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.2-0.3" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.3-0.4" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.4-0.5" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.5-0.6" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.6-0.7" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.7-0.8" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.8-0.9" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.9-1.0" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.0-1.1" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.1-1.2" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.3-1.4" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.7-1.8" stackId="a" fill="#A3C297" />
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>
					) : null}
				</Box> */}
				 {showDatedSalesPppValueData &&(
				<Box width="100%" height="340px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Sales by PPP value
							</Heading>
							<Box height="300px">
								{/* <HStack mb="8" spacing="8">
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">VISITORS</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">UNIQUE VISITORS</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">TOTAL SALES</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
							</HStack> */}
								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={datedSales}>
										<XAxis
											stroke="#A0AEC0"
											dataKey="date"
											tick={customizedDateTick}
											interval={0}
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<YAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip content={<CustomSalesTooltip />} />
										<Legend wrapperStyle={{ fontSize: 11 }} />
										<Bar barSize={40} stroke="none" dataKey="0.0-0.1" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.1-0.2" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.2-0.3" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.3-0.4" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.4-0.5" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.5-0.6" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.6-0.7" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.7-0.8" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.8-0.9" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.9-1.0" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.0-1.1" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.1-1.2" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.3-1.4" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.7-1.8" stackId="a" fill="#A3C297" />
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>					
				</Box>
				)}
				
				{showDatedSalesRevenueData && (
				<Box width="100%" height="340px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Revenue by PPP value
							</Heading>
							<Box height="300px">
								{/* <HStack mb="8" spacing="8">
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">VISITORS</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">UNIQUE VISITORS</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
								<Box>
									<Box fontWeight="600" color="gray.500" fontSize="xs" w="100%">TOTAL SALES</Box>
									<Box fontWeight="600" color="gray.600" fontSize="lg" w="100%">20K</Box>
								</Box>
							</HStack> */}
								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={datedSalesRevenue}>
										<XAxis
											stroke="#A0AEC0"
											dataKey="date"
											tick={customizedDateTick}
											interval={0}
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<YAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip content={<CustomPPPTooltip />} />
										<Legend wrapperStyle={{ fontSize: 11 }} />
										<Bar barSize={40} stroke="none" dataKey="0.0-0.1" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.1-0.2" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.2-0.3" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.3-0.4" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.4-0.5" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.5-0.6" stackId="a" fill="#A8A8F0" />
										<Bar barSize={40} stroke="none" dataKey="0.6-0.7" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.7-0.8" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.8-0.9" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="0.9-1.0" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.0-1.1" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.1-1.2" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.3-1.4" stackId="a" fill="#A3C297" />
										<Bar barSize={40} stroke="none" dataKey="1.7-1.8" stackId="a" fill="#A3C297" />
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>
				</Box>
					)}
				<HStack width="100%" spacing={4}>
					{showOfferSalesData && (
					<Box shadow="xs" p="4" bg="white" borderRadius="lg" width="40%">
						<Heading as="h4" fontSize="medium" mb="6">
							Sales by discount %
						</Heading>
						<Box height="300px">
								<ResponsiveContainer width="100%" height="100%">
									<BarChart data={offerSales}>
										<XAxis
											tick={{ fontSize: "10px" }}
											stroke="#A0AEC0"
											dataKey="percentage"
											interval={0}
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<YAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
										<Bar barSize={40} dataKey="quantity" fill="#8884d8" />
									</BarChart>
								</ResponsiveContainer>
						</Box>
					</Box>
						)}
					{showdatedsalesdata && (
					<Box shadow="xs" p="4" bg="white" borderRadius="lg" width="60%">
						<Heading as="h4" fontSize="medium" mb="6">
							Sales by PPP value
						</Heading>
						<Box height="300px">
								<ResponsiveContainer width="100%" height="100%">
									<BarChart data={pppFactorSales}>
										<XAxis
											stroke="#A0AEC0"
											dataKey="pppFactor"
											tick={customizedPPPTick}
											interval={0}
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<YAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
										<Bar barSize={40} dataKey="quantity" fill="#8884d8" />
									</BarChart>
								</ResponsiveContainer>
						</Box>
					</Box>
					)}
				</HStack>
				{showCountrySalesData && (
				<Box width="100%" height="300px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Revenue by country
							</Heading>
							<Box height="300px">
								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={countrySales}>
										<XAxis
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
											dataKey="code"
											interval={0}
										/>
										<YAxis stroke="#A0AEC0" style={{ fill: "#A0AEC0", fontSize: 12 }} />
										<Tooltip content={<CustomCountryTooltip />} />
										{/* <Legend wrapperStyle={{ fontSize: 11 }} /> */}
										<Bar barSize={40} dataKey="revenue" fill="#8884d8" />
										{/* <Line
											type="monotone"
											dataKey="uniqueVisitors"
											stroke="#ff7300"
										/>
										<Line type="monotone" dataKey="quantity" stroke="#f00" />
										<Line type="monotone" dataKey="visitors" stroke="#82ca9d" /> */}
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>
				</Box>
				)}
				{showCountrySalesData && (
				<Box width="100%" height="300px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Sales by country
							</Heading>
							<Box height="300px">
								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={countrySales}>
										<XAxis
											style={{ fill: "#A0AEC0", fontSize: 12 }}
											dataKey="code"
											interval={0}
											stroke="#A0AEC0"
										/>
										<YAxis stroke="#A0AEC0" style={{ fill: "#A0AEC0", fontSize: 12 }} />
										<Tooltip content={<CustomCountryTooltip />} />
										{/* <Legend wrapperStyle={{ fontSize: 11 }} /> */}
										<Bar barSize={40} dataKey="quantity" fill="#8884d8" />
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>
				</Box>
				)}
				{showDatedVisitors && (
				<Box width="100%" height="300px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Visitors by days
							</Heading>
							<Box height="300px">

								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart barSize={50} data={datedVisitors}>
										<CartesianGrid strokeDasharray="3 3" />
										<defs>
											<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
												<stop offset="0%" stopColor="#CEC8F1" />
												<stop
													offset="100%"
													stopColor="#FFFFFF"
													stopOpacity={0.1}
												/>
											</linearGradient>
										</defs>
										<XAxis
											axisLine={false}
											tick={customizedDateTick}
											dataKey="date"
											stroke="#A0AEC0"
										/>
										<YAxis
											axisLine={false}
											stroke="#A0AEC0"
											style={{ fill: "#A0AEC0", fontSize: 12 }}
										/>
										<Tooltip
											contentStyle={{
												borderRadius: "6px",
												overflow: "hidden",
												borderColor: "#E6e6e6",
											}}
										/>
										<Legend wrapperStyle={{ fontSize: 11 }} />
										{/* <Bar dataKey="visitors" fill="#8884d8" /> */}
										<Area
											type="linear"
											dataKey="visitors"
											stroke="#8884d8"
											fill="url(#colorUv)"
											activeDot={{ r: 8 }}
										/>
										<Line
											type="monotone"
											dataKey="uniqueVisitors"
											stroke="#ff7300"
										/>
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>
				</Box>
					)}
					{showCountryVisitorsData &&(
				<Box width="100%" height="300px">
						<Flex
							direction="column"
							shadow="xs"
							p="4"
							bg="white"
							height="100%"
							borderRadius="lg"
						>
							<Heading as="h4" fontSize="medium" mb="6">
								Visitors by country
							</Heading>
							<Box height="300px">

								<ResponsiveContainer width="100%" height="100%">
									<ComposedChart data={countryVisitors}>
										<XAxis
											style={{ fill: "#A0AEC0", fontSize: 12 }}
											dataKey="code"
											stroke="#A0AEC0"
											interval={0}
										/>
										<YAxis stroke="#A0AEC0" style={{ fill: "#A0AEC0", fontSize: 12 }} />
										<Tooltip content={<CustomCountryVisitorTooltip />} />
										<Legend wrapperStyle={{ fontSize: 11 }} />
										<Bar dataKey="visitors" fill="#8884d8" />
										<Line
											type="monotone"
											dataKey="uniqueVisitors"
											stroke="#ff7300"
										/>
									</ComposedChart>
								</ResponsiveContainer>
							</Box>
						</Flex>
				</Box>
				)}
			</VStack>
		);
	}, [countrySales, countryVisitors, customizedDateTick, datedSales, datedSalesRevenue, datedVisitors, getNoDataIcon, offerSales, pppFactorSales, pppFactorVisitors, showCountrySalesData, showCountryVisitorsData, showDatedSalesPppValueData, showDatedSalesRevenueData, showDatedVisitors, showOfferSalesData, showPppFactorVisitorsData, showdatedsalesdata]);

	const getMainComponent = React.useCallback(() => {
		if (isLoading) {
			return getLoadingComponent();
		} else {
			return getChartComponent();
		}
	}, [getChartComponent, getLoadingComponent, isLoading]);

	return (
		<Box
			w="100%"
			p="6"
			shadow="sm"
			h="100%"
			position="relative"
			overflow="auto"
		>
			<VStack maxW={"1200px"} w="100%" spacing="10">
				<Flex
					shrink={0}
					w="100%"
					direction="column"
					justifyContent="space-between"
				>
					<HStack mb={8}>
						<Flex width="100%" mb="8">
							<Heading fontSize="30">Dashboard</Heading>
						</Flex>
						<HStack>
							<Select bg="white"
								w="300px"
								maxW="350px"
								onChange={handleDealChange}
								value={selectedDeal}
							>
								<option key="all" value="all">
									All products
								</option>
								{dealsList?.map((deal) => (
									<option key={deal.id} value={deal.id}>
										{deal.name}
									</option>
								))}
							</Select>
							<Select bg="white" onChange={handleDurationChange} value={selectedDuration}>
								<option value="thisweek">This Week</option>
								<option value="previousweek">Previous Week</option>
								<option value="thismonth">This Month</option>
								<option value="previousmonth">Previous Month</option>
								<option value="thisyear">This Year</option>
								<option value="previousyear">Previous Year</option>
							</Select>
						</HStack>
					</HStack>

					{getMainComponent()}
				</Flex>
			</VStack>
		</Box>
	);
};
