import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
}
export const DashboardIcon = ({
  width = 24,
  height = 24,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2603 4H17.9691C19.0909 4 19.9999 4.91668 19.9999 6.04797V8.77962C19.9999 9.91091 19.0909 10.8276 17.9691 10.8276H15.2603C14.1385 10.8276 13.2295 9.91091 13.2295 8.77962V6.04797C13.2295 4.91668 14.1385 4 15.2603 4Z"
        fill="#C6CCD5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03082 4H8.73959C9.86141 4 10.7704 4.91668 10.7704 6.04797V8.77962C10.7704 9.91091 9.86141 10.8276 8.73959 10.8276H6.03082C4.909 10.8276 4 9.91091 4 8.77962V6.04797C4 4.91668 4.909 4 6.03082 4ZM6.03082 13.1725H8.73959C9.86141 13.1725 10.7704 14.0891 10.7704 15.2204V17.9521C10.7704 19.0826 9.86141 20 8.73959 20H6.03082C4.909 20 4 19.0826 4 17.9521V15.2204C4 14.0891 4.909 13.1725 6.03082 13.1725ZM17.9692 13.1725H15.2604C14.1386 13.1725 13.2296 14.0891 13.2296 15.2204V17.9521C13.2296 19.0826 14.1386 20 15.2604 20H17.9692C19.091 20 20 19.0826 20 17.9521V15.2204C20 14.0891 19.091 13.1725 17.9692 13.1725Z"
        fill="#414C6B"
      />
    </svg>
  );
};
