import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
}
export const DocsIcon = ({
  width = 24,
  height = 24,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...restProps}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
        fill="#C6CCD5"
      />
      <path
        d="M16.0847 2.56737C15.7857 2.25637 15.2637 2.47037 15.2637 2.90137V5.53837C15.2637 6.64437 16.1747 7.55437 17.2807 7.55437C17.9777 7.56237 18.9457 7.56437 19.7677 7.56237C20.1887 7.56137 20.4027 7.05837 20.1107 6.75437C19.0557 5.65737 17.1667 3.69137 16.0847 2.56737Z"
        fill="#C6CCD5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97447 11.3874H12.3595C12.7705 11.3874 13.1045 11.0544 13.1045 10.6434C13.1045 10.2324 12.7705 9.89844 12.3595 9.89844H8.97447C8.56347 9.89844 8.23047 10.2324 8.23047 10.6434C8.23047 11.0544 8.56347 11.3874 8.97447 11.3874ZM8.97457 16.3816H14.4186C14.8296 16.3816 15.1636 16.0486 15.1636 15.6376C15.1636 15.2266 14.8296 14.8926 14.4186 14.8926H8.97457C8.56357 14.8926 8.23057 15.2266 8.23057 15.6376C8.23057 16.0486 8.56357 16.3816 8.97457 16.3816Z"
        fill="#4A5568"
      />
    </svg>
  );
};
