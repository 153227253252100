import * as React from "react";
import {
	Box,
	Flex,
	Heading,
	HStack,
	Button,
	VStack,
	Text,
} from "@chakra-ui/react";

import { Formik } from "formik";
import { DealCustomizeData } from "../../types/deals.type";
import { DealsCustomizeForm } from "../../widgets/DealsCustomizeForm/DealsCustomizeForm";
import { mergeStyles } from "react-select";
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

const initialValues: DealCustomizeData = {
	backgroundColor: "#3182CE",
	backgroundTransparency: "0",
	fontColor: "#F9F9F9",
	fontSize: "1rem",
	highlightFontColor: "#DD5C64",
	borderRadius: "0",
	unStyled: true,
	placement: "top",
	container: "body",
	addCloseIcon: false,
	countryMessage: `Hello {country_flag} Hey! It looks like you are from <b>{country}</b>. We support Parity Purchasing Power, so if you need it, use code <b>“{coupon_code}”</b> to get <b>{discount_percentage}%</b> off your subscription at checkout.`,
	timeMessage: `Limited time offer! Use coupon code <b>“{coupon_code}”</b> to get <b>{discount_percentage}%</b> off at checkout`,
	holidayMessage: `Get <b>{discount_percentage}%</b> off during <b>{holiday}</b> sale! Use code <b>“{coupon_code}”</b> at checkout.`,
};

interface CreateDealsCustomizeProps {
	onNextClick: (formValues: DealCustomizeData) => void;
	onBackClick: () => void;
	defaultCustomizationValues?: DealCustomizeData;
	primaryButtonLabel?: string;
	isSubmitting?: boolean;
	showCountryMessage?: boolean;
	showHolidayMessage?: boolean;
	showTimeMessage?: boolean;
}

export const CreateDealsCustomize = ({
	onNextClick,
	onBackClick,
	defaultCustomizationValues,
	primaryButtonLabel = "Create Deal",
	isSubmitting = false,
	showCountryMessage = true,
	showHolidayMessage = true,
	showTimeMessage = true,
}: CreateDealsCustomizeProps) => {
	console.log("defaultCustomizationValues", defaultCustomizationValues);

	const getParsedMsg = React.useCallback((msg: string) => {
		return msg
			.replace("{discount_percentage}", "50")
			.replace("{country_flag}", "🇮🇳")
			.replace("{country}", "India")
			.replace("{holiday}", "Black Friday")
			.replace("{coupon_code}", "DFG56ED");
	}, []);

	return (
		<Flex h="100%" direction="column">
			<Formik
				initialValues={defaultCustomizationValues || initialValues}
				onSubmit={async (values: any, actions) => { }}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isValid,
				}) => (
					<>
						<VStack alignItems="start" w="100%" mb="10">
							<Heading as="h2" size="md" mb="2" isTruncated>
								Customize
							</Heading>
							<Text color="gray.500">
								Customize the banner style based on your website theme. You can
								also style it yourself using CSS.
							</Text>
						</VStack>
						<VStack
							alignItems="start"
							overflowY="auto"
							spacing="8"
							mb="86px"
							p="2px"
						>
							<Box w="100%">
								<Heading mr={3} flexShrink={0} mt="0" mb="6" size="md" as="h3">
									Sample banner style
								</Heading>
								<div
									style={{
										padding: "20px 10px",
										minWidth: '100%',
										backgroundColor: values.backgroundColor,
										color: values.fontColor,
										borderRadius: values.borderRadius,
										fontSize: values.fontSize,
										textAlign: "center",
										position: "relative",
									}}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: getParsedMsg(showCountryMessage ? values.countryMessage : showHolidayMessage ? values.holidayMessage:  showTimeMessage? values.timeMessage: ''),
										}}
									></div>
								</div>
							</Box>
							<DealsCustomizeForm showCountryMessage={showCountryMessage} showHolidayMessage={showHolidayMessage} showTimeMessage={showTimeMessage} />
						</VStack>
						<Box
							pos="absolute"
							w="100%"
							zIndex={2}
							display="flex"
							p="6"
							bottom="0"
							left="0"
							right="0"
							bg="white"
							borderTop="1px solid"
							borderColor="gray.100"
							justifyContent="space-between"
						>
							<HStack></HStack>
							<HStack>
								<Button onClick={onBackClick} variant="outline">
									Go back
								</Button>
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={isSubmitting}
									isDisabled={isSubmitting}
									onClick={() => {
										onNextClick(values);
									}}
								>
									{primaryButtonLabel}
								</Button>
							</HStack>
						</Box>
					</>
				)}
			</Formik>
		</Flex>
	);
};
