import * as React from "react";
import {
	Box,
	Button,
	Heading,
	Text,
	Switch,
	ListItem,
	List,
	ListIcon,
	HStack,
	Flex,
	useToast,
} from "@chakra-ui/react";

import { CurrentUser, User } from "../../services/appConfig";
import { getIntegrationsList } from "../../services/interationService";
import { CheckCircleBlueIcon } from "../../components/Icons/CheckCircleBlue";
import styles from "./SettingsSubscription.module.scss";
import { getPricingDiscounts, PricingDiscounts } from "../../services/commonServices";
import { manageSubscription, getCheckoutURL } from "../../services/paymentService";
import { priceIds } from "../../utils/paymentUtils";
import posthog from "posthog-js";
import { SubscriptionPlan,planNames } from "../../containers/SettingsUsagePage/SettingsUsagePage";

interface PricingInfo {
	discountPercentage: number;
}

const BASE_MONTHLY_PRICING = 19;
const BASE_YEARLY_PRICING = 15.8;
const STANDARD_MONTHLY_PRICING = 49;
const STANDARD_YEARLY_PRICING = 40.8;
const PREMIUM_MONTHLY_PRICING = 99;
const PREMIUM_YEARLY_PRICING = 82.5;

export const SettingsSubscription = () => {
	const [user, setUser] = React.useState<User>(CurrentUser.get());
	const [tier, setTire] = React.useState<string>("yearly");
	const [pricingData, setPricingData] = React.useState<PricingDiscounts>();
	const [pricingInfo, setPricingInfo] = React.useState<PricingInfo>();
    const [subscriptionId, setSubscriptionId] = React.useState('');

	const toast = useToast();
	const [isManageSubscriptionLoading, setIsManageSubscriptionLoading] = React.useState<boolean>(false);

	const [subscriptionLoadingType, setSubscriptionLoadingType] = React.useState<string>('none');
	const userPlan = user.plan as SubscriptionPlan;
  	const longPlanName = planNames[userPlan]?.longName ;
	const getDiscountedPrice = (basePrice:number, discount:number = 0) => {
		return basePrice - (basePrice * discount) / 100;
	};

	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);

	const getPricingMarkup = React.useCallback((type: string) => {
		//const discountPercentage = pricingInfo?.discountPercentage;
		const discountPercentage = 0;
		if(type === 'base') {
			let price = getDiscountedPrice(BASE_MONTHLY_PRICING, discountPercentage);
			let priceRounded = Math.ceil(price);
			let priceFraction =
				((price % 1).toFixed(2) + '').split('.')[1] || '0';
			if(tier === 'yearly') {
				price = getDiscountedPrice(BASE_YEARLY_PRICING, discountPercentage);
				priceRounded = Math.ceil(price);
				priceFraction =
					((price % 1).toFixed(2) + '').split('.')[1] || '0';
			}

			return <>
				{priceRounded} /mo 
				{discountPercentage || tier === 'yearly' ? <span className={styles.pricingBaseAmount}>{BASE_MONTHLY_PRICING} /mo</span> : ''}
				</>
			
		} else if(type === 'standard') {
			let price = getDiscountedPrice(STANDARD_MONTHLY_PRICING, discountPercentage);
			let priceRounded = Math.ceil(price);
			let priceFraction =
				((price % 1).toFixed(2) + '').split('.')[1] || '0';
			if(tier === 'yearly') {
				price = getDiscountedPrice(STANDARD_YEARLY_PRICING, discountPercentage);
				priceRounded = Math.ceil(price);
				priceFraction =
					((price % 1).toFixed(2) + '').split('.')[1] || '0';
			}

			return <>
				{priceRounded} /mo 
				{discountPercentage || tier === 'yearly' ? <span className={styles.pricingBaseAmount}>{STANDARD_MONTHLY_PRICING} /mo</span> : ''}
				</>
			
		} else if(type === 'premium') {
			let price = getDiscountedPrice(PREMIUM_MONTHLY_PRICING, discountPercentage);
			let priceRounded = Math.ceil(price);
			let priceFraction =
				((price % 1).toFixed(2) + '').split('.')[1] || '0';
			if(tier === 'yearly') {
				price = getDiscountedPrice(PREMIUM_YEARLY_PRICING, discountPercentage);
				priceRounded = Math.ceil(price);
				priceFraction =
					((price % 1).toFixed(2) + '').split('.')[1] || '0';
			}

			return <>
				{priceRounded} /mo 
				{discountPercentage || tier === 'yearly' ? <span className={styles.pricingBaseAmount}>{PREMIUM_MONTHLY_PRICING} /mo</span> : ''}
				</>
			
		}
		
	}, [tier]);

	React.useEffect(() => {
		async function fetchData() {
			try {
				const url = window.location.href;
				const response = await getPricingDiscounts(url);
				let data = response.data
				if(!data.discountPercentage) {
					data = {
						message: 'no discount avaialble',
						discountPercentage: '0',
					} as PricingDiscounts;
				}
				setPricingData(data);
				const discountPercentage = parseFloat(data.discountPercentage);
				
				setPricingInfo({
					discountPercentage,
				})
				console.log(data);
			} catch (error) {
				console.log("Unable to fetch pricing details", error);
			} finally {
			}
		}
		fetchData();
	}, []);
	React.useEffect(() => {
		async function fetchData() {
			try {
				const userData = CurrentUser.get();
				setUser(userData);
				setSubscriptionId(userData.subscriptionId);
				const integrationsResponse = await getIntegrationsList();
				console.log(integrationsResponse.data);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const getGumroadLink = React.useCallback((type) => {
		let subscriptionType = '';
		if(type === 'standard') {
			subscriptionType = '-standard'
		} else if(type === 'premium') {
			subscriptionType = '-premium'
		}
		return `https://neravat.gumroad.com/l/parity-deals-${tier}-subscription${subscriptionType}${pricingData?.couponCode ? '/'+pricingData?.couponCode :''}?wanted=true&user_id=${user.id}`
	}, [tier, user, pricingData])

	const onChangeTier = React.useCallback(
		(value) => {
			if (tier === "monthly") {
				setTire("yearly");
			} else {
				setTire("monthly");
			}
		},
		[tier]
	);

	const manageSubscriptions = React.useCallback(async () => {
		setIsManageSubscriptionLoading(true);
		try {
			const data = await manageSubscription();
			window.location.href = data.data.url;
		} catch (error) {
			toast({
				title: `Oops! Unable get your subscription details`,
				description: 'Please email at hi@paritydeals.com for further assistance',
				status: "error",
				isClosable: true,
			});
		} finally {
			setIsManageSubscriptionLoading(false);
		}
		
	}, [toast]);

	const manageCheckoutURL = React.useCallback(
		async (data, type) => { 
			setSubscriptionLoadingType(type);
			try {
				setIsManageSubscriptionLoading(type);
				const response = await getCheckoutURL(data);
				window.location.href = response.data.checkoutUrl;
			} catch (error) {
				toast({
					title: `Oops! Unable to get the subscription details`,
					description:
						"Please email at hi@paritydeals.com for further assistance",
					status: "error",
					isClosable: true,
				});
			} finally {
				setSubscriptionLoadingType("none");
			}
		},
		[toast]
	);

	const getButtonProps = (planType: string) => {
		if (longPlanName.includes('Premium')) {
			return {
				text: planType === 'premium' ? 'Current Plan' : 'Downgrade',
				disabled: planType === 'premium'
			};
		} else if (longPlanName.includes('Standard')) {
			return {
				text: planType === 'base' ? 'Downgrade' : planType === 'standard' ? 'Current Plan' : 'Upgrade Now',
				disabled: planType === 'standard'
			};
		} else {
			return {
				text: planType === 'base' ? 'Current Plan' : 'Upgrade Now',
				disabled: planType === 'base'
			};
		}
	};

	const premiumButtonProps = getButtonProps('premium');
	const standardButtonProps = getButtonProps('standard');
	const baseButtonProps = getButtonProps('base');

	const handleButtonClick = React.useCallback(async (priceType: string) => {
		setIsManageSubscriptionLoading(true); 
		try {
			if (subscriptionId.startsWith('sub_')) {
				await manageSubscriptions(); 
			} else {
				const priceId = priceIds[tier][priceType];
				await manageCheckoutURL({ price_id: priceId }, priceType); 
			}
		} catch (error) {
			console.error("Error handling button click:", error);
		} finally {
			setIsManageSubscriptionLoading(false); 
		}
	}, [tier, subscriptionId, manageSubscriptions, manageCheckoutURL]);
	
	const getFreeComponent = React.useCallback(() => {
		return (
			<Box minW="1088px" p="8" backgroundColor="white" boxShadow="sm" borderRadius="md">
				<Flex direction="column" alignItems="center" justifyContent="center" mb="12">
					<Heading mb="2" fontSize="large">
						You are currently using the {longPlanName} plan
					</Heading>
					<Text mb="6" color="gray.500" fontSize="medium">
						Upgrade to paid plans to supercharge your sales
					</Text>
					<Flex alignItems="center" fontWeight="500">
						<Switch
							defaultChecked
							onChange={onChangeTier}
							colorScheme="teal"
							size="lg"
							mr="2"
						/>
						Yearly
					</Flex>
					<Box mt="5">
						{pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
						} - Use coupon code <b>{pricingData.couponCode}</b> to get {pricingData?.discountPercentage.split('.')[0]}% off.</Box>:null}
					</Box>
				</Flex>
				<Box>
					<HStack spacing="4" alignItems="flex-end">
						<Box className={styles.pricingBlock}>
							<Text className={styles.pricingRange}>$0/mo</Text>
							<Text className={styles.pricingPlan}>Free</Text>
							<Text className={styles.pricingDesc}>
								Starting out with one product? Consider this option.
							</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>5k</b> monthly pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>1</b> product
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Unlimited banners
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Sales analytics
								</ListItem>
							</List>
							<Box mt="6">
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isDisabled
								>
									Current Plan
								</Button>
							</Box>
						</Box>
						<Box className={styles.pricingBlock}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								{getPricingMarkup('base')}
								</Text>
							</HStack>
							{/* {pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
                    } - Use coupon code {pricingData.couponCode} to get {pricingData?.discountPercentage.split('.')[0]} off.</Box>:null} */}
							
							<Text className={styles.pricingPlan}>Base</Text>
							<Text className={styles.pricingDesc}>
								For digital creators and small businesses
							</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>10k</b> monthly pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={subscriptionLoadingType === 'base'}
									onClick={()=>{
										manageCheckoutURL({"price_id": priceIds[tier]['base']}, 'base')
									}}
								>
									Upgrade Now
								</Button>
							</Box>
						</Box>
						<Box className={styles.pricingBlock + " " + styles.highlight}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								{getPricingMarkup('standard')}
								</Text>
							</HStack>
							{/* {pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
                    } - {pricingData?.discountPercentage.split('.')[0]}% off</Box>:null} */}
							
							<Text className={styles.pricingPlan}>Standard</Text>
							<Text className={styles.pricingDesc}>
							For small to medium sized businesses (SMBs)
							</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>100k</b> monthly pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={subscriptionLoadingType === 'standard'}
									onClick={()=>{
										manageCheckoutURL({"price_id": priceIds[tier]['standard']}, 'standard')
									}}
								>
									Upgrade Now
								</Button>
							</Box>
						</Box>
						<Box className={styles.pricingBlock}>
							<HStack justifyContent="space-between">
								<Text className={styles.pricingRange}>
								{getPricingMarkup('premium')}
								</Text>
							</HStack>
							{/* {pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
                    } - {pricingData?.discountPercentage.split('.')[0]}% off</Box>:null} */}
							
							<Text className={styles.pricingPlan}>Premium</Text>
							<Text className={styles.pricingDesc}>
							For SaaS businesses and enterprises
							</Text>
							<List spacing={3} className={styles.features}>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									<b>1M</b> monthly pricing page visits
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Auto refreshing coupons
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									VPN Protection
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									Remove powered by logo
								</ListItem>
								<ListItem>
									<ListIcon as={CheckCircleBlueIcon} color="green.500" />
									API support
								</ListItem>
							</List>
							<Box mt="6">
								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={subscriptionLoadingType === 'premium'}
									onClick={()=>{
										manageCheckoutURL({"price_id": priceIds[tier]['premium']}, 'premium')
									}}
								>
									Upgrade Now
								</Button>
							</Box>
						</Box>
					</HStack>
				</Box>
			</Box>
		);
	}, [longPlanName, onChangeTier, pricingData?.couponCode, pricingData?.countryFlag, pricingData?.country, pricingData?.discountPercentage, getPricingMarkup, subscriptionLoadingType, manageCheckoutURL, tier]);
	const getProComponent = React.useCallback(() => {
		return (
			<>
				<Box minW="1088px" p="8" backgroundColor="white" boxShadow="sm" borderRadius="md" mb="4" >
					<Heading mb="4" fontSize="large">
						You are currently using the {longPlanName} subscription
					</Heading>
					<Text mb="0" color="gray.500" fontSize="medium">
						Thank you for supporting ParityDeals
					</Text>
					<Text mb="8" color="gray.500" fontSize="medium">
						Please feel free to reach out to us at hi@paritydeals.com if you
						have any questions or feedback.
					</Text>
					<Button variant="outline" isLoading={isManageSubscriptionLoading} colorScheme="blue" onClick={() => {
						manageSubscriptions()
					}}>
						Manage subscription
					</Button>
				</Box>
				<Box minW="1088px" p="8" backgroundColor="white" boxShadow="sm" borderRadius="md">
					<Flex direction="column" alignItems="center" justifyContent="center" mb="12">
						{/* <Heading mb="2" fontSize="large">
							You are currently using the {longPlanName} plan
						</Heading> */}
						{/* <Text mb="6" color="gray.500" fontSize="medium">
							Upgrade to paid plans to supercharge your sales
						</Text> */}
						<Flex alignItems="center" fontWeight="500">
							<Switch
								defaultChecked
								onChange={onChangeTier}
								colorScheme="teal"
								size="lg"
								mr="2"
							/>
							Yearly
						</Flex>
						<Box mt="5">
							{pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
							} - Use coupon code <b>{pricingData.couponCode}</b> to get {pricingData?.discountPercentage.split('.')[0]}% off.</Box> : null}
						</Box>
					</Flex>
					<Box>
						<HStack spacing="4" alignItems="flex-end">
							<Box className={styles.pricingBlock}>
								<Text className={styles.pricingRange}>$0/mo</Text>
								<Text className={styles.pricingPlan}>Free</Text>
								<Text className={styles.pricingDesc}>
									Starting out with one product? Consider this option.
								</Text>
								<List spacing={3} className={styles.features}>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										<b>5k</b> monthly pricing page visits
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										<b>1</b> product
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Unlimited banners
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Sales analytics
									</ListItem>
								</List>
								<Box mt="6">
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"

									>
										Free Plan
									</Button>
								</Box>
							</Box>
							<Box className={styles.pricingBlock}>
								<HStack justifyContent="space-between">
									<Text className={styles.pricingRange}>
										{getPricingMarkup('base')}
									</Text>
								</HStack>
								{/* {pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
                    } - Use coupon code {pricingData.couponCode} to get {pricingData?.discountPercentage.split('.')[0]} off.</Box>:null} */}

								<Text className={styles.pricingPlan}>Base</Text>
								<Text className={styles.pricingDesc}>
									For digital creators and small businesses
								</Text>
								<List spacing={3} className={styles.features}>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										<b>10k</b> monthly pricing page visits
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Auto refreshing coupons
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										VPN Protection
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Remove powered by logo
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										API support
									</ListItem>
								</List>
								<Box mt="6">
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
										isLoading={subscriptionLoadingType === 'base'}
										onClick={() => handleButtonClick('base')}
										disabled={baseButtonProps.disabled}
									>
										{baseButtonProps.text}

									</Button>
								</Box>
							</Box>
							<Box className={styles.pricingBlock + " " + styles.highlight}>
								<HStack justifyContent="space-between">
									<Text className={styles.pricingRange}>
										{getPricingMarkup('standard')}
									</Text>
								</HStack>
								{/* {pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
                    } - {pricingData?.discountPercentage.split('.')[0]}% off</Box>:null} */}

								<Text className={styles.pricingPlan}>Standard</Text>
								<Text className={styles.pricingDesc}>
									For small to medium sized businesses (SMBs)
								</Text>
								<List spacing={3} className={styles.features}>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										<b>100k</b> monthly pricing page visits
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Auto refreshing coupons
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										VPN Protection
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Remove powered by logo
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										API support
									</ListItem>
								</List>
								<Box mt="6">
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
										isLoading={subscriptionLoadingType === 'standard'}
										onClick={() => handleButtonClick('standard')}
										disabled={standardButtonProps.disabled}
									>
										{standardButtonProps.text}
									</Button>
								</Box>
							</Box>
							<Box className={styles.pricingBlock}>
								<HStack justifyContent="space-between">
									<Text className={styles.pricingRange}>
										{getPricingMarkup('premium')}
									</Text>
								</HStack>
								{/* {pricingData?.couponCode ? <Box className={styles.pricingPromo}>{pricingData?.countryFlag} Special Pricing for {pricingData?.country
                    } - {pricingData?.discountPercentage.split('.')[0]}% off</Box>:null} */}

								<Text className={styles.pricingPlan}>Premium</Text>
								<Text className={styles.pricingDesc}>
									For SaaS businesses and enterprises
								</Text>
								<List spacing={3} className={styles.features}>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										<b>1M</b> monthly pricing page visits
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Auto refreshing coupons
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										VPN Protection
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										Remove powered by logo
									</ListItem>
									<ListItem>
										<ListIcon as={CheckCircleBlueIcon} color="green.500" />
										API support
									</ListItem>
								</List>
								<Box mt="6">
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
										isLoading={subscriptionLoadingType === 'premium'}
										onClick={() => handleButtonClick('premium')}
										disabled={premiumButtonProps.disabled}

									>										
										{premiumButtonProps.text}

									</Button>
								</Box>
							</Box>
						</HStack>
					</Box>
				</Box>
			</>
		);
	}, [baseButtonProps.disabled, baseButtonProps.text, getPricingMarkup, handleButtonClick, isManageSubscriptionLoading, longPlanName, manageSubscriptions, onChangeTier, premiumButtonProps.disabled, premiumButtonProps.text, pricingData?.country, pricingData?.countryFlag, pricingData?.couponCode, pricingData?.discountPercentage, standardButtonProps.disabled, standardButtonProps.text, subscriptionLoadingType]);

	return (
		<Box>
			{!user.isActiveSubscription ? getFreeComponent() : getProComponent()}
		</Box>
	);
};