import axios, { AxiosResponse } from "axios";
import { baseURL } from "../constants/app";
export async function manageSubscription(): Promise<AxiosResponse<any>> {
  return await axios({
    baseURL: baseURL,
    method: "get",
    url: "/pricing/manage-subscription/",
  });
}
export async function getCheckoutURL(data: any): Promise<AxiosResponse<any>> {
  return await axios({
    baseURL: baseURL,
    method: "post",
    data: data,
    url: "/pricing/stripe-checkout/",
  });
}
