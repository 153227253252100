import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Progress,
  Button,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/commonUtils";
import { SubscriptionPlan,planNames } from "../../containers/SettingsUsagePage/SettingsUsagePage";
import styles from './UsageBox.module.scss';

export interface UsageBoxProps {
  planName: SubscriptionPlan; 
  allowdQuota: number;
  usedQuota: number;
  islimitExceed: boolean;
}
export const UsageBox = ({
  planName,
  usedQuota,
  allowdQuota,
}: UsageBoxProps) => {
  const [progressValue, setProgressValue] = useState(
    (usedQuota * 100) / allowdQuota
  );
  const [islimitExceed, setIsLimitExcceded] = useState(
    usedQuota >= allowdQuota
  );
 
  useEffect(() => {
    setProgressValue((usedQuota * 100) / allowdQuota);
    setIsLimitExcceded(usedQuota >= allowdQuota);
  }, [usedQuota, allowdQuota]);

  const shortPlanName = planNames[planName]?.shortName ;
  const isFreePlan = shortPlanName === "Free";

  return (
    <Box
      bg={islimitExceed ?'red.50':"whiteAlpha.50"}
      border={"2px solid"}
      borderColor={islimitExceed ? "red.500" :"purple.400"} 
      borderRadius="8"
      mr="24px"
      boxSizing={"border-box"}
      display={"flex"}
      flexDirection={"column"}
      padding={"16px"}
    >
      <Stack alignContent={"center"} >
        <Box>
          <Heading fontSize="20px"  color="grey.700" fontWeight="600">
            {shortPlanName}
          </Heading>
          <HStack spacing={"1"} mt="8px">
            <Text fontSize="16px" fontWeight="600" color={islimitExceed ? "red":"blackAlpha"}>
            {formatNumber(usedQuota )}
            </Text>
            <Text as="span">/ </Text>
            <Text fontSize="16px"> {formatNumber(allowdQuota)}</Text>
          </HStack>
          <Text fontSize="14px"  mb="3">
            Pricing page visits limit
          </Text>
          <Progress
            colorScheme={islimitExceed ? "red" : "purple"}
            size="sm"
            value={progressValue}
            borderRadius={"5"}
          />
        </Box>
      </Stack>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Link to="/app/settings/subscription/">
        {(islimitExceed || isFreePlan) && ( <Button
          bg="blackAlpha.900"
          _hover={{ bg: "blackAlpha.900" }}
          _active={{ bg: "blackAlpha.900" }}
          colorScheme="blackAlpha"
          variant="solid"
          fontSize="14px"
          size="sm"
          mt="6"
        >
          Upgrade plan
        </Button> )}
        </Link>
        <Link to="/app/settings/api-usage/">
        <Text fontSize="14px" mt="22px" className={styles.usageBox}>
          See usage
        </Text>
        </Link>
      </Box>
    </Box>
  );
};
