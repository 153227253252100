import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}
export const LemonSqueezyColorIcon = ({
  width = 20,
  height = 20,
  fill,
  ...restProps
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      {...restProps}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#4E26DE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8822 10.8886L14.5486 12.5836C15.0031 12.7938 15.3238 13.1466 15.497 13.5512C15.9352 14.5759 15.3363 15.6239 14.3963 16.0009C13.4561 16.3776 12.4541 16.1351 11.9985 15.0695L10.4029 11.3284C10.2792 11.0384 10.5898 10.7534 10.8822 10.8886Z"
        fill="#FFC233"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.102 9.79131L14.8867 8.36064C16.1445 7.88516 17.5185 8.78481 17.5 10.092C17.4997 10.1091 17.4994 10.1261 17.499 10.1433C17.4718 11.4163 16.136 12.2718 14.9058 11.8215L11.1056 10.4306C10.8025 10.3197 10.8002 9.90538 11.102 9.79131Z"
        fill="#FFC233"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8901 9.29573L14.6106 7.71487C15.8469 7.18949 16.1606 5.61267 15.1924 4.70159C15.1797 4.68959 15.167 4.67773 15.1541 4.66588C14.2048 3.78473 12.6355 4.09497 12.095 5.25558L10.4255 8.84134C10.2923 9.12728 10.5934 9.42176 10.8901 9.29573Z"
        fill="#FFC233"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.93253 8.67107L11.2852 4.96214C11.4529 4.50226 11.4218 4.03327 11.2484 3.62863C10.8094 2.60435 9.62043 2.27372 8.68053 2.65119C7.74078 3.02881 7.20976 3.88639 7.66626 4.95158L9.27233 8.68869C9.39687 8.97829 9.82471 8.96687 9.93253 8.67107Z"
        fill="#FFC233"
      />
    </svg>
  );
};
