import axios, { AxiosResponse } from 'axios';
import { SignUp, GumroadAPI, PaddleAuthData, LemonSqueezyAuthData, WhopAuthData, ChargebeeAuthData } from '../types/types';
import { baseURL, baseURLAPI } from '../constants/app';
export async function signUp(payload: {
	email: string;
	password: string;
}): Promise<AxiosResponse<SignUp>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		url: '/user/sign-up/',
		data: payload,
	});
}
export async function logIn(payload: {
	email: string;
	password: string;
}): Promise<AxiosResponse<SignUp>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		url: '/user/sign-in/',
		data: payload,
	});
}
export async function authGoogle(payload: {
    access_token: string;
}): Promise<AxiosResponse<any>> {
    return await axios({
        baseURL: baseURLAPI,
        method: 'post',
        url: '/auth/google/',
        data: payload,
    });
}
export async function forgotPassword(payload: {
	email: string;
}): Promise<AxiosResponse<{
	message: string
}>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		url: '/user/forget-password/',
		data: payload,
	});
}
export async function resetPassword(payload: {
	password: string;
}, id: string): Promise<AxiosResponse<SignUp>> {
	console.log('payload', payload)
	return await axios({
		baseURL: baseURL,
		method: 'post',
		url: `/user/reset-password/?id=${id}`,
		data: payload,
	});
}
export async function connectWithGumroad(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/gumraod-auth-url/',
	});
}
export async function getGumroadAuthUrl(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/gumraod/',
	});
}
export async function getStripeAuthUrl(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/stripe/',
	});
}
export async function getPaddleAuthUrl(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/paddle/',
	});
}
export async function authenticateWithPaddle(payload: PaddleAuthData): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		data: payload,
		url: '/integrations/paddle/',
	});
}
export async function getLemonSqueezyAuthUrl(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/lemon-squeezy/',
	});
}
export async function authenticateWithLemonSqueezy(payload: LemonSqueezyAuthData): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		data: payload,
		url: '/integrations/lemon-squeezy/',
	});
}

export async function getWhopAuthUrl(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/whop/',
	});
}
export async function authenticateWithWhop(payload: WhopAuthData): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		data: payload,
		url: '/integrations/whop/',
	});
}

export async function getChargebeeAuthUrl(): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/integrations/chargebee/',
	});
}
export async function authenticateWithChargebee(payload: ChargebeeAuthData): Promise<AxiosResponse<GumroadAPI>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		data: payload,
		url: '/integrations/chargebee/',
	});
}
