import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
}
export const StripeColorIcon = ({
  width = 102,
  height = 102,
  ...restProps
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 102 102"
      {...restProps}
    >
      <g filter="url(#filter0_dd_6692_28533)">
        <rect  width="102" height="102" rx="24" fill="#625BF6" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.0836 37.2536C47.0836 34.5817 49.2681 33.5541 52.8861 33.5541C58.0743 33.5541 64.6277 35.1298 69.8159 37.9387V21.8389C64.1499 19.5781 58.5521 18.6875 52.8861 18.6875C39.0283 18.6875 29.8125 25.9495 29.8125 38.0757C29.8125 56.9844 55.7533 53.9699 55.7533 62.1226C55.7533 65.274 53.0227 66.3017 49.1998 66.3017C43.5338 66.3017 36.2977 63.9724 30.5634 60.8209V77.1262C36.9121 79.8666 43.329 81.0312 49.1998 81.0312C63.399 81.0312 73.1609 73.9748 73.1609 61.7115C73.0926 41.2957 47.0836 44.9267 47.0836 37.2536Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_dd_6692_28533"
          x="0"
          y="0"
          width="102"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6692_28533"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_6692_28533"
            result="effect2_dropShadow_6692_28533"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_6692_28533"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
