import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const ArrowLeftIcon = ({
	width = 18,
	height = 18,
	...restProps
}: IconProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 9 16'
			{...restProps}
		>
			<path
				d='M7.40412 15.0349C7.08578 15.0349 6.76578 14.9132 6.52245 14.6699L0.710783 8.88654C0.475783 8.65154 0.344116 8.33321 0.344116 7.99987C0.344116 7.66821 0.475783 7.34987 0.710783 7.11487L6.52245 1.32821C7.01078 0.841541 7.80078 0.841541 8.28912 1.33154C8.77578 1.82154 8.77412 2.61321 8.28578 3.09987L3.36412 7.99987L8.28578 12.8999C8.77412 13.3865 8.77578 14.1765 8.28912 14.6665C8.04578 14.9132 7.72412 15.0349 7.40412 15.0349'
				fill='#4A5568'
			/>
		</svg>
	);
};
