import * as React from 'react';
import {
	Box,
	Flex,
	Heading,
	HStack,
	Text,
	Button,
	VStack,
} from '@chakra-ui/react';

import { useEffect } from 'react';
import { CountryListItem } from '../../components/CountryListItem/CountryListItem';
import {
	HolidayCategoryDiscount,
	getHolidays,
} from '../../services/commonServices';
import { HolidayDiscount, IntegratedPlatform } from '../../types/deals.type';
import {
	HolidayListItem,
	HolidayListItemInputType,
} from '../../components/HolidayListItem/HolidayListItem';
import { DealPlatform } from '../../types/types';

interface DealsHolidaysCouponSetupProps {
	onNextClick: (holidays: HolidayCategoryDiscount[]) => void;
	onBackClick: () => void;
	platform: DealPlatform;
	existingHolidays?: HolidayCategoryDiscount[];
	isSubmitting?:boolean;
	primaryButtonLabel?:string;
	integratedPlatform?: IntegratedPlatform[];
}
export const DealsHolidaysCouponSetup = ({
	onNextClick,
	onBackClick,
	platform,
	existingHolidays,
	isSubmitting = false,
	primaryButtonLabel = 'Next',
	integratedPlatform
}: DealsHolidaysCouponSetupProps) => {
	const [holidays, setHolidays] = React.useState<HolidayCategoryDiscount[]>(
		[]
	);

	useEffect(() => {
		async function fetchData() {
			try {
				if (existingHolidays && existingHolidays.length) {
					setHolidays(existingHolidays);
				} else {
					const response = await getHolidays();
					console.log(response.data);
					const defaultDiscounts: HolidayCategoryDiscount[] =
						response.data.map((category) => {
							return {
								holiday: category.holiday,
								couponCode: '',
								startBefore: '7',
								endAfter: '3',
								startDate: category.startDate,
								endDate: category.endDate,
								discountPercentage: '40',
							};
						});
					setHolidays(defaultDiscounts);
				}
			} catch (error) {
				console.log('Unable to fetch note', error);
			} finally {
			}
		}
		fetchData();
	}, [existingHolidays]);

	const handleInputChange = React.useCallback(
		(
			type: HolidayListItemInputType,
			value: string,
			holiday: HolidayCategoryDiscount
		) => {
			const discountsObject = holidays?.find((discount) => {
				console.log(discount, holiday);
				return discount.holiday === holiday.holiday;
			});
			if (!discountsObject) return;
			if (type === 'discountPercentage') {
				discountsObject.discountPercentage = value;
			} else if (type === 'coupon') {
				discountsObject.couponCode = value;
			} else if (type === 'endAfter') {
				discountsObject.endAfter = value;
			} else {
				discountsObject.startBefore = value;
			}
			console.log('discountsObject', discountsObject);
			setHolidays([...(holidays || [])]);
		},
		[holidays]
	);

	const getPageHeader = React.useCallback(() => {
		if(platform === 'manual'){
			return ''
		}
		return <>
				We'll automatically create coupons on your <span style={{textTransform: 'capitalize'}}>{platform}</span> account
				<br />
			</>
	}, [platform])

	return (
		<Flex h='100%' direction='column'>
			<VStack alignItems='start' w='100%' mb='10'>
				<Heading as='h2' size='md' mb='2' isTruncated>
					Set up coupons for holiday based discounts
				</Heading>
				<Text color='gray.500'>
					{getPageHeader()}
					Leave the discount field blank if you don't want to display deals for any particular holiday.
					<br />
				</Text>
			</VStack>
			<VStack overflowY='auto' spacing='4' mb='86px' p='2px'>
				{holidays?.map((holiday) => {
					return (
						<HolidayListItem
							onChange={(
								type: HolidayListItemInputType,
								value: string
							) => {
								handleInputChange(type, value, holiday);
							}}
							disableCoupon={!!integratedPlatform?.length}
							defaultEndAfter={holiday.endAfter}
							defaultStartBefore={holiday.startBefore}
							holiday={holiday}
							defaultCouponCode={holiday.couponCode}
							defaultDiscount={holiday.discountPercentage}
							key={holiday.holiday}
						></HolidayListItem>
					);
				})}
			</VStack>
			<Box
				pos='absolute'
				w='100%'
				zIndex={2}
				display='flex'
				p='6'
				bottom='0'
				left='0'
				right='0'
				bg='white'
				borderTop='1px solid'
				borderColor='gray.100'
				justifyContent='space-between'
			>
				<HStack></HStack>
				<HStack>
					<Button onClick={onBackClick} variant='outline'>
						Go back
					</Button>
					<Button
						bg='blackAlpha.900'
						_hover={{ bg: 'blackAlpha.900' }}
						_active={{ bg: 'blackAlpha.900' }}
						colorScheme='blackAlpha'
						variant='solid'
						isLoading={isSubmitting}
						isDisabled={isSubmitting}
						onClick={() => {
							onNextClick(holidays as HolidayCategoryDiscount[]);
						}}
					>
						{primaryButtonLabel}
					</Button>
				</HStack>
			</Box>
		</Flex>
	);
};
