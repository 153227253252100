import * as React from "react";
import {
	Heading,
	HStack,
	Button,
	VStack,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	FormHelperText,
	Box,
	Flex,
	Tag,
	Text,
	TagCloseButton,
	TagLabel,
	Textarea,
	Checkbox,
	SimpleGrid,
	Link,
} from "@chakra-ui/react";

import { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { TargetedPage } from "../../types/deals.type";
import { FiArrowUpRight } from "react-icons/fi";
import styles from './DealsProductSetup.module.scss'

interface FormValues {
	name: string;
	website: string;
	target: string;
	description: string;
}
export interface ProductSetupFormValues {
	name: string;
	description: string;
	website: string;
	targetPagesBar: TargetedPage[];
	isLocationDealsSelected: boolean;
	isHolidayDealsSelected: boolean;
	isTimeDealsSelected : boolean;
}

interface DealsProductSetupProps {
	onPrimaryBtnClick: (finalFormValues: ProductSetupFormValues) => void;
	onSecondaryBtnClick: () => void;
	primaryButtonLabel?: string;
	secondaryBtnLabel?: string;
	showSecondaryButton?: boolean;
	defaultExcludedPaths?: string[];
	defaultIncludedPaths?: string[];
	defaultProductName?: string;
	defaultProductDescription?: string;
	defaultWebsite?: string;
	showDescription?: boolean;
	defaultLocationDealsSelected?:boolean;
	defaultHolidayDealsSelected?:boolean;
	defaultTimeDealsSelected?:boolean;
	showSelectDealType?:boolean;
	showTimeBasedDealType?:boolean;
	isSubmitting?: boolean;
	disableSubmitBtnByDefault?: boolean;
}

function validateWebsite(value: string) {
	let error;
	const urlvalidate = /^(https?:\/\/)(www\.)?[^\/\s]+\/?$/;
	const localhostRegex =/^http(s)?\:\/\/(?!localhost[:\/])[^\/]+(\/.*)*$/;

	if (!value) {
	  error = "Please enter a website URL";
	} else if (!urlvalidate.test(value)) {
	  error = "Invalid URL. Please include 'http://' or 'https://' any additional paths or parameters.";
	} else if (!localhostRegex.test(value)) {
		error = (
      <Text>
        Please enter a publicly available URL.
        <Link
          target="_blank"
          fontWeight="semibold"
          href="https://www.paritydeals.com/docs/discount-banner/configure-banner/#testing-the-banner-locally"
        >
          <Box marginLeft="5px" display="inline-flex" alignItems="flex-end">
            View Docs <FiArrowUpRight />
          </Box>
        </Link>
      </Text>
    );
	}

	return error;
  }


  function validateTargetedPage(value: string) {
    let error;

    const targetedpagevalidate = /^\/[^?#\s]*(\?[^#\s]*)?$/;
   
	if (value && !targetedpagevalidate.test(value)) {
        error = "Please enter correct targeted page format.";
    }

    return error;
}

export const DealsProductSetup = ({
	onPrimaryBtnClick,
	onSecondaryBtnClick,
	defaultWebsite = "",
	primaryButtonLabel = "Next",
	secondaryBtnLabel = "Go Back",
	showSecondaryButton = false,
	disableSubmitBtnByDefault = false,
	defaultProductName = "",
	defaultProductDescription = "",
	defaultExcludedPaths,
	defaultIncludedPaths,
	isSubmitting = false,
	defaultLocationDealsSelected= true,
	defaultHolidayDealsSelected= false,
	defaultTimeDealsSelected= false,
	showTimeBasedDealType = false,
	showSelectDealType = false,
	showDescription = false,
}: DealsProductSetupProps) => {
	const [excludedPaths, setExcludedPaths] = React.useState<string[]>(
		defaultExcludedPaths || []
	);
	const [includedPaths, setIncludedPaths] = React.useState<string[]>(
		defaultIncludedPaths || []
	);

	const [isLocationDealsSelected, setIsLocationDealsSelected] = React.useState<boolean>(defaultLocationDealsSelected);
	const [isHolidayDealsSelected, setIsHolidayDealsSelected] = React.useState<boolean>(defaultHolidayDealsSelected);
	const [isTimeDealsSelected, setIsTimeDealsSelected] = React.useState<boolean>(defaultTimeDealsSelected);

	useEffect(() => {
		console.log("defaultProductName", defaultProductName, defaultWebsite);
	}, [defaultProductName, defaultWebsite]);

	const onNextButtonClick = React.useCallback(
		({ name, website, description, target }: FormValues) => {
			const formValues: ProductSetupFormValues = {} as ProductSetupFormValues;
			formValues.name = name;
			formValues.description = description;
			formValues.website = website.toLowerCase();
			const includedTarget: TargetedPage[] = includedPaths.map((path) => {
				return { action: "include", path: path };
			});
			const excludedTarget: TargetedPage[] = excludedPaths.map((path) => {
				return { action: "exclude", path: path };
			});
			formValues.targetPagesBar = [...includedTarget, ...excludedTarget];
			if((!formValues.targetPagesBar || !formValues.targetPagesBar.length) && target) {
				formValues.targetPagesBar = [{ action: "include", path: target }]
			}
			formValues.isHolidayDealsSelected = isHolidayDealsSelected;
			formValues.isLocationDealsSelected = isLocationDealsSelected;
			formValues.isTimeDealsSelected = isTimeDealsSelected;

			onPrimaryBtnClick(formValues);
		},
		[includedPaths, excludedPaths, isHolidayDealsSelected, isLocationDealsSelected, isTimeDealsSelected, onPrimaryBtnClick]
	);
	const onExcludePath = React.useCallback(
		(path: string) => {
			setExcludedPaths([...excludedPaths, path]);
		},
		[excludedPaths]
	);
	const onIncludePath = React.useCallback(
		(path: string) => {
			if (path) {
				setIncludedPaths([...includedPaths, path]);
			}
		},
		[includedPaths]
	);
	const onRemoveExcludePath = React.useCallback(
		(path: string) => {
			const updatedItems = excludedPaths.filter(function (item) {
				return item !== path;
			});

			setExcludedPaths(updatedItems);
		},
		[excludedPaths]
	);
	const onRemoveIncludePath = React.useCallback(
		(path: string) => {
			const updatedItems = includedPaths.filter(function (item) {
				return item !== path;
			});

			setIncludedPaths(updatedItems);
		},
		[includedPaths]
	);

	const handleInclusePathOnEnter = React.useCallback(
		(event: any, values) => {
			if (event.key === "Enter") {
				onIncludePath(values.target);
				values.target = "";
			}
		},
		[onIncludePath]
	);

	return (
		<Flex h="100%" direction="column">
			<Formik
				initialValues={{
					name: defaultProductName,
					description: defaultProductDescription,
					website: defaultWebsite,
					target: "",
				}}
				onSubmit={async () => { }}
			>
				{({ values, isValid, dirty }) => (
					<>
						<VStack alignItems="start" w="100%" mb="6">
							<Heading as="h2" size="md" isTruncated>
								Product setup
							</Heading>
						</VStack>
						<VStack
							alignItems="start"
							overflowY="auto"
							spacing="4"
							p="2px"
							mb="86px"
						>
							<Form>
								<HStack spacing="8" alignItems="start">
									<Box w="50%">
										<Field type="text" name="name">
											{({ field, form }: any) => (
												<FormControl
													mb="6"
													isInvalid={form.errors.name && form.touched.name}
													isRequired
												>
													<FormLabel htmlFor="name">Product name</FormLabel>
													<Input {...field} id="name" placeholder="" />
													<FormErrorMessage>
														{form.errors.name}
													</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Field type="text" name="description">
											{({ field, form }: any) => (
												<FormControl
													mb="6"
													isInvalid={
														form.errors.description && form.touched.description
													}
												>
													<FormLabel htmlFor="description">
														Product Description
													</FormLabel>
													<Textarea
														{...field}
														id="description"
														placeholder=""
													></Textarea>
													<FormErrorMessage>
														{form.errors.description}
													</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</Box>
									<Box w="50%">
										<Field
											type="text"
											name="website"
											validate={validateWebsite}
										>
											{({ field, form }: any) => (
												<FormControl isRequired mb="6"
													isInvalid={form.errors.website && form.touched.website}
												>
													<FormLabel htmlFor="website">
														Enter your website URL
													</FormLabel>
													<Input
														{...field}
														id="website"
														required
														placeholder="https://www.paritydeals.com"
													/>
													<FormHelperText fontSize="smaller" color="orange.500">
														Include the protocol (http/https) and 'www' if used, without any additional path.
													</FormHelperText>
													{form.touched.website && form.errors.website && (
														<FormHelperText color="red">
															{form.errors.website}
														</FormHelperText>
													)}
												</FormControl>
											)}
										</Field>
										<Field type="text" name="target"
											validate={validateTargetedPage}
											>
											{({ field, form }: any) => (
												<FormControl
													mb="6"
													isInvalid={form.errors.target && form.touched.target}
												>
													<FormLabel htmlFor="target">
														Enter targeted page
													</FormLabel>
													<Input
														{...field}
														id="target"
														onKeyDown={(event) => {
															handleInclusePathOnEnter(event, values);
														}}
														placeholder="/pricing"
													/>
													<FormHelperText fontSize="smaller" color="orange.500">
														Add only the path after the domain. For, e.g.,
														/pricing. Targeting pages only works on include
														path.
													</FormHelperText>
													<FormHelperText fontSize="smaller" color="orange.500">
														Press Enter or click on the include button to add more targeted pages
													</FormHelperText>
													
													{form.touched.target && form.errors.target && (
														<FormErrorMessage color="red">
														{form.errors.target}
														</FormErrorMessage>
													)}
													
												</FormControl>
											)}
										</Field>
										<HStack mt="2" mb="2">
											<Button
												disabled={!values.target}
												size="xs"
												variant="outline"
												onClick={() => {
													onIncludePath(values.target);
													values.target = "";
												}}
											>
												Include path
											</Button>
											{/* <Button
										disabled={!values.target}
										size='xs'
										variant='outline'
										onClick={() => {
											onExcludePath(values.target);
											values.target = '';
										}}
									>
										Exclude path
									</Button> */}
										</HStack>
										{includedPaths.length ? (
											<>
												<Heading mb="3" mt="6" as="h5" fontSize="sm">
													Included path{" "}
												</Heading>
												<Flex mb="6" flexWrap="wrap">
													{includedPaths.map((path) => (
														<Tag
															size="sm"
															key={path}
															borderRadius="full"
															variant="outline"
															colorScheme="gray"
															flexShrink={0}
															marginBottom={"2"}
															marginRight={"2"}
														>
															<TagLabel>{path}</TagLabel>
															<TagCloseButton
																onClick={() => {
																	onRemoveIncludePath(path);
																}}
															/>
														</Tag>
													))}
												</Flex>
											</>
										) : null}

										{excludedPaths.length ? (
											<>
												<Heading mb="3" mt="6" as="h5" fontSize="sm">
													Excluded path{" "}
												</Heading>
												<HStack mb="6" spacing={4}>
													{excludedPaths.map((path) => (
														<Tag
															size="sm"
															key={path}
															borderRadius="full"
															variant="outline"
															colorScheme="blackAlpha"
														>
															<TagLabel>{path}</TagLabel>
															<TagCloseButton
																onClick={() => {
																	onRemoveExcludePath(path);
																}}
															/>
														</Tag>
													))}
												</HStack>
											</>
										) : null}
									</Box>
								</HStack>
								{showSelectDealType ? <Box p="1" mt="4">
									<Heading size="md" mb="2">
										Select deal types
									</Heading>
									<Text color="gray.500" fontSize="xs" mb="6">You can always add more deal types later if you wish</Text>
									<SimpleGrid columns={[1, 1, 1, 4]} spacing="3">
										<Box
											p="4"
											border="1px solid"
											borderColor="gray.400"
											borderRadius="md"
											className={isLocationDealsSelected ? styles.selectedDealType: ''}
										>
											<Flex justifyContent="space-between" mb="2">
												<Heading size="sm">Location deals</Heading>
												<Checkbox
													size="lg"
													colorScheme="blue"
													isDisabled={isLocationDealsSelected && !isHolidayDealsSelected && !isTimeDealsSelected}
													onChange={(e) => setIsLocationDealsSelected(e.target.checked)}
													defaultIsChecked={defaultLocationDealsSelected}
												></Checkbox>
											</Flex>
											<Text color="gray.700" fontSize="sm">
												Provide discounts by countries to support Purchasing Power Parity
											</Text>
										</Box>
										<Box
											p="4"
											border="1px solid"
											borderColor="gray.400"
											borderRadius="md"
											className={isHolidayDealsSelected ? styles.selectedDealType: ''}
										>
											<Flex justifyContent="space-between" mb="2">
												<Heading size="sm">Holiday deals</Heading>
												<Checkbox
													size="lg"
													colorScheme="blue"
													isDisabled={isHolidayDealsSelected && !isLocationDealsSelected && !isTimeDealsSelected}
													onChange={(e) => setIsHolidayDealsSelected(e.target.checked)}
													defaultIsChecked={defaultHolidayDealsSelected}
												></Checkbox>
											</Flex>
											<Text color="gray.700" fontSize="sm">
												Provide discount by festival, holidays, occasions and more
											</Text>
										</Box>
										{showTimeBasedDealType ? <Box
											p="4"
											border="1px solid"
											borderColor="gray.400"
											borderRadius="md"
											className={isTimeDealsSelected ? styles.selectedDealType: ''}
										>
											<Flex justifyContent="space-between" mb="2">
												<Heading size="sm">Time-based flash deals</Heading>
												<Checkbox
													size="lg"
													colorScheme="blue"
													isDisabled={isTimeDealsSelected && !isLocationDealsSelected && !isHolidayDealsSelected}
													onChange={(e) => setIsTimeDealsSelected(e.target.checked)}
													defaultIsChecked={defaultTimeDealsSelected}
												></Checkbox>
											</Flex>
											<Text color="gray.700" fontSize="sm">
												Set discounts that start from one point and decrease by a specific duration.
											</Text>
										</Box>:null}
										
									</SimpleGrid>
								</Box>:null}
								
							</Form>
						</VStack>
						<Box
							pos="absolute"
							w="100%"
							zIndex={2}
							display="flex"
							p="6"
							bottom="0"
							left="0"
							right="0"
							bg="white"
							borderTop="1px solid"
							borderColor="gray.100"
							justifyContent="space-between"
						>
							<HStack></HStack>
							<HStack>
								{showSecondaryButton ? (
									<Button onClick={onSecondaryBtnClick} variant="outline">
										{secondaryBtnLabel}
									</Button>
								) : null}

								<Button
									bg="blackAlpha.900"
									_hover={{ bg: "blackAlpha.900" }}
									_active={{ bg: "blackAlpha.900" }}
									colorScheme="blackAlpha"
									variant="solid"
									isLoading={isSubmitting}
									disabled={
										isSubmitting || disableSubmitBtnByDefault
											? !(isValid && dirty) || !values.name
											: !isValid || !values.name
									}
									onClick={() => {
										onNextButtonClick(values);
									}}
								>
									{primaryButtonLabel}
								</Button>
							</HStack>
						</Box>
					</>
				)}
			</Formik>
		</Flex>
	);
};
