import * as React from "react";
import {
	Box,
	Button,
	Flex,
	Heading,
	Text,
	useToast,
	Link,
	Switch,
	HStack,
	SimpleGrid,
	Skeleton,
	Alert,
	AlertIcon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	AlertDialog, 
	AlertDialogBody, 
	AlertDialogFooter, 
	AlertDialogHeader, 
	AlertDialogContent, 
	AlertDialogOverlay
} from "@chakra-ui/react";

import posthog from 'posthog-js';

import { GumroadColorIcon } from "../../components/Icons/GumroadColor";
import {
	disableIntegration,
	getIntegrationsList,
	Integration,
} from "../../services/interationService";
import { GumroadIcon } from "../../components/Icons/Gumroad";
import { authenticateWithPaddle } from "../../services/authServices";
import { StripeIcon } from "../../components/Icons/Stripe";
import { StripeColorIcon } from "../../components/Icons/StripeColor";
import { PaddleColorIcon } from "../../components/Icons/PaddleColor";
import { PaddleAuthFormForm } from "../../widgets/PaddleAuthForm/PaddleAuthForm";
import { PaddleAuthData } from "../../types/types";
import {LemonsqueezyAuthData} from "../../types/types";
import { LemonSqueezyColorIcon } from "../../components/Icons/LemonSqueezy";
import { authenticateWithLemonSqueezy} from "../../services/authServices";
import { LemonSqueezyAuthForm } from "../../widgets/LemonSqueezyAuthForm/LemonSqueezyAuthForm";
import { authenticateWithWhop} from "../../services/authServices";
import {WhopColorIcon} from "../../components/Icons/Whop";
import {WhopAuthData} from "../../types/types";
import { WhopAuthForm } from "../../widgets/WhopAuthForm/WhopAuthForm";
import { ChargebeeAuthData } from "../../types/types";
import { ChargebeeColorIcon } from "../../components/Icons/Chargebee";
import { authenticateWithChargebee } from "../../services/authServices";
import { ChargebeeAuthForm } from "../../widgets/ChargebeeAuthForm/ChargebeeAuthForm";
import { useRef, useState } from "react";

export const SettingsIntegrations = () => {
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [currentIntegration, setCurrentIntegration] = useState<{integration: Integration, platform: string} | null>(null);
  	const cancelRef = useRef(null);
	const toast = useToast();
	const [integrationsList, setIntegrationsList] =
		React.useState<Integration[]>();
	const [isLoading, setIsLoading] =
		React.useState<boolean>();
	
	const [isWhopSubmitting, setIsWhopSubmitting] = React.useState<boolean>(false);

	const { isOpen: isWhopOpen, onOpen: onWhopOpen, onClose: onWhopClose } = useDisclosure();
	const initialWhopRef = React.useRef(null);

	const connectWithWhop = React.useCallback(async (values: WhopAuthData) => {
		setIsWhopSubmitting(true);
		try {
			await authenticateWithWhop({
				apiKey: values.apiKey,
			});
			toast({
				title: `Successfully connected with Whop`,
				status: "success",
				isClosable: true,
			});
			const lists = [...(integrationsList || [])];
			const getIntegrationItem = lists?.find(list => list.platform === 'whop');
			if (getIntegrationItem) {
				getIntegrationItem.status = true;
			}
			setIntegrationsList(lists)
		} catch (error) {
			toast({
				title: "Unable to connect with Whop",
				description: "Make sure you have at least one product on your Whop account",
				status: "error",
				isClosable: true,
			});
		} finally {
			setIsWhopSubmitting(false);
			onWhopClose();
		}
	}, [integrationsList, onWhopClose, toast]);

	const [isLemonSqueezySubmitting, setIsLemonSqueezySubmitting] = React.useState<boolean>(false);
	
	
	const { isOpen: isLemonSqueezyOpen, onOpen: onLemonSqueezyOpen, onClose: onLemonSqueezyClose } = useDisclosure();
	const initialLemonSqueezyRef = React.useRef(null);

	const connectWithLemonSqueezy = React.useCallback(async (values: LemonsqueezyAuthData) => {
		setIsLemonSqueezySubmitting(true);
		try {
			await authenticateWithLemonSqueezy({
				apiKey: values.apiKey,
			});
			toast({
				title: `Successfully connected with lemonsqueezy`,
				status: "success",
				isClosable: true,
			});
			const lists = [...(integrationsList || [])];
			const getIntegrationItem = lists?.find(list => list.platform === 'lemonsqueezy');
			if (getIntegrationItem) {
				getIntegrationItem.status = true;
			}
			setIntegrationsList(lists)
		} catch (error) {
			toast({
				title: "Unable to connect with Lemon Squeezy",
				description: "Make sure you have at least one product on your LemonSqueezy account",
				status: "error",
				isClosable: true,
			});
		} finally {
			setIsLemonSqueezySubmitting(false);
			onLemonSqueezyClose();
		}
	}, [integrationsList, onLemonSqueezyClose, toast]);

	const [isChargebeeSubmitting, setIsChargebeeSubmitting] = React.useState<boolean>(false);

	const { isOpen: isChargebeeOpen, onOpen: onChargebeeOpen, onClose: onChargebeeClose } = useDisclosure();
	const initialChargebeeRef = React.useRef(null);

	const connectWithChargebee = React.useCallback(async (values: ChargebeeAuthData) => {
		setIsChargebeeSubmitting(true);
		try {
			await authenticateWithChargebee({
				apiKey: values.apiKey,
				site : values.site
			});
			toast({
				title: `Successfully connected with Chargebee`,
				status: "success",
				isClosable: true,
			});
			const lists = [...(integrationsList || [])];
			const getIntegrationItem = lists?.find(list => list.platform === 'chargebee');
			if (getIntegrationItem) {
				getIntegrationItem.status = true;
			}
			setIntegrationsList(lists)
		} catch (error) {
			toast({
				title: "Unable to connect with Chargebee",
				status: "error",
				isClosable: true,
			});
		} finally {
			setIsChargebeeSubmitting(false);
			onChargebeeClose();
		}
	}, [integrationsList, onChargebeeClose, toast]);
		
	const [isPaddleSubmitting, setIsPaddleSubmitting] = React.useState<boolean>(false);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const initialRef = React.useRef(null);

	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);


	React.useEffect(() => {
		async function fetchData() {
			try {
				setIsLoading(true);
				const integrationsResponse = await getIntegrationsList();
				const lists = integrationsResponse.data;
				setIntegrationsList(lists);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
				setIsLoading(false)
			}
		}
		fetchData();
	}, []);

	const connectWithPaddle = React.useCallback(async (values: PaddleAuthData) => {
		setIsPaddleSubmitting(true);
		
		try {
			await authenticateWithPaddle({
				vendorAuthCode: values.vendorAuthCode,
				vendorId: values.vendorId,
				version: values.version
			})
			
			toast({
				title: `Successfully connected with Paddle`,
				status: "success",
				isClosable: true,
			});
			const lists = [...(integrationsList || [])];
			const getIntegrationItem = lists?.find(list => list.platform === 'paddle');
			if(getIntegrationItem) {
				getIntegrationItem.status = true;
			}
	
			setIntegrationsList(lists)
			onClose()
		} catch (error) {
			toast({
				title: `Oops! Unable to connect with Paddle.`,
				description: "Make sure you have at least one product on your Paddle account",
				status: "error",
				isClosable: true,
			});
		} finally {
			setIsPaddleSubmitting(false);
		}
	}, [integrationsList, onClose, toast]);

	const disableIntefration = React.useCallback((integration:Integration) => { 
		const saveData = async()=>{
			await disableIntegration(integration.platform)
		}
		saveData()
	}, []);

	const handleIntegrationChange = React.useCallback((integration:Integration, platform: string) => { 
		const saveData = async () =>{
			if(integration.status) {
				try {
					await disableIntefration(integration);
					const lists = [...(integrationsList || [])];
					const getIntegrationItem = lists?.find(list => list.platform === integration.platform);
					if(getIntegrationItem) {
						getIntegrationItem.status = false;
					}
					setIntegrationsList(lists)

					toast({
						title: `Successfully disconnected with ${platform}`,
						status: "success",
						isClosable: true,
					});
				} catch (error) {
					toast({
						title: `Oops! Unable to disconnect with ${platform}`,
						status: "error",
						isClosable: true,
					});
				} 
			}
		}
		saveData()
	}, [disableIntefration, integrationsList, toast]);

	const onSwitchChange = (integration: Integration, platform: string) => {
		setCurrentIntegration({ integration, platform });
		setIsAlertOpen(true);
	};

	const onConfirmDisconnect = () => {
		if (currentIntegration) {
			handleIntegrationChange(currentIntegration.integration, currentIntegration.platform);
		}
		setIsAlertOpen(false);
	};


	const onCancelDisconnect = () => {
		if (currentIntegration) {
			const lists = [...(integrationsList || [])];
			const getIntegrationItem = lists?.find(list => list.platform === currentIntegration.integration.platform);
			if (getIntegrationItem) {
				getIntegrationItem.status = true;
			}
			setIntegrationsList(lists);
		}
		setIsAlertOpen(false);
	};


	const getLoadingComponent = React.useCallback(()=>{
return <SimpleGrid columns={[1, 1, 1, 2]} spacing="6">
			{[1,2,3].map((type) => {
				return <Skeleton key={type} height='120px' />
			})}
		</SimpleGrid>
	}, [])

	if(isLoading){
		return getLoadingComponent()
	}

	return (
		<SimpleGrid columns={[1, 1, 1, 2]} spacing="6">
			{integrationsList?.map((integration) => {
				if (integration.platform === "gumroad") {
					return (
						<Box
							key={integration.platform}
							bg="white"
							p="4"
							borderRadius="md"
							shadow="sm"
						>
							<HStack spacing="4" alignItems="start">
								<Box>
									<GumroadColorIcon width={96} height={96} />
								</Box>
								<Box width="100%">
									<Flex mb="2" w="100%" justifyContent="space-between">
										<Heading fontSize="medium">Gumroad</Heading>
										{integration.status? <Switch
											onChange={() => onSwitchChange(integration, 'Gumroad')}
											defaultChecked={integration.status}
											isChecked={integration.status}
											size="sm"
										/>:null}
										
									</Flex>
									<Text fontSize="sm" color="gray.500">
										{integration.status ? `Disconnecting from Gumroad will prevent you from viewing
										sales analytics and automatically generating coupons for
										Gumroad products.`: 
										`Connecting to your Gumroad account will help you select existing products and create coupons automatically whenever required. It helps you get useful sales insights too!`
										
										}
									</Text>
									{
										!integration.status ? <a href={integration.authUrl + "+https://app.paritydeals.com/app/settings/integrations/"}>
										<Button
											mt="4"
											leftIcon={<GumroadIcon width={20} height={20} />}
											variant='outline'
											color="grey"
											colorScheme='grey'
											size="sm"
										>
											Connect with Gumroad
										</Button>
									</a>:null
									}
									
								</Box>
							</HStack>
						</Box>
					);
				} else if(integration.platform === "stripe") {
						return (
							<Box
								key={integration.platform}
								bg="white"
								p="4"
								borderRadius="md"
								shadow="sm"
							>
								<HStack spacing="4" alignItems="start">
								<Box>

									<StripeColorIcon width={96} height={96} />
									</Box>
									<Box width="100%">
										<Flex mb="2" w="100%" justifyContent="space-between">
											<Heading fontSize="medium">Stripe</Heading>
											{integration.status? <Switch
												onChange={()=>{
													onSwitchChange(integration, 'Stripe')
												}}
												
												defaultChecked={integration.status}
												isChecked={integration.status}
												size="sm"
											/>:null}
											
										</Flex>
										<Text fontSize="sm" color="gray.500">
											{integration.status ? `Disconnecting from Stripe will prevent you from viewing
											sales analytics and automatically generating coupons for
											Stripe products.`: 
											`Connecting to your Stripe account will help you select existing products and create coupons automatically whenever required. It helps you get useful sales insights too!`
											
											}
										</Text>
										{
											!integration.status ? <a href={integration.authUrl + "+https://app.paritydeals.com/app/settings/integrations/"}>
											<Button
												mt="4"
												leftIcon={<StripeIcon width={20} height={20}/>}
												variant='outline'
												color="grey"
												colorScheme='grey'
												size="sm"
											>
												Connect with Stripe
											</Button>
										</a>:null
										}
										
									</Box>
								</HStack>
							</Box>
						);
				} else if (integration.platform === 'paddle') {
					return (
						<Box
							key={integration.platform}
							bg="white"
							p="4"
							borderRadius="md"
							shadow="sm"
						>
							<HStack spacing="4" alignItems="start">
								<Box>
								<PaddleColorIcon width={96} height={96} />
								</Box>
								<Box width="100%">
									<Flex mb="2" w="100%" justifyContent="space-between">
										<Heading fontSize="medium">Paddle</Heading>
										{integration.status? <Switch
											onChange={()=>{
												onSwitchChange(integration, 'Paddle')
											}}
											defaultChecked={integration.status}
											isChecked={integration.status}
											size="sm"
										/>:null}
										
									</Flex>
									<Text fontSize="sm" color="gray.500">
										{integration.status ? `Disconnecting from Paddle will prevent you from viewing
										sales analytics and automatically generating coupons for
										Paddle products.`: 
										`Connecting to your Paddle account will help you select existing products and create coupons automatically whenever required. It helps you get useful sales insights too!`
										
										}
									</Text>
									{
										!integration.status ? 
										<Button
											onClick={onOpen}
											mt="4"
											leftIcon={<PaddleColorIcon fill="#fff" width={20} height={20} />}
											variant='outline'
											color="grey"
											colorScheme='grey'
											size="sm"
										>
											Connect with Paddle
										</Button>
									:null
									}
									
								</Box>
							</HStack>
						</Box>
					);
				} else if (integration.platform === 'lemonsqueezy') {
					return (
						<Box
							key={integration.platform}
							bg="white"
							p="4"
							borderRadius="md"
							shadow="sm"
						>
							<HStack spacing="4" alignItems="start">
								<Box>
								<LemonSqueezyColorIcon width={96} height={96} />
								</Box>
								<Box width="100%">
									<Flex mb="2" w="100%" justifyContent="space-between">
										<Heading fontSize="medium">Lemon Squeezy</Heading>
										{integration.status ? <Switch
											onChange={() => {
												onSwitchChange(integration, 'Lemon Squeezy')
											}}
											defaultChecked={integration.status}
											isChecked={integration.status}
											size="sm"
										/> : null}

									</Flex>
									<Text fontSize="sm" color="gray.500">
										{integration.status ? `Disconnecting from Lemon Squeezy will prevent you from viewing
										sales analytics and automatically generating coupons for
										LemonSqueezy products.`:
											`Connecting to your Lemon Squeezy account will help you select existing products and create coupons automatically whenever required. It helps you get useful sales insights too!`

										}
									</Text>
									{
										!integration.status ?
											<Button
												onClick={onLemonSqueezyOpen}
												mt="4"
												leftIcon={<LemonSqueezyColorIcon fill="#fff" width={20} height={20} />}
												variant='outline'
												color="grey"
												colorScheme='grey'
												size="sm"
											>
												Connect with Lemon Squeezy
											</Button>
											: null
									}		
								</Box>
							</HStack>
						</Box>
					);
				} else if (integration.platform === 'whop') {
					return (
						<Box
							key={integration.platform}
							bg="white"
							p="4"
							borderRadius="md"
							shadow="sm"
						>
							<HStack spacing="4" alignItems="start">
								<Box>
								<WhopColorIcon width={96} height={96} />
								</Box>
								<Box width="100%">
									<Flex mb="2" w="100%" justifyContent="space-between">
										<Heading fontSize="medium">Whop</Heading>
										{integration.status ? <Switch
											onChange={() => {
												onSwitchChange(integration, 'Whop')
											}}
											defaultChecked={integration.status}
											isChecked={integration.status}
											size="sm"
										/> : null}

									</Flex>
									<Text fontSize="sm" color="gray.500">
										{integration.status ? `Disconnecting from Whop will prevent you from viewing
										sales analytics and automatically generating coupons for
										Whop products.`:
											`Connecting to your Whop account will help you select existing products and create coupons automatically whenever required. It helps you get useful sales insights too!`

										}
									</Text>
									{
										!integration.status ?
											<Button
												onClick={onWhopOpen}
												mt="4"
												leftIcon={<WhopColorIcon fill="#fff" width={20} height={20} />}
												variant='outline'
												color="grey"
												colorScheme='grey'
												size="sm"
											>
												Connect with Whop
											</Button>
											: null
									}
								</Box>
							</HStack>
						</Box>
					);
				}else if (integration.platform === 'chargebee') {
					return (
						<Box
							key={integration.platform}
							bg="white"
							p="4"
							borderRadius="md"
							shadow="sm"
						>
							<HStack spacing="4" alignItems="start">
								<Box>
								<ChargebeeColorIcon width={96} height={96} />
								</Box>
								<Box width="100%">
									<Flex mb="2" w="100%" justifyContent="space-between">
										<Heading fontSize="medium">Chargebee</Heading>
										{integration.status ? <Switch
											onChange={() => {
												onSwitchChange(integration, 'Chargebee')
											}}
											defaultChecked={integration.status}
											isChecked={integration.status}
											size="sm"
										/> : null}

									</Flex>
									<Text fontSize="sm" color="gray.500">
										{integration.status ? `Disconnecting from Chargebee will prevent you from viewing
										sales analytics and automatically generating coupons for
										Chargebee products.`:
											`Connecting to your Chargebee account will help you select existing products and create coupons automatically whenever required. It helps you get useful sales insights too!`

										}
									</Text>
									{
										!integration.status ?
											<Button
												onClick={onChargebeeOpen}
												mt="4"
												leftIcon={<ChargebeeColorIcon fill="#fff" width={20} height={20} />}
												size="sm"
												variant='outline'
												color="grey"
												colorScheme='grey'
											>
												Connect with Chargebee
											</Button>
											: null
									}
								</Box>
							</HStack>
						</Box>
					);
				}
				return null;
			})}
			<Modal
					initialFocusRef={initialRef}
					isOpen={isOpen}
					onClose={onClose}
					closeOnOverlayClick={false}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Authenticate with Paddle</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Alert status='info' mb="6">
								<AlertIcon />
								<Text>You can find the below details from your Paddle  <Link target="_blank" textDecoration="underline" fontWeight="medium" href='https://vendors.paddle.com/authentication'>
									authentication
								</Link> page</Text>
							</Alert>
							<PaddleAuthFormForm
								onBackClick={onClose}
								initialRef={initialRef}
								onNextClick={(values)=>{
									connectWithPaddle(values)
								}}
								isSubmitting={isPaddleSubmitting}
							></PaddleAuthFormForm>
						</ModalBody>
					</ModalContent>
				</Modal>
			<Modal
				initialFocusRef={initialLemonSqueezyRef}
				isOpen={isLemonSqueezyOpen}
				onClose={onLemonSqueezyClose}
				closeOnOverlayClick={false}
				size="xl"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Authenticate with Lemon Squeezy</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Alert status='info' mb="6">
							<AlertIcon />
							<Text>Lemon Squeezy uses API keys for authentication. You can view and manage your API keys in your <Link target="_blank" textDecoration="underline" fontWeight="medium" href='https://app.lemonsqueezy.com/settings/api'>
								account settings.
							</Link></Text>
						</Alert>
						<LemonSqueezyAuthForm
							onBackClick={onLemonSqueezyClose}
							initialRef={initialLemonSqueezyRef}
							onNextClick={(values) => {
								connectWithLemonSqueezy(values)
							}}
							isSubmitting={isLemonSqueezySubmitting}
						></LemonSqueezyAuthForm>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Modal
				initialFocusRef={initialWhopRef}
				isOpen={isWhopOpen}
				onClose={onWhopClose}
				closeOnOverlayClick={false}
				size="xl"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Authenticate with Whop</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Alert status='info' mb="6">
							<AlertIcon />
							<Text>Whop uses API keys for authentication. You can view and manage your API keys in your <Link target="_blank" textDecoration="underline" fontWeight="medium" href='https://dash.whop.com/settings/developer'>
								account settings.
							</Link></Text>
						</Alert>
						<WhopAuthForm
							onBackClick={onWhopClose}
							initialRef={initialWhopRef}
							onNextClick={(values) => {
								connectWithWhop(values)
							}}
							isSubmitting={isWhopSubmitting}
						></WhopAuthForm>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Modal
					initialFocusRef={initialChargebeeRef}
					isOpen={isChargebeeOpen}
					onClose={onChargebeeClose}
					closeOnOverlayClick={false}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Authenticate with Chargebee</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Alert status='info' mb="6">
								<AlertIcon />
								<Text>To create and configure the API keys, select Settings &gt; Configure Chargebee &gt; API Keys and Webhooks and then click on the API Keys tab. Any existing API keys are listed.</Text>
							</Alert>
							<ChargebeeAuthForm
								onBackClick={onChargebeeClose}
								initialRef={initialChargebeeRef}
								onNextClick={(values)=>{
									connectWithChargebee(values)
								}}
								isSubmitting={isChargebeeSubmitting}
							></ChargebeeAuthForm>
						</ModalBody>
					</ModalContent>
				</Modal>
			<AlertDialog
				isOpen={isAlertOpen}
				leastDestructiveRef={cancelRef}
				onClose={onCancelDisconnect}

			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Disconnect {currentIntegration?.platform} Integration
						</AlertDialogHeader>
						<AlertDialogBody>
							Disconnecting will disable automatic coupon refreshing.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onCancelDisconnect}>
								Cancel
							</Button>
							<Button colorScheme="red" onClick={onConfirmDisconnect} ml={3}>
								Disconnect
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</SimpleGrid>
	);
};
