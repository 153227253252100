import React from "react";
import { Box, Text,Button} from "@chakra-ui/react";
import { CurrentUser } from "../../services/appConfig";
import { allowdQuota } from "../../containers/SettingsUsagePage/SettingsUsagePage";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/commonUtils";
import { SubscriptionPlan,planNames } from "../../containers/SettingsUsagePage/SettingsUsagePage";

export const SubscriptionUsageWarning = () => {
  const user = CurrentUser.get();
  const userPlan = user.plan as SubscriptionPlan;
  const longPlanName = planNames[userPlan]?.longName ;

  return (
    <Box
      bg="red.50"
      border="1px"
      color="red.500"
      borderRadius="8"
      width="100%"
      padding="24px"
      mt={"24px"}
    >
      <Box display="flex" flexDirection="row">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M7.0845 31.751C7.065 31.751 7.047 31.751 7.026 31.7495C6.5535 31.7255 6.09 31.6235 5.649 31.445C3.4785 30.563 2.4315 28.0835 3.312 25.9145L14.2935 6.67549C14.6715 5.99149 15.2445 5.41849 15.9435 5.03149C17.991 3.89749 20.58 4.64299 21.7125 6.68899L32.622 25.781C32.865 26.3525 32.9685 26.8175 32.994 27.2915C33.0525 28.4255 32.6655 29.513 31.9065 30.3545C31.1475 31.196 30.105 31.6925 28.9725 31.7495L7.1925 31.751H7.0845Z"
            fill="#E53E3E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6865 15.0312C16.6865 14.3083 17.276 13.7188 17.999 13.7188C18.722 13.7188 19.3115 14.3083 19.3115 15.0312V19.2733C19.3115 19.9978 18.722 20.5858 17.999 20.5858C17.276 20.5858 16.6865 19.9978 16.6865 19.2733V15.0312ZM16.6865 24.4049C16.6865 23.6774 17.276 23.0849 17.999 23.0849C18.722 23.0849 19.3115 23.6699 19.3115 24.3884C19.3115 25.1279 18.722 25.7174 17.999 25.7174C17.276 25.7174 16.6865 25.1279 16.6865 24.4049Z"
            fill="#E53E3E"
          />
        </svg>
        <Box ml={"24px"}>
          <Box>
            <Text
              textColor="gray.700"
              display="flex"
              alignItems="center"
              fontSize="24"
              fontWeight={600}
              mb={"8px"}
            >
              You have exceeded the {longPlanName} {formatNumber(allowdQuota[userPlan])} plan limit for pricing page visitors.
            </Text>
            <Text textColor="gray.600" fontSize="16">
              To increase your limit, please upgrade your plan.
            </Text>
          </Box>
          <Box mt="24px">
            <Link to="/app/settings/subscription/">
              <Button
                bg="blackAlpha.900"
                _hover={{ bg: "blackAlpha.900" }}
                _active={{ bg: "blackAlpha.900" }}
                colorScheme="blackAlpha"
                variant="solid"
              >
                <Text textColor="white"> Upgrade Plan</Text>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};