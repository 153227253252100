import * as React from "react";

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Tooltip,
} from "@chakra-ui/react";

import { useState, useRef } from "react";

import { refreshCoupon } from "../../services/dealServices";

interface RefreshCouponProps {
  id: string;
  isEnabled: boolean;
}

export const RefreshCoupon = ({ id, isEnabled }: RefreshCouponProps) => {
  const toast = useToast();

  const onRefreshButtonClick = React.useCallback(async () => {
    try {
      await refreshCoupon(id);

      toast({
        title: "Coupons have been refreshed successfully",
        status: "success",
        isClosable: true,
      });
    } catch (error) {

      toast({
        title: "Error while refreshing Coupons",
        description:
          "An error occurred while refreshing coupons. Please try again.",
        status: "error",
        isClosable: true,
      });
    }
  }, [id, toast]);

  const [isRefreshDialogOpen, setIsRefreshDialogOpen] = useState(false);
  const refreshCancelRef = useRef(null);
  const handleRefresh = () => {
    setIsRefreshDialogOpen(true);
  };

  const handleConfirmRefresh = () => {
    setIsRefreshDialogOpen(false);
    onRefreshButtonClick();
  };

  const handleCancelRefresh = () => {
    setIsRefreshDialogOpen(false);
  };

  return (
    <>
      {
        isEnabled ? (<Button onClick={handleRefresh} colorScheme="teal" ml='8'
          size="sm">
          Refresh Coupons
        </Button>) : (<Tooltip label='Automatic coupon refresh is only available to Pro users'><Button colorScheme="teal" ml='8'
          size="sm">
          Refresh Coupons
      </Button></Tooltip>)
      }

      <AlertDialog
        isOpen={isRefreshDialogOpen}
        leastDestructiveRef={refreshCancelRef}
        onClose={handleCancelRefresh}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Refresh coupon?
            </AlertDialogHeader>

            <AlertDialogBody>
              Proceeding will update your coupons, removing old ones.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={refreshCancelRef}
                size="sm"
                onClick={handleCancelRefresh}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={handleConfirmRefresh}
                ml={3}
              >
                Refresh
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
