import * as React from 'react';
import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Flex,
	Heading,
	HStack,
	Input,
	InputGroup,
	InputLeftElement,
	Skeleton,
	Text,
	useToast,
	VStack,
} from '@chakra-ui/react';


import { useEffect, useState } from 'react';
import { MediaListItem } from '../../components/MediaListItem/MediaListItem';
import { fetchGumroadProducts, fetchPaddleProducts, fetchStripeProducts, Product, syncProductsService, fetchLemonSqueezyProducts, fetchWhopProducts, fetchChargebeeProducts } from '../../services/productServices';
import { CheckIcon } from '../../components/Icons/Check';
import { SearchIcon } from '../../components/Icons/Search';
import styles from './DealsSelectProduct.module.scss';
import { DealPlatform } from '../../types/types';
import { CurrentUser } from '../../services/appConfig';
import { IntegratedPlatform } from "../../types/deals.type";

interface DealsSelectProductProps {
	primaryButtonLabel?: string;
	isSubmitting?: boolean;
	onBackClick: () => void;
	onNextClick: (product: Product[]) => void;
	platform: Omit<DealPlatform, 'manual'>
	integratedPlatform?: IntegratedPlatform[];
	isVisibleBackButton?: boolean;
}

export const DealsSelectProduct = ({
	primaryButtonLabel = "Update Deals",
	onNextClick,
	onBackClick,
	isSubmitting = false,
	platform,
	integratedPlatform,
	isVisibleBackButton = true,
}: DealsSelectProductProps) => {
    
	const user = CurrentUser.get();
	const toast = useToast();
	const [products, setProducts] = useState<Product[]>();
	const [originalProducts, setOriginalProducts] = useState<Product[]>();
	const [selectedProducts, setSelectedProducts] = useState<Product[]>(
		[] as Product[]
	
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	useEffect(() => {
		async function fetchData() {
		  try {
			setIsLoading(true);
			let response;
			console.log("platform", platform);
			if (platform === "stripe") {
			  response = await fetchStripeProducts();
			} else if (platform === "gumroad") {
			  response = await fetchGumroadProducts();
			} else if (platform === "paddle") {
			  response = await fetchPaddleProducts();
			} else if (platform === "lemonsqueezy") {
			  response = await fetchLemonSqueezyProducts();
			} else if (platform === "whop") {
			  response = await fetchWhopProducts();
			} else if (platform === "chargebee") {
			  response = await fetchChargebeeProducts();
			}
			if (response) {
			  let data: Product[] = response.data;
			  if (data.length) {
				if (platform === "chargebee") {
				  data = data.map((p) => {
					p.name = `[${p.type}] ${p.name}`;
					return p;
				  });
				}
				// setSelectedProducts(data);
			  }
				if (integratedPlatform && integratedPlatform.length > 0) {
					const filteredProducts = data.filter((obj) =>
						integratedPlatform?.some((obj2) => obj2.identifier === obj.id)
					);
					setSelectedProducts(filteredProducts);
				} else {
					setSelectedProducts([data[0]]);
				}
			
			  setProducts(data);
			  setOriginalProducts(data);
			}
		  } catch (error) {
			console.log("Unable to fetch product list", error);
		  } finally {
			setIsLoading(false);
		  }
		}
		fetchData();
	  }, [integratedPlatform, platform]);


	// Search products by name
	const searchProducts = React.useCallback((text) => {
		if (!products) {
			setProducts([]);
		}
		const filteredProducts = [...(originalProducts || [])].filter(productItem => productItem.name.toLowerCase().includes(text.toLowerCase()));
		setProducts(filteredProducts);
	}, [originalProducts, products])


	const onProductSelect = React.useCallback((item: Product) => {
		let products = [...selectedProducts];
		const product = products.find(productItem => productItem.id === item.id);
		if (product) {
			products = products.filter(productItem => productItem.id !== item.id)
		} else {
			products = [item, ...products]
		}
		if (products.length) {
			setSelectedProducts(products);
		}
	}, [selectedProducts]);


	const getProductAttributes = React.useCallback((product: any) => {
		const attributes = {
			name: product.name,
			description: product.description,
			thumb: '',
			id: product.id,
		};
		if(platform === 'lemonsqueezy') {
			attributes.name = product.attributes.name;
			attributes.description = product.attributes.description;
			attributes.thumb = product.attributes.thumbUrl;
		} else if(platform === 'paddle') {
			attributes.thumb = product.icon;
		} else if(platform === 'gumroad') {
			attributes.thumb = product.previewUrl;
		}
		return attributes;
	}, [platform]);


	const syncProducts = React.useCallback(() => {
		async function sync() {
			try {
				await syncProductsService();
				toast({
					title: `You'll be notified via email once the sync is complete.`,
					status: "success",
					isClosable: true,
				});
			} catch (error) {
				console.log('Unable to initiate sync', error);
			} finally {

			}
		}
		sync();
	}, [toast])


	const getProductListComponent = React.useCallback(() => {
		return <>
			{user.syncProducts ? <Alert flexShrink={0} status='warning'>
				<AlertIcon />
				Can't see latest products? <Button onClick={syncProducts} ml={4} colorScheme="orange" variant='outline' size="sm">Sync now</Button>
			</Alert> : null}
			{products?.map((product) => {
				const isSelected = selectedProducts.find(productItem => productItem.id === product.id);
				const {
					name,
					description,
					thumb,
					id,
				} = getProductAttributes(product);
				console.log('product', product, thumb);
				return (
					<MediaListItem
						name={name}
						imageAlt={name}
						imageUrl={thumb}
						productId={id}
						description={description}
						showGetCodeMenu={false}
						showCustomizeMenu={false}
						className={product.id === isSelected?.id ? styles.selectedDeal : ''}
						key={product.id}
						actionButtonLabel={
							product.id === isSelected?.id
								? 'Selected Product'
								: 'Select Product'
						}
						actionButtonLeftIcon={
							product.id === isSelected?.id ? (
								<CheckIcon />
							) : null
						}
						onActionButtonClick={() => {
							onProductSelect(product);
						}}
					></MediaListItem>
				);
			})}
		</>
	}, [onProductSelect, products, selectedProducts, syncProducts, user.syncProducts, getProductAttributes])

	const selectAllProducts = React.useCallback(() => {
		if (!originalProducts || !originalProducts.length) {
			return;
		}
		if (originalProducts.length === selectedProducts.length) {
			setSelectedProducts(originalProducts);
		} else {
			setSelectedProducts([...(originalProducts || [])]);
		}
	}, [originalProducts, selectedProducts]);

	const getLoadingComponent = React.useCallback(() => {
		return <VStack spacing={4}>
			<Skeleton width="100%" height="122px" />
			<Skeleton width="100%" height="122px" />
			<Skeleton width="100%" height="122px" />
			<Skeleton width="100%" height="122px" />
		</VStack>
	}, []);

	const getNoDataComponent = React.useCallback(() => {
		return <Flex>
			<Text mt="16" color='gray.400' fontSize="xl">No products found</Text>
		</Flex>
	}, []);

	return (
		<Flex h='100%' direction='column'>
			<VStack alignItems='start' w='100%' mb='6'>
				<Flex justifyContent="space-between" w="100%">
					<Box>
						<Heading as='h2' size='md' isTruncated>
							Select product ({selectedProducts.length})
						</Heading>
						<Text>Products fetched from your <span style={{textTransform: 'capitalize'}}>{platform}</span> account.</Text>
					</Box>
					{
						user.syncProducts ? <Box>
							<HStack>
								<InputGroup>
									<InputLeftElement
										pointerEvents='none'
										height="32px"
										width="32px"
										children={<SearchIcon color={'var(--chakra-colors-gray-500)'} />}
									/>
									<Input disabled={isLoading} onChange={(e) => {
										searchProducts(e.target.value);
									}} size="sm" type='text' placeholder='Search' />
								</InputGroup>
								<Button
									minW="100px"
									size='sm'
									disabled={isLoading}
									variant='outline'
									onClick={() => {
										selectAllProducts()
									}}
								>
									{originalProducts && selectedProducts && originalProducts.length === selectedProducts.length ? 'Unselect all' : 'Select all'}
								</Button>
							</HStack>
						</Box> : null
					}

				</Flex>
			</VStack>
			{isLoading ? getLoadingComponent() : <VStack overflowY='auto' spacing='4' mb='86px' p='2px'>
				{originalProducts && originalProducts.length && products && !products.length ? getNoDataComponent() : getProductListComponent()}
			</VStack>}

			<Box
				pos='absolute'
				w='100%'
				zIndex={2}
				display='flex'
				p='6'
				bottom='0'
				left='0'
				right='0'
				bg='white'
				borderTop='1px solid'
				borderColor='gray.100'
				justifyContent='space-between'
			>
				<HStack></HStack>
				<HStack>
				{isVisibleBackButton && (
					<Button onClick={onBackClick} variant="outline">
						Go back
					</Button>
					)}
					<Button
						bg='blackAlpha.900'
						_hover={{ bg: 'blackAlpha.900' }}
						_active={{ bg: 'blackAlpha.900' }}
						colorScheme='blackAlpha'
						isLoading={isSubmitting}
            			isDisabled={isSubmitting}
						variant='solid'
						disabled={!products || !products.length || !selectedProducts?.length}
						onClick={() => {
							const proeuctsToSend = selectedProducts.map((product) => {
								const {name, id, thumb} =  getProductAttributes(product);
								return {
									name,
									id,
									previewUrl: thumb,
								}
							});
							onNextClick(proeuctsToSend);
						}}
					>
						{primaryButtonLabel}
					</Button>
				</HStack>
			</Box>
		</Flex>
	);
};
