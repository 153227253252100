import { Box, Flex, Text, Heading, Button, Spinner } from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { APIUsageInfo } from "../../components/APIUsageInfo/APIUsageInfo";
import { MonthlyPricingPageVisitsGraph } from "../../components/MonthlyPricingPageVisitsGraph/MonthlyPricingPageVisitsGraph";
import { SubscriptionUsageWarning } from "../../components/SubscriptionUsageWarning/SubscriptionUsageWarning";
import {
  fetchVisitors,
  DatedVisitorsData,
} from "../../services/dashboardServices";
import { CurrentUser } from "../../services/appConfig";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/commonUtils";

export let allowdQuota: { [key: string]: number } = {
  Free: 5000,
  null: 5000,
  "monthly-subscription": 10000,
  "monthly-subscription-standard": 100000,
  "monthly-subscription-premium": 1000000,
  "yearly-subscription": 10000,
  "yearly-subscription-standard": 100000,
  "yearly-subscription-premium": 1000000,
  "monthly-standard": 100000,
  "monthly-premium": 1000000,
  "monthly-subscription-base": 10000,
  "yearly-standard": 100000,
  "yearly-premium": 1000000,
  "yearly-subscription-base": 10000,
  "ParityDeals-LTD-plan-A": 10000,
  "ParityDeals-LTD-plan-B": 100000,
  "ParityDeals-LTD-plan-C": 1000000,
};

export enum SubscriptionPlan {
  Free = "Free",
  Null = "null",
  MonthlySubscription = "monthly-subscription",
  MonthlySubscriptionStandard = "monthly-subscription-standard",
  MonthlySubscriptionPremium = "monthly-subscription-premium",
  YearlySubscription = "yearly-subscription",
  YearlySubscriptionStandard = "yearly-subscription-standard",
  YearlySubscriptionPremium = "yearly-subscription-premium",
  MonthlyStandard = "monthly-standard",
  MonthlyPremium = "monthly-premium",
  MonthlySubscriptionBase = "monthly-subscription-base",
  YearlyStandard = "yearly-standard",
  YearlyPremium = "yearly-premium",
  YearlySubscriptionBase = "yearly-subscription-base",
  ParityDealsLTDPlanA = "ParityDeals-LTD-plan-A",
  ParityDealsLTDPlanB = "ParityDeals-LTD-plan-B",
  ParityDealsLTDPlanC = "ParityDeals-LTD-plan-C"
}

export const planNames: { [key in SubscriptionPlan]: { shortName: string; longName: string } } = {
  [SubscriptionPlan.Free]: { shortName: "Free", longName: "Free " },
  [SubscriptionPlan.Null]: { shortName: "null", longName: "null" },
  [SubscriptionPlan.MonthlySubscription]: { shortName: "Monthly Base", longName: "Monthly Base" },
  [SubscriptionPlan.MonthlySubscriptionStandard]: { shortName: "Monthly Standard", longName: "Monthly Standard" },
  [SubscriptionPlan.MonthlySubscriptionPremium]: { shortName: "Monthly Premium", longName: "Monthly Premium" },
  [SubscriptionPlan.YearlySubscription]: { shortName: "Yearly Base", longName: "Yearly Base" },
  [SubscriptionPlan.YearlySubscriptionStandard]: { shortName: "Yearly Standard", longName: "Yearly Standard" },
  [SubscriptionPlan.YearlySubscriptionPremium]: { shortName: "Yearly Premium", longName: "Yearly Premium" },
  [SubscriptionPlan.MonthlyStandard]: { shortName: "Monthly Standard", longName: "Monthly Standard" },
  [SubscriptionPlan.MonthlyPremium]: { shortName: "Monthly Premium", longName: "Monthly Premium" },
  [SubscriptionPlan.MonthlySubscriptionBase]: { shortName: "Monthly Base", longName: "Monthly Base" },
  [SubscriptionPlan.YearlyStandard]: { shortName: "Yearly Standard", longName: "Yearly Standard" },
  [SubscriptionPlan.YearlyPremium]: { shortName: "Yearly Premium", longName: "Yearly Premium" },
  [SubscriptionPlan.YearlySubscriptionBase]: { shortName: "Yearly Base", longName: "Yearly Base" },
  [SubscriptionPlan.ParityDealsLTDPlanA]: { shortName: "LTD Plan A", longName: "ParityDeals LTD Plan A" },
  [SubscriptionPlan.ParityDealsLTDPlanB]: { shortName: "LTD Plan  B", longName: "ParityDeals LTD Plan B" },
  [SubscriptionPlan.ParityDealsLTDPlanC]: { shortName: "LTD Plan C", longName: "ParityDeals LTD Plan C" },
};

export interface SettingsUsagePageprops {
  allowdQuota: number;
  usedQuota: number;
  PlanName: string;
}

export const SettingsUsagePage = () => {
  const [visitorsData, setVisitorsData] = useState<DatedVisitorsData[]>([]);

  const [isChartLoaded, setIsChartLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [usedQuota, setUsedQuota] = useState<number>(0);

  const user = CurrentUser.get();
  const userPlan = user.plan as SubscriptionPlan;
  const planDetails = planNames[userPlan];

  const isfreeplan = user.plan === SubscriptionPlan.Free;
  const getVisitorsData = useCallback(async () => {
    const visotorResponse = await fetchVisitors("thisyear", "");
    const thisMonthResponse = await fetchVisitors("thismonth", "");

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");

    const formattedDate = `${year}-${month}-01`;

    console.log(formattedDate);
    let monthlyDatedVisitorsData = thisMonthResponse.data.datedVisitorsData.reduce((acc, curr) => {
      acc.visitors += curr.visitors;
      acc.uniqueVisitors += curr.uniqueVisitors;
      acc.overages += curr.overages;
      return acc;
  }, { date: formattedDate, visitors: 0, uniqueVisitors: 0, overages: 0 });

  console.log("monthly",monthlyDatedVisitorsData)
   let yearlyData=visotorResponse.data.datedVisitorsData;
   let currentMonthObj=yearlyData.find(obj=>obj.date===formattedDate);
   if (currentMonthObj) {
    currentMonthObj.visitors=monthlyDatedVisitorsData.visitors
    currentMonthObj.uniqueVisitors=monthlyDatedVisitorsData.uniqueVisitors
    currentMonthObj.overages=monthlyDatedVisitorsData.overages
   }

  setVisitorsData(yearlyData);
    let usedQuota = monthlyDatedVisitorsData.visitors;
    setUsedQuota(usedQuota || 0);
    setIsChartLoaded(true);
  }, []);
  useEffect(() => {
    getVisitorsData();
  }, [getVisitorsData]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await getVisitorsData();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [getVisitorsData]);

  if (isLoading || user === null) {
    return (
      <Flex h="100%" alignItems="center" justifyContent="center">
        <Spinner
          thickness="2px"
          speed="0.65s"
          emptyColor="gray.200"
          color="gray.700"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <>
      <Flex p="5" borderRadius="md" maxW="5xl" direction={"column"}>
        <Box>
          <Flex w="100%" direction="column">
            <Text fontSize={30} lineHeight={"32px"} color="grey.700" fontWeight={600}>
              {planDetails.longName}
            </Text>
            <Text marginTop="8px" fontSize="14" color="grey.700">
              {planDetails.longName} plan comes with a limitation of{" "}
              {formatNumber(allowdQuota[userPlan])} monthly pricing page visits.
            </Text>

            <Box >
              {isfreeplan && usedQuota < allowdQuota[user.plan] && (
                <Link to="/app/settings/subscription/">
                  <Button
                    bg="blackAlpha.900"
                    _hover={{ bg: "blackAlpha.900" }}
                    _active={{ bg: "blackAlpha.900" }}
                    colorScheme="blackAlpha"
                    variant="solid"
                    mt="24px" mb="8px"
                  >
                    <Text textColor="white"> Upgrade Plan</Text>
                  </Button>
                </Link>
              )}
            </Box>
          </Flex>
        </Box>
        {usedQuota >= allowdQuota[userPlan] ? (
          <SubscriptionUsageWarning />
        ) : null}
        <APIUsageInfo
          allowdPage={allowdQuota[userPlan]}
          usedPage={usedQuota}
        />
        <Box w="100%" height="500px" mb="35px">
          {isChartLoaded ? (
            <MonthlyPricingPageVisitsGraph
              allowdQuota={allowdQuota[userPlan]}
              visotorResponse={visitorsData}
            />
          ) : null}
        </Box>
      </Flex>
    </>
  );
};
