import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
}
export const IntegrationsIcon = ({
  width = 24,
  height = 24,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.7301 14.37C20.536 14.07 20.26 13.77 19.9023 13.58C19.6162 13.44 19.4322 13.21 19.2687 12.94C18.7475 12.08 19.0541 10.95 19.9228 10.44C20.9447 9.87 21.2718 8.6 20.679 7.61L19.9943 6.43C19.4118 5.44 18.1344 5.09 17.1226 5.67C16.2233 6.15 15.0685 5.83 14.5473 4.98C14.3838 4.7 14.2918 4.4 14.3122 4.1C14.3429 3.71 14.2203 3.34 14.0363 3.04C13.6582 2.42 12.9735 2 12.2172 2H10.7763C10.0302 2.02 9.34553 2.42 8.9674 3.04C8.77323 3.34 8.66081 3.71 8.68125 4.1C8.70169 4.4 8.60972 4.7 8.4462 4.98C7.925 5.83 6.77019 6.15 5.88109 5.67C4.85913 5.09 3.59191 5.44 2.99917 6.43L2.31446 7.61C1.73194 8.6 2.05897 9.87 3.07071 10.44C3.93937 10.95 4.24596 12.08 3.73498 12.94C3.56125 13.21 3.37729 13.44 3.09115 13.58C2.74368 13.77 2.43709 14.07 2.27358 14.37C1.89546 14.99 1.9159 15.77 2.29402 16.42L2.99917 17.62C3.37729 18.26 4.08245 18.66 4.81825 18.66C5.16572 18.66 5.5745 18.56 5.90153 18.36C6.15702 18.19 6.46361 18.13 6.80085 18.13C7.81259 18.13 8.66081 18.96 8.68125 19.95C8.68125 21.1 9.62145 22 10.8069 22H12.1968C13.372 22 14.3122 21.1 14.3122 19.95C14.3429 18.96 15.1911 18.13 16.2029 18.13C16.5299 18.13 16.8365 18.19 17.1022 18.36C17.4292 18.56 17.8278 18.66 18.1855 18.66C18.911 18.66 19.6162 18.26 19.9943 17.62L20.7097 16.42C21.0776 15.75 21.1083 14.99 20.7301 14.37Z"
        fill="#718096"
      />
      <path
        d="M11.5117 14.8299C9.90726 14.8299 8.60938 13.5799 8.60938 12.0099C8.60938 10.4399 9.90726 9.17993 11.5117 9.17993C13.1162 9.17993 14.3834 10.4399 14.3834 12.0099C14.3834 13.5799 13.1162 14.8299 11.5117 14.8299Z"
        fill="#4A5568"
      />
    </svg>
  );
};
