import * as React from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useToast,
} from '@chakra-ui/react';

import styles from './ResetPassword.module.scss'

import { Field, Form, Formik } from 'formik';

import { forgotPassword, logIn, resetPassword } from '../../services/authServices';
import {
	Link,
	Redirect,
	RouteComponentProps,
	useLocation,
	withRouter,
} from 'react-router-dom';
import { useState } from 'react';
import { Logo } from '../../components/Logo/Logo';
import posthog from 'posthog-js';

interface MatchParams {
	id: string;
}

export type ResetPasswordProps = RouteComponentProps<MatchParams>;
interface FormValues {
	password: string;
}
const ResetPassword = (props: ResetPasswordProps) => {
	const toast = useToast();

	let { search } = useLocation();

	const id = new URLSearchParams(search).get("id");
	const email = new URLSearchParams(search).get("email");
	const isStripe = new URLSearchParams(search).get("external");

	React.useEffect(() => {
		posthog.capture('$pageview');
	}, []);


	return (
		<Flex bg='white' h='100vh' direction='column'>
			<Flex
				shrink={0}
				shadow='base'
				w='100%'
				h='60px'
				bg='white'
				pr='10'
				pb='4'
				pt='4'
				pl='10'
				zIndex='2'
				alignItems='center'
			>
				<Logo></Logo>
			</Flex>
			<Flex alignItems='center' justifyContent='center' h='100%'>
				<Box
					bg='gray.50'
					p='100px 60px '
					shadow='md'
					width={480}
					maxW="90vw"
					borderRadius='md'
					m='0 auto'
				>
					<Heading
						textAlign='center'
						fontSize='24px'
						as='h3'
						mb="8"
						isTruncated
					>
						{isStripe ? 'Create Account': 'Reset Your Password'}
					</Heading>
					
					<Formik
						initialValues={{ password: '' }}
						onSubmit={async (values: FormValues, actions: any) => {
							try {
								const response = await resetPassword({
									password: values.password,
								}, id as string);
								actions.resetForm();
								actions.setSubmitting(false);
								toast({
									title: `Your password has been reset successfully.`,
									status: "info",
									isClosable: true,
								});
							} catch (error) {
								console.log('error', error)
								toast({
									title: `Oops! Something went wrong`,
									status: 'error',
									isClosable: true,
								});
								actions.setSubmitting(false);
							}
						}}
					>
						{(props) => (
							<Form>
								{isStripe ? <Field
									type='email'
									name='email'
								>
									{({ field, form }: any) => (
										<FormControl
											mb='6'
										>
											<FormLabel
												fontSize='sm'
												htmlFor='email'
											>
												Email
											</FormLabel>
											<Input
												{...field}
												id='email'
												value={email}
												disabled
											/>
										</FormControl>
									)}
								</Field> : null}
								<Field
									type='password'
									name='password'
								>
									{({ field, form }: any) => (
										<FormControl
											isInvalid={
												form.errors.password &&
												form.touched.password
											}
											mb='6'
										>
											{isStripe? <FormLabel
												fontSize='sm'
												htmlFor='email'
											>
												Password
											</FormLabel>:null}
											<Input
												{...field}
												type='password'
												id='password'
												placeholder={isStripe ? 'Create a password' : 'Enter new password'}
											/>
										</FormControl>
									)}
								</Field>
								<Button
									bg='blackAlpha.900'
									_hover={{ bg: 'blackAlpha.900' }}
									_active={{ bg: 'blackAlpha.900' }}
									colorScheme='blackAlpha'
									variant='solid'
									mt={2}
									isFullWidth
									size='lg'
									isLoading={props.isSubmitting}
									isDisabled={
										props.isSubmitting ||
										!props.isValid ||
										!props.dirty
									}
									type='submit'
								>
									{isStripe? 'Create account':  'Reset password'}
								</Button>
							</Form>
						)}
					</Formik>
					{
						isStripe ? null: <Text fontSize='sm' mt='4' textAlign='center' color="gray.600">
						<Link to='/login'>
							<Text display='inline' color="gray.700">
								Back to Sign in
							</Text>
						</Link>
					</Text>
					}
					
				</Box>
			</Flex>
		</Flex>
	);
};

export default 	withRouter(ResetPassword);
