import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const ArrowRightIcon = ({
	width = 18,
	height = 18,
	...restProps
}: IconProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 18'
			{...restProps}
		>
			<path d="M12.129 8.25L8.106 4.227L9.1665 3.1665L15 9L9.1665 14.8335L8.106 13.773L12.129 9.75H3V8.25H12.129Z" fill="#2D3748"/>
		</svg>
	);
};
