import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}
export const ChargebeeColorIcon = ({
  width = 96,
  height = 96,
  fill,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      {...restProps}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="24" fill="#FF3300" />
      <path
        d="M38.5704 49.9551L82.1025 39.5752V17H59.5301L38.5704 49.9551Z"
        fill="white"
      />
      <path
        d="M17 49.5583C16.9979 52.1982 17.3174 54.8285 17.9513 57.3911L38.5732 49.9551L17.7796 42.4517C17.2634 44.7834 17.0019 47.1644 17 49.5527V49.5583Z"
        fill="white"
      />
      <path
        d="M24.6667 28.5705L38.5648 49.9608L44.9734 17.3237C37.0489 18.4469 29.8178 22.4558 24.6667 28.5818V28.5705Z"
        fill="white"
      />
      <path
        d="M38.5704 49.9551L82.1025 60.3294V82.9017H59.5301L38.5704 49.9551Z"
        fill="white"
      />
      <path
        d="M24.6696 71.3341L38.5676 49.9438L44.9678 82.5725C37.0442 81.4489 29.8141 77.44 24.6639 71.3144L24.6696 71.3341Z"
        fill="white"
      />
    </svg>
  );
};
