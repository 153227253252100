import * as React from "react";
import {
	Box,
	Flex,
	Heading,
	HStack,
	List,
	ListItem,
	VStack,
	Spinner,
	IconButton,
	useToast,
	Alert,
	AlertIcon,
	Button,
	Code,
} from "@chakra-ui/react";

import { useEffect } from "react";
import {
	Link,
	RouteComponentProps,
	useHistory,
	withRouter,
} from "react-router-dom";
import {
	DealsProductSetup,
	ProductSetupFormValues,
} from "../DealsProductSetup/DealsProductSetup";
import { CreateDealsCustomize } from "../CreateDealsCustomize/CreateDealsCustomize";
import { DealsCouponSetup } from "../DealsCouponSetup/DealsCouponSetup";
import { useLocation } from "react-router-dom";
import { Product } from "../../services/productServices";
import {
	CountryDiscount,
	Deal,
	DealCustomizeData,
} from "../../types/deals.type";
import { editDeal, fetchDeal } from "../../services/dealServices";
import {
	CountryCategoryDiscount,
	CustomCountryDiscount,
	HolidayCategoryDiscount,
} from "../../services/commonServices";
import { ArrowLeftIcon } from "../../components/Icons/ArrowLeft";
import { DealsHolidaysCouponSetup } from "../DealsHolidaysCouponSetup/DealsHolidaysCouponSetup";
import styles from "./EditDeals.module.scss";
import {
	DealsTimeCouponSetup,
	DealTimeFormValues,
} from "../DealsTimeCouponSetup/DealsTimeCouponSetup";
import { DealIcon } from "../../components/Icons/Deal";
import { ProductSettings } from "../ProductSettings/ProductSettings";
import posthog from "posthog-js";
import { getUserProfile } from "../../services/userServices";
import { CurrentUser } from "../../services/appConfig";
import { DealsSelectProduct } from "../DealsSelectProduct/DealsSelectProduct";
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

type Steps =
	| "product-setup"
	| "select-product"
	| "holiday-coupon-setup"
	| "location-coupon-setup"
	| "time-coupon-setup"
	| "customize"
	| "add-to-site"
	| "settings";

interface MatchParams {
	dealId: string;
}

export type EditDealsProps = RouteComponentProps<MatchParams>;

const EditDeals = (props: EditDealsProps) => {
	const toast = useToast();
	let history = useHistory();
	let { search } = useLocation();

	const defaultStep = new URLSearchParams(search).get("step") as Steps;

	const [selectedProduct, setSelectedProduct] = React.useState<Product>(
		{} as Product
	);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [deal, setDeal] = React.useState<Deal>({} as Deal);
	const [productData, setProductData] = React.useState<
		Omit<
			ProductSetupFormValues,
			| "isLocationDealsSelected"
			| "isHolidayDealsSelected"
			| "isTimeDealsSelected"
		>
	>({} as ProductSetupFormValues);
	const [discounts, setDiscounts] = React.useState<CountryDiscount[]>(
		[] as CountryDiscount[]
	);
	const [customDiscounts, setCustomDiscounts] = React.useState<CustomCountryDiscount[]>(
		[] as CustomCountryDiscount[]
	);
	const [holidayDiscounts, setHolidayDiscounts] = React.useState<
		HolidayCategoryDiscount[]
	>([] as HolidayCategoryDiscount[]);

	const [timeDiscounts, setTimeDiscounts] = React.useState<
		DealTimeFormValues[]
	>({} as DealTimeFormValues[]);

	const [customizedData, setCustomizedData] = React.useState<DealCustomizeData>(
		{} as DealCustomizeData
	);

	const [currentStep, setCurrentStep] = React.useState<Steps>(
		defaultStep || "product-setup"
	);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetchDeal(props.match.params.dealId);
				const dealData = response.data;
				setDeal(response.data);
				setProductData({
					name: dealData.name,
					description: dealData.description,
					website: dealData.website,
					targetPagesBar: dealData.targetPagesBar,
				});
				setDiscounts(dealData.discountBar);
				setHolidayDiscounts(dealData.holidayBar);
				setTimeDiscounts(dealData.timeBar);
				setCustomDiscounts(dealData.countryBar)
				setCustomizedData({
					backgroundColor: dealData.backgroundColor,
					backgroundTransparency: dealData.backgroundTransparency,
					fontColor: dealData.fontColor,
					fontSize: dealData.fontSize,
					highlightFontColor: dealData.highlightFontColor,
					unStyled: dealData.unStyled,
					addCloseIcon: dealData.addCloseIcon,
					countryMessage: dealData.countryMessage,
					holidayMessage: dealData.holidayMessage,
					timeMessage: dealData.timeMessage,
					placement: dealData.placement,
					container: dealData.container,
					borderRadius: dealData.borderRadius,
				});
				console.log(response.data);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, [props.match.params.dealId]);

	const getPlatform = React.useCallback(() => {
		if (deal.integratedPlatform && deal.integratedPlatform.length) {
			return deal.integratedPlatform[0].platform;
		}
		return "manual";
	}, [deal]);

	const goToStep = React.useCallback((step: Steps) => {
		setCurrentStep(step);
	}, []);

	React.useEffect(() => {
		async function fetchData() {
			try {
				const response = await getUserProfile();
				CurrentUser.set(response.data);
				posthog.identify(
					response.data.email,
					{ email: response.data.email }
				);
				posthog.capture('$pageview');
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const updateDeal = React.useCallback(
		async (
			productData: Omit<
				ProductSetupFormValues,
				| "isLocationDealsSelected"
				| "isHolidayDealsSelected"
				| "isTimeDealsSelected"
			>,
			discounts: CountryDiscount[],
			customDiscounts: CustomCountryDiscount[],
			holidayDiscounts: HolidayCategoryDiscount[],
			timeDiscounts: DealTimeFormValues[],
			customizedData: DealCustomizeData,
			selectedProducts?: Product[],
		) => {
			async function saveData() {
				const createDealsFormData: Deal = {
					...customizedData,
					id: deal.id,
					discountBar: discounts,
					countryBar: customDiscounts,
					holidayBar: holidayDiscounts,
					timeBar: timeDiscounts,
					targetPagesBar: productData.targetPagesBar,
					website: productData.website,
					name: productData.name,
					description: productData.description,
					imageUrl: deal.imageUrl,
					isDraft: false,
					...((getPlatform() === "gumroad" || getPlatform() === "stripe" || getPlatform() === "paddle" || getPlatform() === "lemonsqueezy" || getPlatform() === "whop" || getPlatform() === "chargebee") && {
						// integratedPlatforms: deal.integratedPlatform,
						integratedPlatform: !selectedProducts ? deal.integratedPlatform : selectedProducts?.map(product => {
							return {
								platform: getPlatform(),
								identifier: product.id,
								sync: true,
							}
						}),
					}),
				};
				try {
					setIsSubmitting(true);
					await editDeal(createDealsFormData);
					toast({
						title: `Successfully updated the deal`,
						status: "success",
						isClosable: true,
					});
				} catch (error) {
					toast({
						title: `Oops! Unable to update deal`,
						status: "error",
						isClosable: true,
					});
				} finally {
					setIsSubmitting(false);
				}
			}
			saveData();
		},
		[deal.id, deal.imageUrl, deal.integratedPlatform, getPlatform, toast]
	);

	const getExcludedPaths = React.useCallback(() => {
		if (!deal.id) return;
		const excludedPaths = deal.targetPagesBar
			.filter((page) => page.action === "exclude")
			.map((page) => page.path);
		return excludedPaths;
	}, [deal]);
	const getIncludedPaths = React.useCallback(() => {
		if (!deal.id) return;
		const includedPaths = deal.targetPagesBar
			.filter((page) => page.action === "include")
			.map((page) => page.path);
		return includedPaths;
	}, [deal]);
	const getDefaultCustomizationValues = React.useCallback(() => {
		if (!deal.id) return;
		return {
			backgroundColor: deal.backgroundColor,
			backgroundTransparency: deal.backgroundTransparency,
			fontColor: deal.fontColor,
			fontSize: deal.fontSize,
			highlightFontColor: deal.highlightFontColor,
			borderRadius: deal.borderRadius,
			unStyled: deal.unStyled,
			placement: deal.placement,
			container: deal.container,
			addCloseIcon: deal.addCloseIcon,
			countryMessage: deal.countryMessage,
			timeMessage: deal.timeMessage,
			holidayMessage: deal.holidayMessage,
		};
	}, [deal]);

	const getMainComponent = React.useCallback(() => {
		if (!deal.id) {
			return (
				<Flex h="100%" alignItems="center" justifyContent="center">
					<Spinner
						thickness="2px"
						speed="0.65s"
						emptyColor="gray.200"
						color="gray.700"
						size="xl"
					/>
				</Flex>
			);
		}
		return (
			<>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep === "product-setup" ? "flex" : "none",
					}}
				>
					<DealsProductSetup
						defaultProductName={deal?.name || ""}
						defaultWebsite={deal.website}
						showDescription={getPlatform() === "manual"}
						defaultExcludedPaths={getExcludedPaths()}
						defaultIncludedPaths={getIncludedPaths()}
						showSecondaryButton
						onSecondaryBtnClick={() => {
							history.push("/app/deals/");
						}}
						isSubmitting={isSubmitting}
						primaryButtonLabel="Update Deal"
						onPrimaryBtnClick={(formValues: ProductSetupFormValues) => {
							setProductData(formValues);
							updateDeal(
								formValues,
								discounts,
								customDiscounts,
								holidayDiscounts,
								timeDiscounts,
								customizedData
							);
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep === "select-product" ? "flex" : "none",
					}}
				>
					<DealsSelectProduct
						onBackClick={() => {
							history.push("/app/deals/");
						}}
						integratedPlatform={deal.integratedPlatform}
						isSubmitting={isSubmitting}
						platform={getPlatform()}
						primaryButtonLabel="Update Deal"
						isVisibleBackButton={true}
						onNextClick={(proeuctsToSend) => {
							let updatedCustomDiscounts = customDiscounts.map(discount => {

								discount.couponCode = '';
								return discount
							});
							let updatedDiscounts = discounts.map(discount => {
								discount.couponCode = '';
								return discount
							});

							let updatedHolidayDiscounts = holidayDiscounts.map(discount => {
								discount.couponCode = '';
								return discount
							});
							let updatedTimeDiscounts = timeDiscounts.map(discount => {
								delete discount.id;
								return discount
							});
							setTimeDiscounts(updatedTimeDiscounts);
							updateDeal(
								productData,
								updatedDiscounts,
								updatedCustomDiscounts,
								updatedHolidayDiscounts,
								timeDiscounts,
								customizedData,
								proeuctsToSend
							);
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep === "location-coupon-setup" ? "flex" : "none",
					}}
				>
					<DealsCouponSetup
						onBackClick={() => {
							history.push("/app/deals/");
						}}
						platform={getPlatform()}
						existingDiscounts={deal.discountBar}
						existingCustomDiscounts={deal.countryBar}
						isSubmitting={isSubmitting}
						integratedPlatform={deal.integratedPlatform}
						primaryButtonLabel="Update Deal"
						onNextClick={(
							countryCategoryDiscount: CountryCategoryDiscount[],
							customDiscounts: CustomCountryDiscount[]
						) => {
							const updatedDiscounts = countryCategoryDiscount.map(
								(countryDiscount) => {
									return {
										pppFactor: countryDiscount.range,
										couponCode: countryDiscount.couponCode,
										discountPercentage: countryDiscount.discountPercentage,
									};
								}
							);
							setDiscounts(updatedDiscounts);
							setCustomDiscounts(customDiscounts)
							updateDeal(
								productData,
								updatedDiscounts,
								customDiscounts,
								holidayDiscounts,
								timeDiscounts,
								customizedData
							);
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep === "holiday-coupon-setup" ? "flex" : "none",
					}}
				>
					<DealsHolidaysCouponSetup
						platform={getPlatform()}
						onBackClick={() => {
							history.push("/app/deals/");
						}}
						existingHolidays={deal.holidayBar}
						isSubmitting={isSubmitting}
						integratedPlatform={deal.integratedPlatform}
						primaryButtonLabel="Update Deal"
						onNextClick={(
							holidayCategoryDiscount: HolidayCategoryDiscount[]
						) => {
							const updatedDiscounts = holidayCategoryDiscount.map(
								(holidayDiscount) => {
									return {
										startBefore: holidayDiscount.startBefore,
										endAfter: holidayDiscount.endAfter,
										couponCode: holidayDiscount.couponCode,
										discountPercentage: holidayDiscount.discountPercentage,
										holiday: holidayDiscount.holiday,
									};
								}
							);
							setHolidayDiscounts(updatedDiscounts);
							updateDeal(
								productData,
								discounts,
								customDiscounts,
								updatedDiscounts,
								timeDiscounts,
								customizedData
							);
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep === "time-coupon-setup" ? "flex" : "none",
					}}
				>
					<DealsTimeCouponSetup
						existingDiscounts={deal.timeBar}
						platform={getPlatform()}
						isSubmitting={isSubmitting}
						primaryButtonLabel="Update Deal"
						onNextClick={(values: DealTimeFormValues) => {
							console.log("values", values);
							setTimeDiscounts([values]);
							updateDeal(
								productData,
								discounts,
								customDiscounts,
								holidayDiscounts,
								[values],
								customizedData
							);
						}}
						onBackClick={() => {
							history.push("/app/deals/");
						}}
					/>
				</Flex>
				<Flex
					h="100%"
					direction="column"
					style={{
						display: currentStep === "customize" ? "flex" : "none",
					}}
				>
					<CreateDealsCustomize
						onBackClick={() => {
							history.push("/app/deals/");
						}}
						isSubmitting={isSubmitting}
						primaryButtonLabel="Update Deal"
						showTimeMessage={getPlatform() === "gumroad" || getPlatform() === "stripe" || getPlatform() === "paddle" || getPlatform() === "lemonsqueezy" || getPlatform() === "whop" || getPlatform() === "chargebee"}
						defaultCustomizationValues={getDefaultCustomizationValues()}
						onNextClick={async (formData: DealCustomizeData) => {
							setCustomizedData(formData);
							updateDeal(productData, discounts, customDiscounts, holidayDiscounts, timeDiscounts, formData);
						}}
					/>
				</Flex>
				<Flex
					direction="column"
					style={{
						display: currentStep === "add-to-site" ? "flex" : "none",
					}}
					alignItems="center"
					h="100%" overflow="auto"
					pt={20}
					pb={20}
				>
					<Flex direction="column" >
						<Flex justifyContent="center" height="164">
							<DealIcon />
						</Flex>
						<Heading fontSize="x-large" mt="8">Use any of the following option to display discounts on your site</Heading>
						
						<Heading fontSize="medium" mt="8"> 1) Add the below script to your site to display the banner.</Heading>
						<Code p="4" shadow="sm" mt="12" borderRadius="md">
							&lt;script
							src="https://cdn.paritydeals.com/banner.js"&gt;&lt;/script&gt;
						</Code>
						<HStack mt="4">
							<Link to="/app/deals/">
								<Button variant="outline">View Deals</Button>
							</Link>
							<Button
								bg="blackAlpha.900"
								_hover={{ bg: "blackAlpha.900" }}
								_active={{ bg: "blackAlpha.900" }}
								colorScheme="blackAlpha"
								variant="solid"
								isLoading={isSubmitting}
								isDisabled={isSubmitting}
								onClick={() => {
									navigator.clipboard.writeText(
										`<script src="https://cdn.paritydeals.com/banner.js"></script>`
									);
									toast({
										title: `Copied to Clipboard`,
										status: "info",
										isClosable: true,
									});
								}}
							>
								Copy code
							</Button>
						</HStack>
						<Heading fontSize="medium" mt="8"> 2) Use our API to build a custom solution.</Heading>
						<Code p="4" shadow="sm" mt="12" borderRadius="md">
							{deal.identifier}
						</Code>
						<HStack mt="4">
							<a href="https://www.paritydeals.com/docs/api/deals-discount/">
								<Button variant="outline">View Docs</Button>
							</a>
							<Button
								bg="blackAlpha.900"
								_hover={{ bg: "blackAlpha.900" }}
								_active={{ bg: "blackAlpha.900" }}
								colorScheme="blackAlpha"
								variant="solid"
								isLoading={isSubmitting}
								isDisabled={isSubmitting}
								onClick={() => {
									navigator.clipboard.writeText(
										deal.identifier || ''
									);
									toast({
										title: `Copied to Clipboard`,
										status: "info",
										isClosable: true,
									});
								}}
							>
								Copy Identifier
							</Button>
						</HStack>
						<Flex alignItems="center">
							<Alert maxW="600px" mt="12" status="info">
								<AlertIcon />
								If you face any issues, please contact us at hi@paritydeals.com
							</Alert>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					direction="column"
					h={"100%"}
					overflow={"auto"}
					style={{
						display: currentStep === "settings" ? "flex" : "none",
					}}
				>
					<ProductSettings integratedPlatform={deal.integratedPlatform} id={deal.id} />
				</Flex>
			</>
		);
	}, [deal.id, deal?.name, deal.website, deal.integratedPlatform, deal.discountBar, deal.countryBar, deal.holidayBar, deal.timeBar, deal.identifier, currentStep, getPlatform, getExcludedPaths, getIncludedPaths, isSubmitting, getDefaultCustomizationValues, history, updateDeal, discounts, customDiscounts, holidayDiscounts, timeDiscounts, customizedData, productData, toast]);

	return (
		<VStack w="100%" h="100vh" p="6" pl="20" pt="10" spacing="10">
			<Flex shrink={0} alignItems="center" w="100%" justifyContent="start">
				<Link to="/app/deals/">
					<IconButton
						borderRadius="full"
						shadow="lg"
						ml="-14"
						mr="5"
						borderColor="gray.500"
						colorScheme="whiteAlpha"
						aria-label="Go to deals"
						icon={<ArrowLeftIcon />}
					/>
				</Link>
				<Heading fontSize="30">Update deals</Heading>
			</Flex>
			<Flex flex={1} h="calc(100% - 100px)" w="100%">
				<HStack spacing="6" w="100%" overflow={"hidden"}>
					<Box
						h="100%"
						w="260px"
						shadow="sm"
						bg="white"
						flexShrink={0}
						borderRadius="lg"
						pt="10"
					>
						<List spacing={5}>
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								className={currentStep === "product-setup" ? styles.active : ""}
								cursor="pointer"
								onClick={() => {
									goToStep("product-setup");
								}}
							>
								Product setup
							</ListItem>
							{getPlatform() !== 'manual' && (
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								onClick={() => {
									goToStep("select-product");
								}}
								className={currentStep === "select-product" ? styles.active : ""}
							>
								Select product
							</ListItem>)}
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								className={
									currentStep === "holiday-coupon-setup" ? styles.active : ""
								}
								onClick={() => {
									goToStep("holiday-coupon-setup");
								}}
							>
								Holiday deals
							</ListItem>
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								onClick={() => {
									goToStep("location-coupon-setup");
								}}
								className={
									currentStep === "location-coupon-setup" ? styles.active : ""
								}
							>
								Location deals
							</ListItem>
							{getPlatform() === "gumroad" || getPlatform() === "stripe" || getPlatform() === "paddle" || getPlatform() === 'lemonsqueezy' || getPlatform() === 'whop' || getPlatform() === 'chargebee' ? <ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								onClick={() => {
									goToStep("time-coupon-setup");
								}}
								className={
									currentStep === "time-coupon-setup" ? styles.active : ""
								}
							>
								Time based deals
							</ListItem>: null}
							{/* You can also use custom icons from react-icons */}
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								onClick={() => {
									goToStep("customize");
								}}
								className={currentStep === "customize" ? styles.active : ""}
							>
								Customize
							</ListItem>
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								onClick={() => {
									goToStep("add-to-site");
								}}
								className={currentStep === "add-to-site" ? styles.active : ""}
							>
								Add to site
							</ListItem>
							<ListItem
								display="flex"
								alignItems="center"
								fontSize="md"
								color="gray.500"
								pl="4"
								pt="1"
								pb="1"
								pr="4"
								borderLeft="2px solid transparent"
								cursor="pointer"
								onClick={() => {
									goToStep("settings");
								}}
								className={currentStep === "settings" ? styles.active : ""}
							>
								Settings
							</ListItem>
						</List>
					</Box>
					<Box
						w="100%"
						p="6"
						bg="white"
						shadow="sm"
						h="100%"
						borderRadius="lg"
						position="relative"
					>
						{getMainComponent()}
					</Box>
				</HStack>
			</Flex>
		</VStack>
	);
};
export default withRouter(EditDeals);
