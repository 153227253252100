import * as React from "react";
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Select,
} from "@chakra-ui/react";

import { Form, Field, Formik } from "formik";
import { PaddleAuthData } from "../../types/types";
const initialValues: PaddleAuthData = {
	vendorId: '',
	vendorAuthCode: '',
	version: 'classic',
};

interface PaddleAuthFormFormProps {
	onNextClick: (values: PaddleAuthData) => void;
	onBackClick: () => void;
	isSubmitting?: boolean;
	initialRef: React.RefObject<HTMLInputElement>;
}


export const PaddleAuthFormForm = ({
	onNextClick,
	onBackClick,
	isSubmitting = false,
	initialRef
}: PaddleAuthFormFormProps) => {
	return (

		<Formik
			initialValues={initialValues}
			onSubmit={async (values: any, actions) => { }}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isValid,
			}) => (
				<>
					<Form>
						<Field type="text" name="vendorId">
							{({ field, form }: any) => (
								<FormControl
									isRequired
									isInvalid={
										form.errors.vendorId && form.touched.vendorId
									}
								>
									<FormLabel color="gray.800" htmlFor="vendorId">
										Vendor ID
									</FormLabel>
									<Input
										{...field}
										ref={initialRef}
										id="vendorId"
										placeholder="111111"
									/>
									<FormErrorMessage>
										{form.errors.vendorId}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
						<Field type="text" name="vendorAuthCode">
							{({ field, form }: any) => (
								<FormControl
									isRequired
									mt="6"
									isInvalid={
										form.errors.vendorAuthCode && form.touched.vendorAuthCode
									}
								>
									<FormLabel color="gray.800" htmlFor="vendorAuthCode">
										Auth code
									</FormLabel>
									<Input
										{...field}
										id="vendorAuthCode"
										placeholder="Auth Code API Key"
									/>
									<FormErrorMessage>
										{form.errors.vendorAuthCode}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
						<Field type="text" name="version">
							{({ field, form }: any) => (
								<FormControl
									isRequired
									mt="6"
									isInvalid={
										form.errors.version && form.touched.version
									}
								>
									<FormLabel color="gray.800" htmlFor="version">
										API version
									</FormLabel>
									<Select {...field}>
										<option value='classic'>Classic</option>
										<option value='billing'>Billing</option>
									</Select>
									<FormErrorMessage>
										{form.errors.version}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					</Form>
					<Box
						w="100%"
						display="flex"
						pt="6"
						mt="6"
						bg="white"
						borderTop="1px solid"
						borderColor="gray.100"
						justifyContent="space-between"
					>
						<HStack></HStack>
						<HStack>
							<Button onClick={onBackClick} variant="outline">
								Cancel
							</Button>
							<Button
								bg="blackAlpha.900"
								_hover={{ bg: "blackAlpha.900" }}
								_active={{ bg: "blackAlpha.900" }}
								colorScheme="blackAlpha"
								variant="solid"
								isLoading={isSubmitting}
								isDisabled={isSubmitting || !values.vendorId || !values.vendorAuthCode}
								onClick={() => {
									onNextClick(values);
								}}
							>
								Authenticate
							</Button>
						</HStack>
					</Box>
				</>
			)}
		</Formik>




	);
};
