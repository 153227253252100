export interface User {
    email: string;
    id: number;
    isActiveSubscription: boolean;
    recurrence: "monthly" | "yearly";
    subscriptionId: string;
    plan: string
}

export const CurrentUser: any = {
    currentUser: {},
    set(user: User) {
        user.plan = user.plan || 'Free'
        this.currentUser = user;
    },
    get() {
        return this.currentUser;
    },
};