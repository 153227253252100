import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}
export const PaddleColorIcon = ({
  width = 20,
  height = 20,
  fill,
  ...restProps
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...restProps}
    >
      <rect width="20" height="20" rx="4" fill="#FFDD35" />
      <path
        d="M7.09506 3.80951H10.578C11.5395 3.80951 12.3255 3.98306 12.9359 4.33016C13.5503 4.67327 14.0051 5.13806 14.3004 5.72454C14.5956 6.31101 14.7432 6.96532 14.7432 7.68744C14.7432 8.40957 14.5956 9.06586 14.3004 9.65633C14.0091 10.2468 13.5583 10.7176 12.9479 11.0687C12.3374 11.4158 11.5555 11.5893 10.602 11.5893H7.92091V15.3236C7.92091 15.7334 7.58868 16.0657 7.17884 16.0657C6.76901 16.0657 6.43677 15.7334 6.43677 15.3236V11.7569C6.43677 10.9372 7.10124 10.2727 7.92091 10.2727H10.5541C11.2124 10.2727 11.741 10.159 12.14 9.93161C12.5389 9.7042 12.8282 9.397 13.0077 9.01001C13.1912 8.61902 13.283 8.17817 13.283 7.68744C13.283 7.19671 13.1912 6.75785 13.0077 6.37086C12.8282 5.98386 12.5369 5.68065 12.134 5.46122C11.731 5.2378 11.1964 5.12609 10.5301 5.12609H7.92091H7.09506C6.73149 5.12609 6.43677 4.83136 6.43677 4.4678C6.43677 4.10424 6.73149 3.80951 7.09506 3.80951Z"
        fill="#040F0F"
      />
      <path
        d="M7.05892 5.49477C7.05892 5.49477 7.17515 6.57962 7.62788 7.03234C8.0806 7.48507 9.16545 7.6013 9.16545 7.6013C9.16545 7.6013 8.0806 7.71753 7.62788 8.17025C7.17515 8.62298 7.05892 9.70783 7.05892 9.70783C7.05892 9.70783 6.9427 8.62298 6.48997 8.17025C6.03724 7.71753 4.95239 7.6013 4.95239 7.6013C4.95239 7.6013 6.03724 7.48507 6.48997 7.03234C6.9427 6.57962 7.05892 5.49477 7.05892 5.49477Z"
        fill="#040F0F"
      />
    </svg>
  );
};
