import * as React from "react";
import {
	Box,
	Button,
	Code,
	Flex,
	Heading,
	Text,
	HStack,
	IconButton,
	useToast,
	VStack,
	Spinner,
} from "@chakra-ui/react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { useEffect } from "react";
import { ProductSetupFormValues } from "../DealsProductSetup/DealsProductSetup";
import { Deal, DealCategory } from "../../types/deals.type";
import { getDealCategories } from "../../services/commonServices";
import { ArrowLeftIcon } from "../../components/Icons/ArrowLeft";
import { CreateMarketplaceDealsForm } from "../CreateMarketplaceDealsForm/CreateMarketplaceDealsForm";
import { fetchDeals } from "../../services/dealServices";
import {
	MarketplaceDeal,
	MarketplaceDealFormData,
} from "../../types/marketplaceDeal.type";
import {
	createMarketplaceDeal,
	editMarketplaceDeal,
	fetchMarketplaceDeal,
} from "../../services/marketplaceSealServices";
import { DealIcon } from "../../components/Icons/Deal";
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}
interface MatchParams {
	dealId: string;
}

export type EditDealsProps = RouteComponentProps<MatchParams>;
export const EditMarketplaceDeals = (props: EditDealsProps) => {
	const toast = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [showSuccessMessgae, setShowSuccessMessgae] =
		React.useState<boolean>(false);
	const [deals, setDeal] = React.useState<MarketplaceDeal>(
		{} as MarketplaceDeal
	);
	const [dealsCategories, setDealsCategories] =
		React.useState<DealCategory[]>();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetchMarketplaceDeal(props.match.params.dealId);
				const categoryResponse = await getDealCategories();
				setDealsCategories(categoryResponse.data);
				setDeal(response.data);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
			}
		}
		fetchData();
	}, [props.match.params.dealId]);

	const updateDeal = React.useCallback(
		(formValues: MarketplaceDealFormData) => {
			async function saveDeal() {
				try {
					setIsSubmitting(true);
					await editMarketplaceDeal({
						//name: formValues.name,
						id: deals.id,
						description: formValues.description,
						website: formValues.website,
						category: formValues.category,
						//bar: formValues.bar,
					});
					setShowSuccessMessgae(true);
				} catch (error) {
					toast({
						title: `Oops! Unable to add deal`,
						status: "error",
						isClosable: true,
					});
				} finally {
					setIsSubmitting(false);
				}
			}
			saveDeal();
		},
		[deals.id, toast]
	);

	const getDealCreationMessageComponent = React.useCallback(() => {
		return (
			<Flex
				h="100%"
				direction="column"
				p="120px 0 200px 0"
				justifyContent="center"
				alignItems="center"
			>
				<DealIcon />
				<Heading mt="8">Deal has been successfully updated</Heading>
				<Text color="gray.600" fontSize="md" mt="2">
					We'll manually review your submission and add to the marketplace.
				</Text>
				<Link to="/app/marketplace-deals/">
					<Button
						bg="blackAlpha.900"
						mt="10"
						_hover={{ bg: "blackAlpha.900" }}
						_active={{ bg: "blackAlpha.900" }}
						colorScheme="blackAlpha"
						variant="solid"
					>
						View Deals
					</Button>
				</Link>
			</Flex>
		);
	}, []);

	const getMainComponent = React.useCallback(() => {
		return (
			<>
				{showSuccessMessgae ? (
					getDealCreationMessageComponent()
				) : (
					<Flex h="100%" direction="column">
						{deals.id && dealsCategories?.length ? (
							<CreateMarketplaceDealsForm
								isSubmitting={isSubmitting}
								defaultWebsite={deals.website}
								defaultProductDescription={deals.description}
								defaultCategory={deals.category}
								dealsCategories={dealsCategories}
								primaryButtonLabel={"Update Deal"}
								showSelectDeal={false}
								onPrimaryBtnClick={updateDeal}
							/>
						) : (
							<Flex p="100px">
								<Spinner />
							</Flex>
						)}
					</Flex>
				)}
			</>
		);
	}, [
		updateDeal,
		deals,
		dealsCategories,
		getDealCreationMessageComponent,
		isSubmitting,
		showSuccessMessgae,
	]);

	return (
		<VStack w="100%" p="6" pl="20" pt="10" spacing="10">
			<Flex shrink={0} alignItems="center" w="100%" justifyContent="start">
				<Link to="/app/marketplace-deals/">
					<IconButton
						borderRadius="full"
						shadow="lg"
						ml="-14"
						mr="5"
						borderColor="gray.500"
						colorScheme="whiteAlpha"
						aria-label="Go to marketplace deals"
						icon={<ArrowLeftIcon />}
					/>
				</Link>
				<Heading fontSize="30">Edit marketplace deal</Heading>
			</Flex>
			<Box
				w="100%"
				p="6"
				bg="white"
				shadow="sm"
				borderRadius="lg"
				position="relative"
			>
				{getMainComponent()}
			</Box>
		</VStack>
	);
};

export default withRouter(EditMarketplaceDeals);
