import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../constants/app';
import { Deal, DealSetting } from '../types/deals.type';

export interface Product {
	id: string;
	name: string;
	previewUrl: string;
}

export async function createDeal(data: Deal): Promise<AxiosResponse<Deal>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		data: data,
		url: '/deals/',
	});
}
export async function editDeal(data: Deal): Promise<AxiosResponse<Deal>> {
	return await axios({
		baseURL: baseURL,
		method: 'put',
		data: data,
		url: `/deals/${data.id}/`,
	});
}
export async function deleteDeal(id: string): Promise<AxiosResponse<Deal>> {
	return await axios({
		baseURL: baseURL,
		method: 'delete',
		url: `/deals/${id}/`,
	});
}

export async function fetchDeals(): Promise<AxiosResponse<Deal[]>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: '/deals/',
	});
}
export async function fetchDeal(id: string): Promise<AxiosResponse<Deal>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: `/deals/${id}/`,
	});
}

export async function refreshCoupon(
	barId: string
): Promise<AxiosResponse<Deal[]>> {
	return await axios({
		baseURL: baseURL,
		data: {
			bar_id: barId,
		},
		method: 'POST',
		url: `/deals/refresh-coupons/`,
	});
}

export async function ToggleVpnProtection(
	payload: { vpn: boolean },
	barID: string
): Promise<AxiosResponse<DealSetting>> {
	return await axios({
		baseURL: baseURL,
		method: 'PATCH',
		url: `/deals/${barID}/settings/`,
		data: payload,
	});
}

export async function ToggleTorBrowserProtection(
	payload: { tor: boolean },
	barID: string
): Promise<AxiosResponse<DealSetting>> {
	return await axios({
		baseURL: baseURL,
		method: 'PATCH',
		url: `/deals/${barID}/settings/`,
		data: payload,
	});
}

export async function ToggleProxyProtection(
	payload: { proxy: boolean },
	barID: string
): Promise<AxiosResponse<DealSetting>> {
	return await axios({
		baseURL: baseURL,
		method: 'PATCH',
		url: `/deals/${barID}/settings/`,
		data: payload,
	});
}

export async function fetchDealSetting(
	barID: string
): Promise<AxiosResponse<DealSetting>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: `/deals/${barID}/settings/`
	});
}