import React, { useState, useCallback, useEffect } from "react";
import { Box, Heading, Flex, Text } from "@chakra-ui/react";

import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
} from "recharts";
import { DatedVisitorsData } from "../../services/dashboardServices";
import { formatNumber } from "../../utils/commonUtils";
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const formatXAxis = (tickFormat: number) => {
  if (tickFormat > 1000) {
    return tickFormat / 1000 + "K";
  }
  return tickFormat;
};
const formatYAxis = (tickFormat: string | number | Date) => {
  console.log("tickFormat", tickFormat);
  const month = monthNames[new Date(tickFormat).getMonth()];
  return month;
};

export interface MonthlyPricingPageVisitsGraphProps {
  allowdQuota: number;
  visotorResponse: DatedVisitorsData[];
}

export const MonthlyPricingPageVisitsGraph = ({
  allowdQuota,
  visotorResponse,
}: MonthlyPricingPageVisitsGraphProps) => {
  const getVisitorsData = useCallback(() => {
    const data = visotorResponse.map((item) => {
      const visitors = Math.min(item.visitors, allowdQuota);
      const overages =
        item.visitors > allowdQuota ? item.visitors - allowdQuota : 0;
      return {
        date: item.date,
        pricing_page_visitors: visitors,
        overages: overages,
      };
    });
    return data;
  }, [allowdQuota, visotorResponse]);

  const [visitorsData, setVisitorsData] = useState(getVisitorsData());

  useEffect(() => {
    setVisitorsData(getVisitorsData());
  }, [allowdQuota, visotorResponse, getVisitorsData]);
  

  const CustomTooltip = ({ active, payload, allData }) => {
    if (active && payload && payload.length) {
      const hasOverages = allData.some((monthData) =>
        monthData.overages && monthData.overages > 0
      );
      const hasData = payload.some((item) => item.value > 0);

      if (!hasData) {
        return null;
      }

      const monthNames = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
      ];

      const month = monthNames[new Date(payload[0].payload.date).getMonth()];

      return (
        <Box
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          bg="black"
          p="4"
          mt="20px"
        >
          <Text
            mb="2"
            color="rgba(255, 255, 255, 0.92)"
            fontSize="sm"
            fontWeight="600"
          >{`${month}`}</Text>

          {payload
            .filter(
              (item) => item.name === "pricing_page_visitors" || (hasOverages && item.name === "overages")
            )
            .map((item) => (
              <Box key={item.name} mb="0.5">
                <Box
                  display="flex"
                  justifyContent="left"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box
                    width="12px"
                    height="12px"
                    bg={
                      item.name === "pricing_page_visitors"
                        ? "rgba(159, 122, 234, 1)"
                        : "red"
                    }
                  ></Box>

                  <Text
                    fontSize="sm"
                    as="span"
                    color="rgba(255, 255, 255, 0.92)"
                    padding="5px"
                  >
                    {item.name === "pricing_page_visitors" ? "Pricing page visitors": "Overages"}
                  </Text>
                  <Text
                    fontSize="sm"
                    as="b"
                    fontWeight="500"
                    ml="1"
                    color="rgba(255, 255, 255, 0.92)"
                  >
                    {formatNumber(item.value)}
                  </Text>
                </Box>
              </Box>
            ))}
        </Box>
      );
    }

    return null;
  };
  const hasOverages = visitorsData.some((monthData) =>
    monthData.overages && monthData.overages > 0
  );
  const renderColorfulLegendText = (value: string) => {
    return <span style={{ fontSize:"12px", color: "rgba(74, 85, 104, 1)" }}>{value === "pricing_page_visitors" ? "Pricing page visitors": "Overages"}</span>;
  };
  const getChartMarkup = useCallback(() => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={visitorsData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            stroke="rgba(237, 242, 247, 1)"
            horizontal={true}
            vertical={false}
          />
          <XAxis
            tick={{ fontSize: "10px" }}
            tickLine={false}
            stroke="rgba(203, 213, 224, 1)"
            tickFormatter={(tick) => formatYAxis(tick)}
            dataKey="date"
            interval={0}
            style={{ fill: "rgba(74, 85, 104, 1)", fontSize: 12 }}
          />

          <YAxis
            tick={{ fontSize: "10px" }}
            tickLine={false}
            tickFormatter={(value: any) => String(formatXAxis(value))}
            stroke="rgba(203, 213, 224, 1)"
            style={{ fill: "rgba(74, 85, 104, 1)", fontSize: 12 }}
          />
          <Tooltip
            content={({ active, payload }) => (
              <CustomTooltip active={active} payload={payload || []} allData={visitorsData} />
            )}
            cursor={{ fill: "transparent" }}
          ></Tooltip>
          {hasOverages && (<Legend
            align="left"
            fontSize={"14px"}
            wrapperStyle={{
              paddingLeft: "60px",
            }}
            formatter={renderColorfulLegendText}
          />)}
          <Bar
            dataKey="pricing_page_visitors"
            stackId="a"
            fill="rgba(159, 122, 234, 1)"
            barSize={32}
          >
            {visitorsData.map((entry: any, index: number) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  radius={
                    entry.overages
                      ? ([0, 0, 0, 0] as unknown as string | number | undefined)
                      : ([5, 5, 0, 0] as unknown as string | number | undefined)
                  }
                />
              );
            })}
          </Bar>
          <Bar
            dataKey="overages"
            radius={[5, 5, 0, 0]}
            stackId="a"
            fill="rgba(229, 62, 62, 1)"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }, [hasOverages, visitorsData]);

  return (
    <Flex
      direction="column"
      shadow="xs"
      p="4"
      bg="white"
      height="100%"
      borderRadius="lg"
      mt="20px"
      width="100%"
      mb="35px"
    >
      <Heading as="h4" fontSize="20px" mb="40px" color="gray.700" fontWeight="600" lineHeight={"28px"}>
        Monthly pricing page visits
      </Heading>
      <Box height="100%" width="100%">
        {getChartMarkup()}
      </Box>
    </Flex>
  );
};
