import * as React from 'react';
import {
	Box,
	Flex,
	Heading,
	HStack,
	Text,
	Button,
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormControl,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputRightElement
} from '@chakra-ui/react';

import { useEffect } from 'react';
import { RiDeleteBin5Line } from "react-icons/ri"
import { FiPlus, FiEdit3 } from "react-icons/fi"
import { CountryListItem } from '../../components/CountryListItem/CountryListItem';
import {
	CountryCategoryDiscount,
	CustomCountryDiscount,
	getCountryCategories,
} from '../../services/commonServices';
import { CountryDiscount, IntegratedPlatform } from '../../types/deals.type';
import { getDefaultDiscounts } from './DealsCouponSetupUtils';
import { DealPlatform } from '../../types/types';
import { Select } from 'chakra-react-select';

import styles from './DealsCouponSetup.module.scss'
import { FieldArray, Field, Formik, Form } from 'formik';
import { SearchIcon } from '../../components/Icons/Search';
import { svgMap } from '../../components/CountryFlags/CountryFlags';

interface CountryList {
	label: string
	value: string
}




interface DealsCouponSetupProps {
	onNextClick: (discounts: CountryCategoryDiscount[], customDiscounts: CustomCountryDiscount[]) => void;
	onBackClick: () => void;
	existingDiscounts?: CountryDiscount[];
	existingCustomDiscounts?: CustomCountryDiscount[];
	platform: DealPlatform;
	isSubmitting?: boolean;
	primaryButtonLabel?: string;
	integratedPlatform?: IntegratedPlatform[]
}
export const DealsCouponSetup = ({
	onNextClick,
	onBackClick,
	existingDiscounts,
	existingCustomDiscounts,
	platform,
	isSubmitting = false,
	primaryButtonLabel = 'Next',
	integratedPlatform
}: DealsCouponSetupProps) => {
	const [discounts, setDiscounts] = React.useState<CountryCategoryDiscount[]>(
		[]
	);

	const [countriesList, setCountriesList] = React.useState<CountryList[]>([]);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [customDiscounts, setCustomDiscounts] = React.useState<CustomCountryDiscount[]>(existingCustomDiscounts || [])
    
	const [searchTerm, setSearchTerm] = React.useState('');
	const [filteredDiscounts, setFilteredDiscounts] = React.useState<CountryCategoryDiscount[]>([]);

	const getDefaultCountryDiscounts = React.useCallback(() => {
		if (customDiscounts && customDiscounts.length > 0) {
			return [...customDiscounts]
		} else {
			return [{ country: '', discountPercentage: '', couponCode: '' }]
		}
	}, [customDiscounts])

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getCountryCategories();

				let countries = [];

				response.data.forEach((category) => {
					countries = [...countries, ...category.countries]
				});

				countries = countries.map((country) => {
					return {
						label: country.countryName,
						value: country.country
					}
				});
				setCountriesList(countries);



				if (existingDiscounts) {
					const defaultDiscounts: CountryCategoryDiscount[] =
						response.data.map((category) => {
							return {
								range: category.range,
								countries: category.countries,
								pppFactor: category.range,
								couponCode:
									existingDiscounts.find(
										(discount) =>
											discount.pppFactor ===
											category.range
									)?.couponCode || '',
								discountPercentage:
									existingDiscounts.find(
										(discount) =>
											discount.pppFactor ===
											category.range
									)?.discountPercentage || '',
							};
						});
					setDiscounts(defaultDiscounts);
				} else {
					const defaultDiscounts: CountryCategoryDiscount[] =
						response.data.map((category) => {
							return {
								range: category.range,
								countries: category.countries,
								pppFactor: category.range,
								couponCode: '',
								discountPercentage: getDefaultDiscounts(
									category.range
								),
							};
						});
					setDiscounts(defaultDiscounts);
				}
			} catch (error) {
				console.log('Unable to fetch note', error);
			} finally {
			}
		}
		fetchData();
	}, [existingDiscounts]);
   
	const handleSearchChange = React.useCallback((event) => {
		const newSearch = event.target.value;
		setSearchTerm(newSearch);
		if (!newSearch) {
			setFilteredDiscounts([]);
			return;
		}
		const filterDiscounts = discounts.filter((discount) => {
			return discount.countries.some((country) => country.countryName.toLowerCase().includes(newSearch.toLowerCase()))
		});
		const filteredCountryDiscount = filterDiscounts.map((discount) => {
			const dupDiscount = {...discount};
			dupDiscount.countries = discount.countries.filter((country) => {
				return country.countryName.toLowerCase().includes(newSearch.toLowerCase());
			})
			return dupDiscount;
		});
		setFilteredDiscounts(filteredCountryDiscount);
	}, [discounts]);


	const handleInputChange = React.useCallback(
		(type: string, value: string, pppFactor: string) => {
			const discountsObject = discounts?.find((discount) => {
				return discount.pppFactor === pppFactor;
			});
			if (!discountsObject) return;
			if (type === 'discountPercentage') {
				discountsObject.discountPercentage = value;
			} else {
				discountsObject.couponCode = value;
			}
			setDiscounts([...(discounts || [])]);
		},
		[discounts]
	);

	const getPageHeader = React.useCallback(() => {
		if (platform === 'manual') {
			return ''
		}
		return <>
			We'll automatically create coupons on your <span style={{ textTransform: 'capitalize' }}>{platform}</span> account
			<br />
		</>
	}, [platform])

	const defaultValue = (options, value: string) => {
		return options ? options.find(option => option.value === value) : ""
	}

	const getCustomDiscountsComponent = React.useCallback(() => {
		if(customDiscounts && customDiscounts.length > 0) {
			return <HStack
					shadow='xs'
					p='4'
					borderRadius='lg'
					className={styles.container}
					spacing='4'
					align='start'
					w='100%'
					justify='space-between'
					alignItems='center'
					flexShrink={0}
				>
					<Flex direction='column' alignItems='start'>
						<Text fontWeight='500' mb='2' fontSize='sm' color='gray.500'>
							Custom
						</Text>
						<Flex wrap='wrap' pr='140px'>
							{customDiscounts.map((country) => {
							const svg = svgMap[country.country];
								return (
									<Box
										data-tool
										key={country.country}
										mr='4'
										flexWrap='wrap'
										fontSize='2xl'
										cursor="default"
									>
										<Box
											dangerouslySetInnerHTML={{ __html: svg }}
											width="22px"
											height="22px"
										/>
									</Box>
								);						
							})}
						</Flex>
					</Flex>
					<Box mb='6' mt="4">
						<Button leftIcon={<FiEdit3 />} size="sm" onClick={onOpen} variant='outline'>Edit</Button>
					</Box>
				</HStack>
		}
		return null

	}, [customDiscounts, onOpen])

	return (
		<Flex h='100%' direction='column'>
			<VStack alignItems='start' w='100%' >
				<Heading as='h2' size='md' mb='2' isTruncated>
					Set up coupons for location based discounts
				</Heading>
				<Text color='gray.500'>
					{getPageHeader()}
					Leave the discount field blank if you don't want to display deals for any specific parity group.
				</Text>
			</VStack>
			<HStack justifyContent="space-between">
				<Box mb="6" mt="4">
					<Button leftIcon={<FiPlus />} onClick={onOpen} variant="outline">
						Add custom pricing
					</Button>
				</Box>
				<Box>
					<FormControl mb="2">
						<InputGroup>
							<Input placeholder="Search for country..." value={searchTerm}
								onChange={handleSearchChange} type="search" />
							<InputRightElement children={<SearchIcon color={'var(--chakra-colors-gray-500)'} />} />
						</InputGroup>
					</FormControl>
				</Box>
			</HStack>
			<VStack overflowY='auto' spacing='4' mb='86px' p='2px'>
				{getCustomDiscountsComponent()}
				{(searchTerm ? filteredDiscounts : discounts)?.map((discount) => {
					return (discount.countries.length ?
						<CountryListItem
							onChange={(type: string, value: string) => {
								handleInputChange(type, value, discount.range);
							}}
							disableCoupon={!!integratedPlatform?.length}
							range={discount.range}
							defaultCouponCode={discount.couponCode}
							defaultDiscount={discount.discountPercentage}
							key={discount.range}
							countries={discount.countries}
						></CountryListItem> : null
					);
				})}
			</VStack>
			<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size='3xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader borderBottom="1px solid" borderBottomColor="gray.100" mb="8">
						Create separate pricing for specific countries 
						<Text fontSize="sm" fontWeight="400" color="gray.500" >Custom pricing overrides the default country groups. </Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box className={styles.nestedFormCont}>
							<Formik
								initialValues={{
									customDiscount: getDefaultCountryDiscounts(existingCustomDiscounts),
								}}
								onSubmit={async () => { }}
							>
								{({
									values,
									form,
								}) => (
									<>
										<VStack
											alignItems="start"
											overflowY="auto"
											spacing="8"
											mb="86px"
											p="2px"
										>
											<HStack w="100%" alignItems="start" pos="relative" overflow="hidden">
												<Form style={{ width: '100%' }}>
													<Box className={styles.nestedFormCont}>

														<FieldArray name="customDiscount">
															{({ insert, remove, push }) => (
																<Box className={styles.nestedForm}>
																	{values.customDiscount.length > 0 &&
																		values.customDiscount.map((friend, index) => (
																			<HStack key={index} mb="4" pos="relative">

																				<Field type="text" name={`customDiscount.${index}.country`} w="40%">
																					{({ field, form }: any) => (
																						<FormControl
																						>
																							<FormLabel fontSize="sm" htmlFor={`customDiscount.${index}.country`}>
																								Country name
																							</FormLabel>
																							<Select
																								size="sm"
																								onChange={(opt, e) => {
																									form.setFieldValue(field.name, opt.value)

																								}}
																								defaultValue={defaultValue(countriesList, field.value)}
																								styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
																								menuPortalTarget={document.body}
																								//{...field}
																								options={countriesList}
																							/>
																						</FormControl>
																					)}
																				</Field>
																				<Field type="text" name={`customDiscount.${index}.discountPercentage`} w="20%">
																					{({ field, form }: any) => (
																						<FormControl
																							mb="2"
																						>
																							<FormLabel fontSize="sm" htmlFor={`customDiscount.${index}.discountPercentage`}>
																								Discount %

																							</FormLabel>
																							<Input
																								{...field}
																								size="sm"
																							/>
																						</FormControl>
																					)}
																				</Field>
																				<Field type="text" name={`customDiscount.${index}.couponCode`} w="20%">
																					{({ field, form }: any) => (
																						<FormControl
																							mb="2"
																						>
																							<FormLabel fontSize="sm" htmlFor={`customDiscount.${index}.couponCode`}>
																								Coupon code
																							</FormLabel>
																							<Input
																								{...field}
																								size="sm"
																							/>
																						</FormControl>
																					)}
																				</Field>
																				<IconButton
																					alignSelf={"flex-end"}
																					className={styles.questionDeleteBtn}
																					variant='outline'
																					colorScheme='gray'
																					aria-label='Delete Question'
																					fontSize='14px'
																					size="sm"
																					onClick={() => remove(index)}
																					icon={<RiDeleteBin5Line />}
																				/>
																			</HStack>
																		))}
																	<Button
																		variant="outline"
																		size="sm"
																		leftIcon={<FiPlus />}
																		mb="8"
																		onClick={() => push({ country: '', discountPercentage: '', couponCode: '' })}
																	>
																		Add More
																	</Button>
																</Box>
															)}
														</FieldArray>
													</Box>

												</Form>
											</HStack>
										</VStack>
										<Box
											pos="absolute"
											w="100%"
											zIndex={2}
											display="flex"
											p="6"
											bottom="0"
											left="0"
											right="0"
											bg="white"
											borderTop="1px solid"
											borderColor="gray.100"
											justifyContent="space-between"
										>
											<HStack></HStack>
											<HStack>
												<Button onClick={onClose} variant="outline">
													Cancel
												</Button>
												<Button
													bg='blackAlpha.900'
													_hover={{ bg: 'blackAlpha.900' }}
													_active={{ bg: 'blackAlpha.900' }}
													colorScheme='blackAlpha'
													variant='solid'
													onClick={() => {
														const data = values.customDiscount.filter((item) => item.country !== '' && item.discountPercentage !== '');
														setCustomDiscounts(data);
														onClose();
													}}
												>
													Save
												</Button>
											</HStack>
										</Box>
									</>
								)}
							</Formik>



						</Box>

					</ModalBody>
				</ModalContent>
			</Modal>
			<Box
				pos='absolute'
				w='100%'
				zIndex={2}
				display='flex'
				p='6'
				bottom='0'
				left='0'
				right='0'
				bg='white'
				borderTop='1px solid'
				borderColor='gray.100'
				justifyContent='space-between'
			>
				<HStack></HStack>
				<HStack>
					<Button onClick={onBackClick} variant='outline'>
						Go back
					</Button>
					<Button
						bg='blackAlpha.900'
						_hover={{ bg: 'blackAlpha.900' }}
						_active={{ bg: 'blackAlpha.900' }}
						colorScheme='blackAlpha'
						variant='solid'
						isLoading={isSubmitting}
						isDisabled={isSubmitting}
						onClick={() => {
							onNextClick(discounts as CountryCategoryDiscount[], customDiscounts as CustomCountryDiscount[]);
						}}
					>
						{primaryButtonLabel}
					</Button>
				</HStack>
			</Box>
		</Flex>
	);
};
