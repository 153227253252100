import * as React from "react";
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
} from "@chakra-ui/react";

import { Form, Field, Formik } from "formik";
import { ChargebeeAuthData } from "../../types/types";
const initialValues: ChargebeeAuthData = {
	apiKey: '',
	site: ''
};

interface ChargebeeAuthFormProps {
	onNextClick: (values: ChargebeeAuthData) => void;
	onBackClick: () => void;
	isSubmitting?: boolean;
	initialRef: React.RefObject<HTMLInputElement>;
}


export const ChargebeeAuthForm = ({
	onNextClick,
	onBackClick,
	isSubmitting = false,
	initialRef
}: ChargebeeAuthFormProps) => {
	return (

		<Formik
			initialValues={initialValues}
			onSubmit={async (values: any, actions) => { }}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isValid,
			}) => (
				<>
					<Form>
						<Field type="text" name="apiKey">
							{({ field, form }: any) => (
								<FormControl
									isRequired
									isInvalid={
										form.errors.apiKey && form.touched.apiKey
									}
								>
									<FormLabel color="gray.800" htmlFor="apiKey">
										API key
									</FormLabel>
									<Input
										{...field}
										ref={initialRef}
										id="apiKey"
										placeholder="111111"
									/>
									<FormErrorMessage>
										{form.errors.apiKey}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
						<Field type="text" name="site">
							{({ field, form }: any) => (
								<FormControl
									isRequired
									mt="6"
									isInvalid={
										form.errors.site && form.touched.site
									}
								>
									<FormLabel color="gray.800" htmlFor="site">
										Site
									</FormLabel>
									<Input
										{...field}
										id="site"
										placeholder="Your charrgebee site"
									/>
									<FormErrorMessage>
										{form.errors.site}
									</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					</Form>
					<Box
						w="100%"
						display="flex"
						pt="6"
						mt="6"
						bg="white"
						borderTop="1px solid"
						borderColor="gray.100"
						justifyContent="space-between"
					>
						<HStack></HStack>
						<HStack>
							<Button onClick={onBackClick} variant="outline">
								Cancel
							</Button>
							<Button
								bg="blackAlpha.900"
								_hover={{ bg: "blackAlpha.900" }}
								_active={{ bg: "blackAlpha.900" }}
								colorScheme="blackAlpha"
								variant="solid"
								isLoading={isSubmitting}
								isDisabled={isSubmitting || !values.apiKey || !values.site}
								onClick={() => {
									onNextClick(values);
								}}
							>
								Authenticate
							</Button>
						</HStack>
					</Box>
				</>
			)}
		</Formik>




	);
};
