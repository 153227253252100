import * as React from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	HStack,
	SimpleGrid,
	Spinner,
	Stack,
	Text,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { deleteDeal, fetchDeals } from '../../services/dealServices';
import { Deal, DealCategory } from '../../types/deals.type';
import { MediaListItem, MetaListMenuItems } from '../../components/MediaListItem/MediaListItem';
import { NoDataDeals } from '../../components/NoData/NoDataDeals';
import { GumroadIcon } from '../../components/Icons/Gumroad';
import { PlusIcon } from '../../components/Icons/Plus';
import {
	getGumroadAuthUrl,
} from '../../services/authServices';
import { deleteMarketplaceDeal, fetchMarketplaceDeals } from '../../services/marketplaceSealServices';
import { ArrowRightIcon } from '../../components/Icons/ArrowRight';
import { getDealCategories } from '../../services/commonServices';

export const MarketPlaceDealsList = () => {
	const toast = useToast();
	const [dealsList, setDealsList] = React.useState<Deal[]>();
	const [dealsCategories, setDealsCategories] = React.useState<DealCategory[]>();
	const [dealsLoaded, setDealsLoaded] = React.useState<boolean>(false);

	const history = useHistory();

	const onDealMenuItemClick = React.useCallback(async (type: MetaListMenuItems, deal: Deal) => {
		console.log('type', type)
		if (type === 'edit') {
			history.push(`/app/edit-marketplace-deals/${deal.id}/`);
		} else if (type === 'customize') {
		} else if (type === 'delete') {
			await deleteMarketplaceDeal(deal.id as string);
			setDealsList(dealsList?.filter(_deal => _deal.id !== deal.id));
			toast({
				title: `Deal has been deleted successfully `,
				status: 'success',
				isClosable: true,
			});
		}
	}, [history, dealsList, toast])

	useEffect(() => {
		async function fetchData() {
			try {
				const categoryResponse = await getDealCategories();
				console.log('category resonse',categoryResponse )
				const response = await fetchMarketplaceDeals();
				setDealsList(response.data);
				setDealsCategories(categoryResponse.data)
				setDealsLoaded(true);
			} catch (error) {
				console.log('Unable to fetch deals', error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const getNoDataIcon = React.useCallback(() => {
		return (
			<VStack
				spacing='9'
				alignItems='center'
				direction='column'
				justifyContent='center'
				w='100%'
				h='100%'
				pt="20"
			>
				<NoDataDeals />
				<Box textAlign='center'>
					<Heading
						maxW='780px'
						fontSize='xl'
						color="gray.700"
						fontWeight='semibold'
						as='h3'
					>
						List your product on Paritydeals marketplace to get more sales
					</Heading>
					<Text fontSize="medium" mt="2" maxW="550px" color="gray.600">
						Paritydeals marketplace provides a curated list of great deals for developers on hosting, tools, education, and much more.
					</Text>
				</Box>
				<HStack>
					<a target="_blank" href='https://marketplace.paritydeals.com/'>
						<Button
							variant='outline'
							rightIcon={<ArrowRightIcon />}
						>
							Visit marketplace website
						</Button>
					</a>
					<Link to='/app/create-marketplace-deals'>
						<Button
							bg='blackAlpha.900'
							_hover={{ bg: 'blackAlpha.900' }}
							_active={{ bg: 'blackAlpha.900' }}
							colorScheme='blackAlpha'
							variant='solid'
							leftIcon={<PlusIcon />}
						>
							Create marketplace deal
						</Button>
					</Link>
				</HStack>
			</VStack>
		);
	}, []);

	const getDealsList = React.useCallback(() => {
		if (!dealsLoaded) {
			return (
				<Flex
					w='100%'
					minH='300px'
					justifyContent='center'
					alignItems='center'
				>
					<Spinner
						thickness='2px'
						speed='0.65s'
						emptyColor='gray.200'
						color='gray.700'
						size='xl'
					/>
				</Flex>
			);
		}
		if (!dealsList?.length) {
			return getNoDataIcon();
		}
		const dealListItems = dealsList?.map((deal) => {
			return (
				<MediaListItem
					name={deal.name}
					imageAlt={deal.name}
					imageUrl={deal.imageUrl}
					description={deal.description}
					key={deal.id}
					showCustomizeMenu={false}
					showGetCodeMenu={false}
					actionButtonLeftIcon={null}
					onMenuItemClick={(type: MetaListMenuItems) => {
						onDealMenuItemClick(type, deal);
					}}
				></MediaListItem>
			);
		});
		return <SimpleGrid columns={[1, 1, 1, 2]} spacing='6'>
			{dealListItems}
		</SimpleGrid>
	}, [dealsList, dealsLoaded, getNoDataIcon, onDealMenuItemClick]);

	return (
		<Box
			w="100%"
			maxW="1200px"
			p="6"
			shadow="sm"
			h="100%"
			position="relative"
			overflow="auto"
		>
			<VStack w='100%' spacing='10'>
				<Flex shrink={0} w='100%' justifyContent='space-between'>
					<Heading fontSize='30'>Marketplace deals</Heading>
					<Stack direction='row' spacing={4}>
						<Link to='/app/create-marketplace-deals'>
							<Button
								bg='blackAlpha.900'
								_hover={{ bg: 'blackAlpha.900' }}
								_active={{ bg: 'blackAlpha.900' }}
								colorScheme='blackAlpha'
								variant='solid'
								leftIcon={<PlusIcon />}
							>
								Create marketplace deal
							</Button>
						</Link>
					</Stack>
				</Flex>
				<Box width="100%">
					{getDealsList()}
				</Box>
			</VStack>
		</Box>
	);
};
