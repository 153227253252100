import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const DealIcon = ({
	width = 173,
	height = 164,
	...restProps
}: IconProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 173 164'
			{...restProps}
		>
			<path
				d='M119.875 63.7497C117.423 63.7497 114.975 62.8189 113.104 60.9605L110.777 58.6334C109.875 57.7345 108.667 57.2372 107.385 57.234H104.111C98.8199 57.234 94.5133 52.9274 94.5133 47.6359V44.3589C94.5101 43.0806 94.0128 41.8725 93.1107 40.964L90.8219 38.6784C87.0732 34.952 87.0573 28.8603 90.7869 25.1084L93.1139 22.7782C94.0128 21.876 94.5101 20.6679 94.5133 19.3864V16.1159C94.5133 10.8211 98.8199 6.5145 104.111 6.5145H107.388C108.667 6.5145 109.872 6.01722 110.78 5.10873L113.072 2.82315C116.799 -0.925592 122.887 -0.944718 126.642 2.78808L128.969 5.1151C129.874 6.01722 131.079 6.5145 132.358 6.5145H135.635C140.926 6.5145 145.233 10.8211 145.233 16.1159V19.3896C145.236 20.6679 145.733 21.876 146.635 22.7845L148.924 25.0733C150.738 26.8775 151.742 29.2811 151.752 31.8472C151.758 34.3973 150.776 36.7977 148.988 38.6115C148.956 38.6434 148.927 38.6784 148.895 38.7071L146.632 40.9704C145.733 41.8725 145.236 43.0806 145.233 44.3621V47.6359C145.233 52.9274 140.926 57.234 135.635 57.234H132.358C131.079 57.2372 129.871 57.7345 128.966 58.6366L126.674 60.9254C124.806 62.8061 122.339 63.7497 119.875 63.7497Z'
				fill='#FF9D3B'
			/>
			<path
				d='M130.047 21.7106C128.96 20.6268 127.19 20.6268 126.103 21.7106L109.719 38.0954C108.632 39.1824 108.632 40.9515 109.719 42.0417C110.248 42.5677 110.946 42.8578 111.692 42.8578C112.438 42.8578 113.136 42.5677 113.662 42.0417L130.047 25.6569C131.134 24.5668 131.134 22.8008 130.047 21.7106Z'
				fill='white'
			/>
			<path
				d='M129.143 37.5025C128.11 37.069 126.886 37.3049 126.07 38.1209C125.901 38.3186 125.662 38.6246 125.499 38.9848C125.327 39.3737 125.305 39.7913 125.305 40.0718C125.305 40.3523 125.327 40.7667 125.499 41.1556C125.659 41.5127 125.85 41.8027 126.102 42.0546C126.679 42.5901 127.348 42.861 128.094 42.861C128.802 42.861 129.497 42.5742 130.067 42.0418C130.574 41.535 130.851 40.8337 130.851 40.0718C130.851 39.3068 130.574 38.6087 130.064 38.0986C129.783 37.8213 129.446 37.6045 129.143 37.5025Z'
				fill='white'
			/>
			<path
				d='M111.673 26.4758C112.429 26.4758 113.111 26.1921 113.637 25.6661C114.191 25.1497 114.494 24.4452 114.494 23.6866C114.494 23.3072 114.418 22.9311 114.268 22.5996C114.131 22.2903 113.924 21.9939 113.653 21.7229C112.894 20.926 111.616 20.6678 110.653 21.1077C110.315 21.2225 109.977 21.4392 109.693 21.7261C109.177 22.2776 108.884 22.9916 108.884 23.6866C108.884 24.4165 109.183 25.137 109.713 25.6629C110.27 26.1857 110.965 26.4758 111.673 26.4758Z'
				fill='white'
			/>
			<path
				d='M156.499 97.999C155.23 97.999 153.963 97.5171 152.995 96.5552L151.79 95.3506C151.323 94.8853 150.698 94.6279 150.034 94.6262H148.34C145.601 94.6262 143.372 92.397 143.372 89.6579V87.9616C143.37 87.3 143.112 86.6746 142.645 86.2043L141.461 85.0212C139.52 83.0923 139.512 79.939 141.443 77.9969L142.647 76.7907C143.112 76.3238 143.37 75.6984 143.372 75.0351V73.3421C143.372 70.6014 145.601 68.3721 148.34 68.3721H150.036C150.698 68.3721 151.321 68.1147 151.792 67.6444L152.978 66.4614C154.907 64.5209 158.059 64.511 160.002 66.4432L161.207 67.6477C161.676 68.1147 162.299 68.3721 162.961 68.3721H164.657C167.396 68.3721 169.626 70.6014 169.626 73.3421V75.0367C169.627 75.6984 169.885 76.3238 170.352 76.794L171.536 77.9788C172.475 78.9127 172.995 80.1569 173 81.4852C173.003 82.8052 172.495 84.0477 171.569 84.9866C171.553 85.0031 171.538 85.0212 171.522 85.0361L170.35 86.2076C169.885 86.6746 169.627 87.3 169.626 87.9633V89.6579C169.626 92.397 167.396 94.6262 164.657 94.6262H162.961C162.299 94.6279 161.674 94.8853 161.205 95.3523L160.019 96.537C159.052 97.5105 157.775 97.999 156.499 97.999Z'
				fill='#4D62D9'
			/>
			<path
				d='M161.765 76.2381C161.202 75.6771 160.286 75.6771 159.724 76.2381L151.242 84.7195C150.68 85.2821 150.68 86.1979 151.242 86.7622C151.516 87.0345 151.878 87.1846 152.264 87.1846C152.65 87.1846 153.011 87.0345 153.283 86.7622L161.765 78.2809C162.327 77.7166 162.327 76.8025 161.765 76.2381Z'
				fill='white'
			/>
			<path
				d='M161.297 84.4126C160.763 84.1882 160.129 84.3103 159.707 84.7327C159.619 84.835 159.495 84.9934 159.411 85.1799C159.322 85.3812 159.311 85.5974 159.311 85.7426C159.311 85.8878 159.322 86.1023 159.411 86.3036C159.494 86.4884 159.593 86.6386 159.723 86.7689C160.022 87.0461 160.368 87.1864 160.754 87.1864C161.121 87.1864 161.48 87.0379 161.776 86.7623C162.038 86.4999 162.182 86.1369 162.182 85.7426C162.182 85.3466 162.038 84.9852 161.774 84.7212C161.629 84.5776 161.454 84.4654 161.297 84.4126Z'
				fill='white'
			/>
			<path
				d='M152.254 78.7047C152.645 78.7047 152.999 78.5579 153.271 78.2856C153.558 78.0183 153.715 77.6536 153.715 77.2609C153.715 77.0646 153.675 76.8699 153.597 76.6982C153.527 76.5382 153.419 76.3847 153.279 76.2445C152.886 75.832 152.225 75.6983 151.726 75.926C151.551 75.9854 151.377 76.0976 151.23 76.2461C150.962 76.5316 150.811 76.9012 150.811 77.2609C150.811 77.6388 150.966 78.0117 151.24 78.284C151.528 78.5546 151.888 78.7047 152.254 78.7047Z'
				fill='white'
			/>
			<g opacity='0.999999'>
				<path
					d='M57.42 97.8585C42.542 82.9837 26.2277 75.1784 20.9815 80.4246C20.4057 81.0292 19.9835 81.7585 19.7467 82.5583L19.6636 82.4719L14.6509 103.009L9.66064 123.485L3.7459 147.732L0 163.087L22.1715 154.696L50.3697 144.025L72.8643 135.513L72.8387 135.487C73.5936 135.247 74.2814 134.838 74.854 134.294C80.1034 129.048 72.2949 112.737 57.42 97.8585Z'
					fill='#DD1C49'
				/>
				<path
					d='M20.9811 80.4183C26.2273 75.1721 42.5448 82.9774 57.4197 97.8522C72.2945 112.727 80.0998 129.041 74.8536 134.288C69.6074 139.534 53.2931 131.728 38.4183 116.854C23.5434 101.979 15.7349 85.6644 20.9811 80.4183Z'
					fill='#D1E2ED'
				/>
				<path
					d='M70.8234 136.178C68.1363 136.226 65.4748 135.695 63.0085 134.626L44.6181 141.584L16.4135 152.243L1.28595 157.969L0 163.241L22.1715 154.85L50.3697 144.182L71.0153 136.37L70.8234 136.178Z'
					fill='#B21C47'
				/>
				<path
					d='M94.0474 121.015C93.8171 121.015 93.59 120.974 93.3724 120.894C83.6286 117.244 73.0979 116.22 62.8326 117.925L55.9774 119.067C54.9282 119.217 53.9557 118.488 53.8054 117.439C53.6614 116.425 54.3396 115.475 55.344 115.283L62.2025 114.131C73.1299 112.314 84.3452 113.405 94.7192 117.288C95.7172 117.647 96.2354 118.747 95.8772 119.745C95.6021 120.516 94.8663 121.025 94.0474 121.015Z'
					fill='#FFBF3F'
				/>
				<path
					d='M59.4993 109.499C58.4405 109.493 57.5864 108.626 57.5928 107.567C57.596 106.835 58.0151 106.173 58.674 105.853L98.98 86.6594C99.9365 86.2019 101.082 86.6082 101.536 87.5678C101.993 88.5243 101.587 89.6727 100.627 90.127L60.3215 109.32C60.0655 109.442 59.784 109.503 59.4993 109.499Z'
					fill='#FFBF3F'
				/>
				<path
					d='M37.0561 105.661C36.8034 105.661 36.5507 105.61 36.3172 105.514C35.3383 105.104 34.8777 103.981 35.2872 103.002L38.0766 96.3072C42.3695 86.0484 43.3675 74.7051 40.9396 63.8513C40.6901 62.8213 41.3267 61.7848 42.3567 61.5353C43.3867 61.2858 44.4232 61.9192 44.6727 62.9492C44.6791 62.9716 44.6823 62.9972 44.6887 63.0196C47.2926 74.6475 46.2146 86.7937 41.6146 97.7851L38.8219 104.48C38.5244 105.194 37.8303 105.661 37.0561 105.661Z'
					fill='#FFBF3F'
				/>
				<path
					d='M61.4185 71.1126C61.4185 73.2335 59.6975 74.9513 57.5799 74.9513C55.459 74.9513 53.7412 73.2335 53.7412 71.1126C53.7412 68.9917 55.459 67.2739 57.5799 67.2739C59.6975 67.2739 61.4185 68.9917 61.4185 71.1126Z'
					fill='#DD93C1'
				/>
				<path
					d='M40.3055 57.6773C38.1846 57.6773 36.4668 55.9595 36.4668 53.8387C36.4668 51.7178 38.1846 50 40.3055 50C42.4263 50 44.1441 51.7178 44.1441 53.8387C44.1441 55.9595 42.4263 57.6773 40.3055 57.6773Z'
					fill='#7FCCCC'
				/>
				<path
					d='M15.2358 105.661C15.0215 104.839 14.8264 104.01 14.6504 103.166L9.66016 123.642L10.044 128.322C10.8821 138.302 15.1686 147.678 22.1678 154.84L50.3692 144.182C50.2157 144.134 50.0557 144.098 49.9022 144.047C32.8009 137.637 19.8774 123.322 15.2358 105.661Z'
					fill='white'
				/>
				<path
					d='M50.3694 144.182C50.2158 144.134 50.0559 144.098 49.9023 144.047C48.0566 143.347 46.246 142.55 44.4802 141.661L19.0811 151.274C20.0439 152.524 21.0772 153.717 22.1712 154.853L50.3694 144.182Z'
					fill='#F4F4E8'
				/>
				<path
					d='M80.6116 72.9937V82.6287H70.9766V72.9937H80.6116Z'
					fill='#C9D854'
				/>
				<path
					d='M46.0639 107.58C45.005 107.58 44.1445 106.719 44.1445 105.661C44.1445 105.117 44.3748 104.599 44.7779 104.234L63.9712 86.9599C64.771 86.2657 65.9865 86.3553 66.6807 87.155C67.3557 87.9356 67.2949 89.1096 66.5431 89.8133L47.3498 107.087C46.9979 107.404 46.5373 107.58 46.0639 107.58Z'
					fill='#FFBF3F'
				/>
				<path
					d='M101.725 99.9028C100.666 99.9028 99.8057 99.0423 99.8057 97.9834V96.0641C99.8057 95.0053 100.666 94.1448 101.725 94.1448C102.784 94.1448 103.644 95.0053 103.644 96.0641V97.9834C103.644 99.0423 102.784 99.9028 101.725 99.9028Z'
					fill='#FFDB42'
				/>
				<path
					d='M108.513 97.0908C108.004 97.0908 107.511 96.8892 107.153 96.5277L105.797 95.1714C105.061 94.4101 105.08 93.1945 105.845 92.4588C106.587 91.739 107.77 91.739 108.513 92.4588L109.869 93.8151C110.617 94.5636 110.617 95.7792 109.869 96.5277C109.511 96.886 109.021 97.0908 108.513 97.0908Z'
					fill='#FFDB42'
				/>
				<path
					d='M111.321 90.306H109.402C108.343 90.306 107.482 89.4454 107.482 88.3866C107.482 87.3278 108.343 86.4673 109.402 86.4673H111.321C112.38 86.4673 113.24 87.3278 113.24 88.3866C113.24 89.4454 112.38 90.306 111.321 90.306Z'
					fill='#FFDB42'
				/>
				<path
					d='M107.153 84.8774C106.094 84.8774 105.233 84.0169 105.233 82.9581C105.233 82.4495 105.438 81.96 105.796 81.6018L107.153 80.2454C107.914 79.5097 109.133 79.5289 109.869 80.2934C110.588 81.0356 110.588 82.216 109.869 82.9581L108.512 84.3144C108.154 84.6727 107.661 84.8774 107.153 84.8774Z'
					fill='#FFDB42'
				/>
				<path
					d='M101.725 82.6286C100.666 82.6286 99.8057 81.7681 99.8057 80.7093V78.7899C99.8057 77.7311 100.666 76.8706 101.725 76.8706C102.784 76.8706 103.644 77.7311 103.644 78.7899V80.7093C103.644 81.7681 102.784 82.6286 101.725 82.6286Z'
					fill='#FFDB42'
				/>
				<path
					d='M96.2958 84.8776C95.7871 84.8776 95.2977 84.6729 94.9394 84.3146L93.5799 82.9583C92.8442 82.1969 92.8634 80.9814 93.6279 80.2456C94.37 79.5259 95.5536 79.5259 96.2958 80.2456L97.6521 81.602C98.4006 82.3505 98.4006 83.5661 97.6521 84.3146C97.2906 84.6729 96.8044 84.8776 96.2958 84.8776Z'
					fill='#FFDB42'
				/>
			</g>
		</svg>
	);
};
