import * as React from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import styles from './CountryListItem.module.scss';

import { FiInfo } from "react-icons/fi";

import { useEffect } from 'react';
import { Country, CountryCategory } from '../../services/commonServices';
import countryCodeEmoji from 'country-code-emoji';
import { parityRange } from '../../types/deals.type';
import { svgMap } from '../CountryFlags/CountryFlags';

export interface CountryListItemProps {
	range: parityRange;
	countries: Country[];
	defaultDiscount?: string;
	defaultCouponCode?: string;
	disableCoupon?: boolean;
	onChange: (type: string, value: string) => void;
}
export const CountryListItem = ({
	countries,
	range,
	onChange,
	defaultDiscount,
	disableCoupon,
	defaultCouponCode,
}: CountryListItemProps) => {
	useEffect(() => {
		async function fetchData() {
			try {
			} catch (error) {
				console.log('Unable to fetch note', error);
			} finally {
			}
		}
		fetchData();
	}, []);
	return (
		<HStack
			shadow='xs'
			p='4'
			borderRadius='lg'
			className={styles.container}
			spacing='4'
			align='start'
			w='100%'
			justify='space-between'
			alignItems='center'
			flexShrink={0}
		>
			<Flex direction='column' alignItems='start'>
				<Text fontWeight='500' mb='2' fontSize='sm' color='gray.500'>
					Parity group: {range}
				</Text>
				<Flex wrap='wrap' pr='140px'>
					{countries.map((country) => {
						const svg = svgMap[country.country];
						return (
							<Box
								data-tool
								key={country.country}
								mr='4'
								flexWrap='wrap'
								fontSize='2xl'
								cursor="default"
							>
								<Tooltip label={country.countryName}>
									<Box
										dangerouslySetInnerHTML={{ __html: svg }}
										width="22px"
										height="22px"
									/>
								</Tooltip>
							</Box>
						);
					})}
				</Flex>
			</Flex>
			<Flex>
				<HStack w='360px' spacing='4'>
					<FormControl id='discountPercentage'>
						<FormLabel fontSize='md'>Discount %</FormLabel>
						<Input
							onChange={(e) => {
								onChange('discountPercentage', e.target.value);
							}}
							size='sm'
							defaultValue={defaultDiscount}
							type='discountPercentage'
						/>
					</FormControl>
					<FormControl id='coupon'>
						<FormLabel>
							<Flex alignItems={"center"}>
								Coupon code {disableCoupon && <Tooltip label={'Cannot edit auto generated coupons'}>
									<Box marginLeft={'2'}>
										<FiInfo />
									</Box>
								</Tooltip>}
							</Flex>
						</FormLabel>
						<Input
							onChange={(e) => {
								onChange('coupon', e.target.value);
							}}
							size='sm'
							type='coupon'
							disabled={disableCoupon}
							defaultValue={defaultCouponCode}
						/>
					</FormControl>
				</HStack>
			</Flex>
		</HStack>
	);
};
