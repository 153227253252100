import axios from 'axios';
import { baseURL } from '../constants/app';
import posthog from 'posthog-js';

export const setAuthDetails = (token: string) => {
	axios.defaults.headers.common['Authorization'] = 'Token ' + token;
	axios.defaults.baseURL = baseURL;
	axios.defaults.headers.post['Content-Type'] = 'application/json';
	localStorage.setItem('accesstoken', token);
};
export const removeAuthenticationDetails = () => {
	axios.defaults.headers.common['Authorization'] = '';
	localStorage.removeItem('accesstoken');
	posthog.reset()
};
