import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../constants/app';

export interface ApiKey {
	apiKey: string;
}

export async function createApiKey(): Promise<AxiosResponse<ApiKey>> {
	return await axios({
		baseURL: baseURL,
		method: 'post',
		url: '/user/api-key/',
	});
}
export async function refreshApiKey(): Promise<AxiosResponse<ApiKey>> {
	return await axios({
		baseURL: baseURL,
		method: 'put',
		url: `/user/api-key/`,
	});
}
export async function getApiKey(): Promise<AxiosResponse<ApiKey>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: `/user/api-key/`,
	});
}