import * as React from "react";
import {
	Box,
	Button,
	Flex,
	Heading,
	HStack,
	Text,
	Textarea,
	useToast,
} from "@chakra-ui/react";

import {
	refreshApiKey,
	createApiKey,
	getApiKey,
} from "../../services/apiServices";
import styles from "./SettingsAPi.module.scss";

export const SettingsAPI = () => {
	const toast = useToast();
	const [isLoading, setIsLoading] =
		React.useState<boolean>();
	const [isApiKeyLoading, setIsApiKeyLoading] =
		React.useState<boolean>();
	const [apiKey, setApiKey] =
		React.useState<string>();

	React.useEffect(() => {
		async function fetchData() {
			try {
				setIsApiKeyLoading(true)
				const apiKeyResponse = await getApiKey();
				setApiKey(apiKeyResponse.data.apiKey);
			} catch (error) {
				console.log("Unable to fetch deals", error);
			} finally {
				setIsApiKeyLoading(false)
			}
		}
		fetchData();
	}, []);


	const handleApiKeyCreate = React.useCallback(async () => {
		try {
			setIsLoading(true);
			const apiKeyResponse = await createApiKey();
			setApiKey(apiKeyResponse.data.apiKey);
		} catch (error) {
			console.log("Unable to fetch deals", error);
		} finally {
			setIsLoading(false)
		}

	}, [])
	const handleApiKeyRegenerate = React.useCallback(async () => {
		try {
			setIsLoading(true);
			const apiKeyResponse = await refreshApiKey();
			setApiKey(apiKeyResponse.data.apiKey);
		} catch (error) {
			console.log("Unable to fetch deals", error);
		} finally {
			setIsLoading(false)
		}

	}, [])

	return (
		<Flex direction={'column'}>
			<Flex
				bg="white"
				p="12"
				borderRadius="md"
				shadow="sm"
				maxW="3xl"
				direction={'column'}
			>
				<Box>
					<Heading className={styles.heading} size="md">API Key</Heading>
					<Text className={styles.subHeading}>Integrate ParityDeals into your platform</Text>
				</Box>
				<Text className={styles.headingSm} size="md">Create API Key</Text>
				<Textarea value={apiKey} readOnly mb={4} />
				<HStack>
					{apiKey ? <Button colorScheme='teal' size='md' isLoading={isLoading || isApiKeyLoading}
						loadingText='Creating...' onClick={handleApiKeyRegenerate}>
						Regenerate API key
					</Button> : <Button colorScheme='teal' size='md' isLoading={isLoading || isApiKeyLoading}
						loadingText={isApiKeyLoading ? 'Fetching API Key...' : 'Creating...'} onClick={handleApiKeyCreate}>
						Create API key
					</Button>}

					{apiKey && <Button
						colorScheme="blackAlpha"
						variant="solid"
						bg="blackAlpha.900"
						_hover={{ bg: "blackAlpha.900" }}
						_active={{ bg: "blackAlpha.900" }}
						onClick={() => {
							navigator.clipboard.writeText(
								apiKey
							);
							toast({
								title: `Copied to Clipboard`,
								status: "info",
								isClosable: true,
							});
						}}
					>
						Copy API key
					</Button>}


				</HStack>
			</Flex>
		</Flex>
	);
};
