import * as React from "react";
import {
	Heading,
	HStack,
	Button,
	VStack,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	FormHelperText,
	Box,
	Flex,
	Tag,
	TagCloseButton,
	TagLabel,
	Textarea,
	Alert,
	AlertIcon,
} from "@chakra-ui/react";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Deal, DealCategory } from "../../types/deals.type";
import { MarketplaceDealFormData } from "../../types/marketplaceDeal.type";

interface CreateMarketplaceDealsFormProps {
	onPrimaryBtnClick: (deal: MarketplaceDealFormData) => void;
	dealsList?: Deal[];
	dealsCategories?: DealCategory[];
	defaultCategory?: number;
	primaryButtonLabel?: string;
	secondaryBtnLabel?: string;
	showSecondaryButton?: boolean;
	disableSubmitBtnByDefault?: boolean;
	defaultProductName?: string;
	defaultProductDescription?: string;
	defaultWebsite?: string;
	showDescription?: boolean;
	isSubmitting?: boolean;
	showSelectDeal?: boolean;
}

export interface DealListItem {
	value: string;
	label: string;
}
export interface MarketplaceCategoryListItem {
	value: string;
	label: string;
}

export const CreateMarketplaceDealsForm = ({
	onPrimaryBtnClick,
	dealsList,
	dealsCategories,
	defaultCategory,
	showSelectDeal = true,
	defaultWebsite = "",
	primaryButtonLabel = "Submit Deal",
	disableSubmitBtnByDefault = false,
	defaultProductName = "",
	defaultProductDescription = "",
	isSubmitting = false,
}: CreateMarketplaceDealsFormProps) => {
	console.log("defaultCategory", defaultCategory);

	const [selectedDeal, setSelectedDeal] = React.useState<DealListItem>();
	const [selectedCategory, setSelectedCategory] =
		React.useState<MarketplaceCategoryListItem>();
	const [defaultSelectedCategory, setDefaultSelectedCategory] =
		React.useState<MarketplaceCategoryListItem>();

	const getDealsListFromDeals = React.useCallback((deals: Deal[]) => {
		const list = deals.map((deal) => {
			return {
				value: deal.id || "0",
				label: deal.name,
			};
		});
		return list;
	}, []);
	const getCategoriesListFromcategories = React.useCallback(
		(dealsCategories: DealCategory[]) => {
			const list = dealsCategories.map((category) => {
				return {
					value: category.id || "0",
					label: category.name,
				};
			});
			console.log("list", list);
			return list;
		},
		[]
	);

	const [dealsListItems, setDealsListItems] = React.useState<DealListItem[]>(
		dealsList?.length
			? getDealsListFromDeals(dealsList as Deal[])
			: ([] as DealListItem[])
	);
	const [categoriesListItems, setCategoriesListItems] = React.useState<
		MarketplaceCategoryListItem[]
	>(
		dealsCategories?.length
			? getCategoriesListFromcategories(dealsCategories as DealCategory[])
			: ([] as MarketplaceCategoryListItem[])
	);

	const onNextButtonClick = React.useCallback(
		(formValues: any) => {
			onPrimaryBtnClick(formValues);
		},
		[onPrimaryBtnClick]
	);

	useEffect(() => {
		if (dealsList?.length) {
			setDealsListItems(getDealsListFromDeals(dealsList as Deal[]));
		}
	}, [dealsList, getDealsListFromDeals]);
	useEffect(() => {
		if (dealsCategories?.length) {
			setCategoriesListItems(
				getCategoriesListFromcategories(dealsCategories as DealCategory[])
			);
		}
	}, [dealsCategories, getCategoriesListFromcategories]);

	useEffect(() => {
		if (defaultCategory) {
			const item = categoriesListItems.find(
				(categoriesListItems) =>
					"" + categoriesListItems.value === "" + defaultCategory
			);
			console.log("itemmm", item);
			setSelectedCategory(item);
		}
	}, [defaultCategory, categoriesListItems]);
	return (
		<Flex direction="column">
			<Formik
				initialValues={{
					name: defaultProductName,
					description: defaultProductDescription,
					website: defaultWebsite,
					category: "",
				}}
				onSubmit={async () => { }}
			>
				{({ values, isValid, dirty }) => (
					<>
						<VStack alignItems="start" w="100%" mb="6">
							<Heading as="h2" size="md" isTruncated>
								Deal setup
							</Heading>
						</VStack>
						<VStack
							alignItems="start"
							spacing="4"
							p="2px"
							mb="86px"
							maxW="620px"
						>
							<Form style={{ width: "100%" }}>
								{showSelectDeal ? (
									<Field type="text" name="name">
										{({ field, form }: any) => (
											<FormControl mb="6" isRequired>
												<FormLabel htmlFor="name">Title</FormLabel>
												<Select
													isClearable
													placeholder="Select from existing holiday,location deals or create new"
													onChange={(deal) => {
														setSelectedDeal(deal as DealListItem);
													}}
													options={dealsListItems}
												/>
												<FormErrorMessage>{form.errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
								) : null}
								<Field type="text" name="website">
									{({ field, form }: any) => (
										<FormControl
											mb="6"
											isInvalid={form.errors.website && form.touched.website}
											isRequired
										>
											<FormLabel htmlFor="website">Product URL</FormLabel>
											<Input {...field} id="website" placeholder="" />
											<FormErrorMessage>{form.errors.website}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								{/* <HStack mb="2">
									<Field type='text' name='discount'>
										{({ field, form }: any) => (
											<FormControl
												isInvalid={
													form.errors.discount &&
													form.touched.discount
												}
											>
												<FormLabel htmlFor='discount'>
													Discount %
												</FormLabel>
												<Input
													{...field}
													id='discount'
													placeholder=''
												/>
												<FormErrorMessage>
													{form.errors.discount}
												</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field type='text' name='coupon'>
										{({ field, form }: any) => (
											<FormControl
												isInvalid={
													form.errors.coupon &&
													form.touched.coupon
												}
											>
												<FormLabel htmlFor='coupon'>
													Coupon code
												</FormLabel>
												<Input
													{...field}
													id='coupon'
													placeholder=''
												/>
												<FormErrorMessage>
													{form.errors.coupon}
												</FormErrorMessage>
											</FormControl>
										)}
									</Field>
								</HStack>
								<Alert status='info' mb="8">
									<AlertIcon />
									Set an additional discount for everyone. We'll always show the highest available deal to users. For example, if you put 30% discounts for everyone and when someone visits from a country where they are eligible for a 50% discount, we'll show the 50% coupon for them
								</Alert> */}
								<Field type="text" name="category">
									{({ field, form }: any) => (
										<FormControl
											mb="6"
											isInvalid={form.errors.category && form.touched.category}
											isRequired
										>
											<FormLabel htmlFor="category">Deal Category</FormLabel>
											<Select
												isClearable
												defaultValue={defaultCategory && categoriesListItems.find(
													(categoriesListItems) =>
														"" + categoriesListItems.value ===
														"" + defaultCategory
												)}
												onChange={(category) => {
													setSelectedCategory(
														category as MarketplaceCategoryListItem
													);
												}}
												options={categoriesListItems}
											/>
											<FormErrorMessage>
												{form.errors.category}
											</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								<Field type="text" name="description">
									{({ field, form }: any) => (
										<FormControl
											mb="6"
											isInvalid={
												form.errors.description && form.touched.description
											}
										>
											<FormLabel htmlFor="description">Description</FormLabel>
											<Textarea
												{...field}
												id="description"
												placeholder=""
											></Textarea>
											<FormErrorMessage>
												{form.errors.description}
											</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</Form>
							<Flex width="100%" justifyContent={"end"}>
								{dealsList ? (
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
										isLoading={isSubmitting}
										disabled={
											isSubmitting || disableSubmitBtnByDefault
												? !(isValid && dirty) ||
												!selectedDeal?.value ||
												!selectedCategory?.value
												: !isValid ||
												!selectedDeal?.value ||
												!selectedCategory?.value
										}
										onClick={() => {
											onNextButtonClick({
												...values,
												...{
													bar: selectedDeal?.value,
													name: selectedDeal?.label,
													category: selectedCategory?.value,
												},
											});
										}}
									>
										{primaryButtonLabel}
									</Button>
								) : (
									<Button
										bg="blackAlpha.900"
										_hover={{ bg: "blackAlpha.900" }}
										_active={{ bg: "blackAlpha.900" }}
										colorScheme="blackAlpha"
										variant="solid"
										isLoading={isSubmitting}
										disabled={isSubmitting || !selectedCategory?.value}
										onClick={() => {
											onNextButtonClick({
												...values,
												...{
													category: selectedCategory?.value,
												},
											});
										}}
									>
										{primaryButtonLabel}
									</Button>
								)}
							</Flex>
						</VStack>
					</>
				)}
			</Formik>
		</Flex>
	);
};
