import axios, { AxiosResponse } from "axios";
import { baseURL } from "../constants/app";
export type DashboardDuration =
	| "thisweek"
	| "previousweek"
	| "thismonth"
	| "previousmonth"
	| "thisyear"
	| "previousyear";
export interface CountryVisitorsData {
	code: string;
	name: string;
	pppFactor: string;
	uniqueVisitors: number;
	visitors: number;
}
export interface DatedVisitorsData {
  overages: number;
	date: string;
	uniqueVisitors: number;
	visitors: number;
}

export interface DatedSalesData {
	date: string;
	'0.0-0.1': number;
	'0.1-0.2': number;
	'0.2-0.3': number;
	'0.3-0.4': number;
	'0.4-0.5': number;
	'0.5-0.6': number;
	'0.6-0.7': number;
	'0.7-0.8': number;
	'0.8-0.9': number;
	'0.9-1.0': number;
	'1.0-1.1': number;
	'1.1-1.2': number;
	'1.3-1.4': number;
	'1.7-1.8': number;
}
export interface PppFactorVisitorsData {
	pppFactor: string;
	uniqueVisitors: number;
	visitors: number;
}

export interface VisitorsData {
	countryVisitorsData: CountryVisitorsData[];
	datedVisitorsData: DatedVisitorsData[];
	pppFactorVisitorsData: PppFactorVisitorsData[];
}

export interface CountrySalesData {
	code: string; 
	name: string; 
	quantity: number;
	revenue: number;
	pppFactor: string;
}
export interface OfferSalesData {
	percentage: string;
	quantity: number;
}
export interface PppFactorSalesData {
	revenue: number;
	pppFactor: string;
	quantity: number;
}

export interface SalesData {
	countrySales: CountrySalesData[];
	offerSales: OfferSalesData[];
	pppFactorSales: PppFactorSalesData[];
	dateSales: DatedSalesData[];
	datedSalesRevenue: DatedSalesData[];
}

export async function fetchVisitors(
	duration: DashboardDuration,
	id: string
): Promise<AxiosResponse<VisitorsData>> {
	return await axios({
		baseURL: baseURL,
		method: "get",
		url: `/dashboard/visitors/?duration=${duration}${id ? `&bar=${id}` : ""}`,
	});
}
export async function fetchSales(
	duration: DashboardDuration,
	id: string
): Promise<AxiosResponse<SalesData>> {
	return await axios({
		baseURL: baseURL,
		method: "get",
		url: `/dashboard/sales/?duration=${duration}${id ? `&bar=${id}` : ""}`,
	});
}
