export const priceIds = {
    monthly: {
        premium: 'price_1O574aEEwDDegOMGH2Ct9Kws',
        standard: 'price_1O572ZEEwDDegOMGV3KciBSE',
        base: 'price_1O571BEEwDDegOMGqrRKA8ih',
    },
    yearly: {
        premium: 'price_1O574aEEwDDegOMGnBv7J0JK',
        standard: 'price_1O572ZEEwDDegOMGTz9kb4jl',
        base: 'price_1O571BEEwDDegOMGtUS3tfYm'
    }
}