import { parityRange } from '../../types/deals.type';

export const getDefaultDiscounts = (range: parityRange) => {
	if (range === '0.0-0.1') {
		return '70';
	} else if (range === '0.1-0.2') {
		return '70';
	} else if (range === '0.2-0.3') {
		return '60';
	} else if (range === '0.3-0.4') {
		return '50';
	} else if (range === '0.4-0.5') {
		return '50';
	} else if (range === '0.5-0.6') {
		return '40';
	} else if (range === '0.6-0.7') {
		return '30';
	} else if (range === '0.7-0.8') {
		return '20';
	} else if (range === '0.8-0.9') {
		return '';
	} else if (range === '0.9-1.0') {
		return '';
	} else if (range === '1.0-1.1') {
		return '';
	} else if (range === '1.1-1.2') {
		return '';
	} else if (range === '1.3-1.4') {
		return '';
	} else if (range === '1.7-1.8') {
		return '';
	}
	return '';
};
